import CustomTooltip from '../Components/Custom/CustomTooltip'

export const groupBy = (array, property) => {
    return array.reduce((memo, x) => {
        if (!memo[x[property]]) {
            memo[x[property]] = []
        }
        memo[x[property]].push(x)
        return memo
    }, [])
}

export const formatName = value => {
    if (value) {
        if (value.length > 6) {
            return (
                <CustomTooltip
                    placement='top'
                    width={0}
                    content={<>{value}</>}
                    display={<span>{value.slice(0, 6) + '...'}</span>}
                />
            )
        } else {
            return value
        }
    }
}

export const sortSchoolSupportArray = array => {
    const order = ['P', 'S', 'A', 'C', 'D']
    return array.sort(function (a, b) {
        if (a === b) {
            return 0
        }

        let indexOfA = order.indexOf(a[0])
        let aInList = indexOfA >= 0

        let indexOfB = order.indexOf(b[0])
        let bInList = indexOfB >= 0

        if (!aInList && !bInList) {
            return a.localeCompare(b)
        } else if (!aInList) {
            return 1
        } else if (!bInList) {
            return -1
        } else if (indexOfA === indexOfB) {
            return a.localeCompare(b)
        } else {
            return indexOfA - indexOfB
        }
    })
}
