export const municipalities = [
  {
    cty_mun: '2415',
    municipality_desc: 'ACTON'
  },
  {
    cty_mun: '1134',
    municipality_desc: 'ADDINGTON HIGHLANDS'
  },
  {
    cty_mun: '3946',
    municipality_desc: 'ADELAIDE METCALFE'
  },
  {
    cty_mun: '4301',
    municipality_desc: 'ADJALA-TOSORONTIO'
  },
  {
    cty_mun: '4742',
    municipality_desc: 'ADMASTON/BROMLEY'
  },
  {
    cty_mun: '3954',
    municipality_desc: 'AILSA CRAIG'
  },
  {
    cty_mun: '1805',
    municipality_desc: 'AJAX'
  },
  {
    cty_mun: '5201',
    municipality_desc: 'ALBAN'
  },
  {
    cty_mun: '5911',
    municipality_desc: 'ALBERTON'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'ALEXANDRIA'
  },
  {
    cty_mun: '0231',
    municipality_desc: 'ALFRED AND PLANTAGENET'
  },
  {
    cty_mun: '5727',
    municipality_desc: 'ALGOMA DISTRICT SCHOOL BOARD'
  },
  {
    cty_mun: '5799',
    municipality_desc: 'ALGOMA PLT'
  },
  {
    cty_mun: '4621',
    municipality_desc: 'ALGONQUIN HIGHLANDS'
  },
  {
    cty_mun: '4102',
    municipality_desc: 'ALLENFORD'
  },
  {
    cty_mun: '4324',
    municipality_desc: 'ALLISTON'
  },
  {
    cty_mun: '2332',
    municipality_desc: 'ALMA'
  },
  {
    cty_mun: '0931',
    municipality_desc: 'ALMONTE'
  },
  {
    cty_mun: '1450',
    municipality_desc: 'ALNWICK/HALDIMAND'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'ALTON'
  },
  {
    cty_mun: '2208',
    municipality_desc: 'AMARANTH'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'AMELIASBURG'
  },
  {
    cty_mun: '3729',
    municipality_desc: 'AMHERSTBURG'
  },
  {
    cty_mun: '1104',
    municipality_desc: 'AMHERSTVIEW'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'ANCASTER'
  },
  {
    cty_mun: '4321',
    municipality_desc: 'ANGUS'
  },
  {
    cty_mun: '4210',
    municipality_desc: 'ANNAN'
  },
  {
    cty_mun: '3906',
    municipality_desc: 'APPIN'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'APPLE HILL'
  },
  {
    cty_mun: '4905',
    municipality_desc: 'ARCHIPELAGO'
  },
  {
    cty_mun: '1039',
    municipality_desc: 'ARDEN'
  },
  {
    cty_mun: '3845',
    municipality_desc: 'ARKONA'
  },
  {
    cty_mun: '4919',
    municipality_desc: 'ARMOUR'
  },
  {
    cty_mun: '5436',
    municipality_desc: 'ARMSTRONG'
  },
  {
    cty_mun: '5885',
    municipality_desc: 'ARMSTRONG STATION'
  },
  {
    cty_mun: '4702',
    municipality_desc: 'ARNPRIOR'
  },
  {
    cty_mun: '4999',
    municipality_desc: 'ARNSTEIN'
  },
  {
    cty_mun: '4103',
    municipality_desc: 'ARRAN-ELDERSLIE'
  },
  {
    cty_mun: '2349',
    municipality_desc: 'ARTHUR'
  },
  {
    cty_mun: '3939',
    municipality_desc: 'ARVA'
  },
  {
    cty_mun: '1809',
    municipality_desc: 'ASHBURN'
  },
  {
    cty_mun: '4070',
    municipality_desc: 'ASHFIELD-COLBORNE-WAWANOSH'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'ASHTON'
  },
  {
    cty_mun: '1501',
    municipality_desc: 'ASPHODEL-NORWOOD'
  },
  {
    cty_mun: '5297',
    municipality_desc: 'ASQUITH GARVEY DSA LOCALITY EDUCATION'
  },
  {
    cty_mun: '5111',
    municipality_desc: 'ASSIGINACK'
  },
  {
    cty_mun: '0819',
    municipality_desc: 'ATHENS'
  },
  {
    cty_mun: '5901',
    municipality_desc: 'ATIKOKAN'
  },
  {
    cty_mun: '3140',
    municipality_desc: 'ATWOOD'
  },
  {
    cty_mun: '4070',
    municipality_desc: 'AUBURN'
  },
  {
    cty_mun: '0706',
    municipality_desc: 'AUGUSTA'
  },
  {
    cty_mun: '1946',
    municipality_desc: 'AURORA'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'AVONMORE'
  },
  {
    cty_mun: '3411',
    municipality_desc: 'AYLMER'
  },
  {
    cty_mun: '3001',
    municipality_desc: 'AYR'
  },
  {
    cty_mun: '4205',
    municipality_desc: 'AYTON'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'AZILDA'
  },
  {
    cty_mun: '3018',
    municipality_desc: 'BADEN'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'BADJEROS'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'BAINSVILLE'
  },
  {
    cty_mun: '4453',
    municipality_desc: 'BALA'
  },
  {
    cty_mun: '0911',
    municipality_desc: 'BALDERSON'
  },
  {
    cty_mun: '5228',
    municipality_desc: 'BALDWIN'
  },
  {
    cty_mun: '1419',
    municipality_desc: 'BALTIMORE'
  },
  {
    cty_mun: '1262',
    municipality_desc: 'BANCROFT'
  },
  {
    cty_mun: '4342',
    municipality_desc: 'BARRIE'
  },
  {
    cty_mun: '4726',
    municipality_desc: 'BARRYS BAY'
  },
  {
    cty_mun: '1204',
    municipality_desc: 'BATAWA'
  },
  {
    cty_mun: '1104',
    municipality_desc: 'BATH'
  },
  {
    cty_mun: '0911',
    municipality_desc: 'BATHURST BURGESS SHERBROOKE TOWNSHIP'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'BATTERSEA'
  },
  {
    cty_mun: '4020',
    municipality_desc: 'BAYFIELD'
  },
  {
    cty_mun: '3401',
    municipality_desc: 'BAYHAM'
  },
  {
    cty_mun: '4427',
    municipality_desc: 'BAYSVILLE'
  },
  {
    cty_mun: '4758',
    municipality_desc: 'BEACHBURG'
  },
  {
    cty_mun: '3211',
    municipality_desc: 'BEACHVILLE'
  },
  {
    cty_mun: '2622',
    municipality_desc: 'BEAMSVILLE'
  },
  {
    cty_mun: '5876',
    municipality_desc: 'BEARDMORE'
  },
  {
    cty_mun: '1839',
    municipality_desc: 'BEAVERTON'
  },
  {
    cty_mun: '0924',
    municipality_desc: 'BECKWITH'
  },
  {
    cty_mun: '4324',
    municipality_desc: 'BEETON'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'BELFOUNTAIN'
  },
  {
    cty_mun: '4060',
    municipality_desc: 'BELGRAVE'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'BELL EWART'
  },
  {
    cty_mun: '1208',
    municipality_desc: 'BELLEVILLE'
  },
  {
    cty_mun: '3418',
    municipality_desc: 'BELMONT'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'BERWICK'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'BETHANY'
  },
  {
    cty_mun: '1419',
    municipality_desc: 'BEWDLEY'
  },
  {
    cty_mun: '5121',
    municipality_desc: 'BILLINGS'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'BINBROOK'
  },
  {
    cty_mun: '5614',
    municipality_desc: 'BLACK RIVER-MATHESON'
  },
  {
    cty_mun: '1820',
    municipality_desc: 'BLACKSTOCK'
  },
  {
    cty_mun: '3245',
    municipality_desc: 'BLANDFORD BLENHEIM'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'BLENHEIM'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'BLEZARD VALLEY'
  },
  {
    cty_mun: '5738',
    municipality_desc: 'BLIND RIVER'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'BLOOMFIELD'
  },
  {
    cty_mun: '4242',
    municipality_desc: 'BLUE MOUNTAINS'
  },
  {
    cty_mun: '4060',
    municipality_desc: 'BLUEVALE'
  },
  {
    cty_mun: '4020',
    municipality_desc: 'BLUEWATER'
  },
  {
    cty_mun: '4050',
    municipality_desc: 'BLYTH'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'BOBCAYGEON'
  },
  {
    cty_mun: '4210',
    municipality_desc: 'BOGNOR'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'BOLSOLVER'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'BOLTON'
  },
  {
    cty_mun: '4312',
    municipality_desc: 'BOND HEAD'
  },
  {
    cty_mun: '4826',
    municipality_desc: 'BONFIELD'
  },
  {
    cty_mun: '4738',
    municipality_desc: 'BONNECHERE VALLEY'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'BOTHWELL'
  },
  {
    cty_mun: '1270',
    municipality_desc: 'BOULTER'
  },
  {
    cty_mun: '0316',
    municipality_desc: 'BOURGET'
  },
  {
    cty_mun: '1817',
    municipality_desc: 'BOWMANVILLE'
  },
  {
    cty_mun: '4418',
    municipality_desc: 'BRACEBRIDGE'
  },
  {
    cty_mun: '4312',
    municipality_desc: 'BRADFORD WEST GWILLIMBURY'
  },
  {
    cty_mun: '4701',
    municipality_desc: 'BRAESIDE'
  },
  {
    cty_mun: '2110',
    municipality_desc: 'BRAMPTON'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'BRANCHTON'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'BRANT COUNTY'
  },
  {
    cty_mun: '2906',
    municipality_desc: 'BRANTFORD'
  },
  {
    cty_mun: '4348',
    municipality_desc: 'BRECHIN'
  },
  {
    cty_mun: '3029',
    municipality_desc: 'BRESLAU'
  },
  {
    cty_mun: '5432',
    municipality_desc: 'BRETHOUR'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'BRIGDEN'
  },
  {
    cty_mun: '3245',
    municipality_desc: 'BRIGHT'
  },
  {
    cty_mun: '1408',
    municipality_desc: 'BRIGHTON'
  },
  {
    cty_mun: '3829',
    municipality_desc: 'BRIGHTS GROVE'
  },
  {
    cty_mun: '4998',
    municipality_desc: 'BRITT'
  },
  {
    cty_mun: '1839',
    municipality_desc: 'BROCK'
  },
  {
    cty_mun: '4104',
    municipality_desc: 'BROCKTON'
  },
  {
    cty_mun: '0802',
    municipality_desc: 'BROCKVILLE'
  },
  {
    cty_mun: '3815',
    municipality_desc: 'BROOKE-ALVINSTON'
  },
  {
    cty_mun: '1809',
    municipality_desc: 'BROOKLIN'
  },
  {
    cty_mun: '3211',
    municipality_desc: 'BROWNSVILLE'
  },
  {
    cty_mun: '5721',
    municipality_desc: 'BRUCE MINES'
  },
  {
    cty_mun: '4719',
    municipality_desc: 'BRUDENELL LYNDOCH RAGLAN'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'BRUNNER'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'BRUSSELS'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'BURFORD'
  },
  {
    cty_mun: '3202',
    municipality_desc: 'BURGESSVILLE'
  },
  {
    cty_mun: '4922',
    municipality_desc: 'BURKS FALLS'
  },
  {
    cty_mun: '2402',
    municipality_desc: 'BURLINGTON'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'BURNT RIVER'
  },
  {
    cty_mun: '5128',
    municipality_desc: 'BURPEE AND MILLS'
  },
  {
    cty_mun: '2602',
    municipality_desc: 'CAISTOR CENTRE'
  },
  {
    cty_mun: '4706',
    municipality_desc: 'CALABOGIE'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'CALEDON'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'CALEDONIA'
  },
  {
    cty_mun: '4966',
    municipality_desc: 'CALLANDER'
  },
  {
    cty_mun: '4822',
    municipality_desc: 'CALVIN'
  },
  {
    cty_mun: '3006',
    municipality_desc: 'CAMBRIDGE'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'CAMDEN EAST'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'CAMERON'
  },
  {
    cty_mun: '3835',
    municipality_desc: 'CAMLACHIE'
  },
  {
    cty_mun: '1423',
    municipality_desc: 'CAMPBELLCROFT'
  },
  {
    cty_mun: '1435',
    municipality_desc: 'CAMPBELLFORD'
  },
  {
    cty_mun: '2402',
    municipality_desc: 'CAMPBELLVILLE'
  },
  {
    cty_mun: '2622',
    municipality_desc: 'CAMPDEN'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'CANFIELD'
  },
  {
    cty_mun: '1208',
    municipality_desc: 'CANNIFTON'
  },
  {
    cty_mun: '1839',
    municipality_desc: 'CANNINGTON'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'CAPREOL'
  },
  {
    cty_mun: '0701',
    municipality_desc: 'CARDINAL'
  },
  {
    cty_mun: '4104',
    municipality_desc: 'CARGILL'
  },
  {
    cty_mun: '0928',
    municipality_desc: 'CARLETON PLACE'
  },
  {
    cty_mun: '4936',
    municipality_desc: 'CARLING'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'CARLISLE'
  },
  {
    cty_mun: '1270',
    municipality_desc: 'CARLOW/MAYO'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'CARP'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'CARRYING PLACE'
  },
  {
    cty_mun: '5202',
    municipality_desc: 'CARTIER'
  },
  {
    cty_mun: '5429',
    municipality_desc: 'CASEY'
  },
  {
    cty_mun: '0302',
    municipality_desc: 'CASSELMAN'
  },
  {
    cty_mun: '1411',
    municipality_desc: 'CASTLETON'
  },
  {
    cty_mun: '1509',
    municipality_desc: 'CAVAN MONAGHAN'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'CAYUGA'
  },
  {
    cty_mun: '1944',
    municipality_desc: 'CEDAR VALLEY'
  },
  {
    cty_mun: '3418',
    municipality_desc: 'CENTRAL ELGIN'
  },
  {
    cty_mun: '1039',
    municipality_desc: 'CENTRAL FRONTENAC'
  },
  {
    cty_mun: '4030',
    municipality_desc: 'CENTRAL HURON'
  },
  {
    cty_mun: '5104',
    municipality_desc: 'CENTRAL MANITOULIN'
  },
  {
    cty_mun: '4010',
    municipality_desc: 'CENTRALIA'
  },
  {
    cty_mun: '1230',
    municipality_desc: 'CENTRE HASTINGS'
  },
  {
    cty_mun: '2326',
    municipality_desc: 'CENTRE WELLINGTON'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'CENTREVILLE'
  },
  {
    cty_mun: '1915',
    municipality_desc: 'CFB DOWNSVIEW'
  },
  {
    cty_mun: '1055',
    municipality_desc: 'CFB KINGSTON'
  },
  {
    cty_mun: '4845',
    municipality_desc: 'CFB NORTH BAY'
  },
  {
    cty_mun: '0607',
    municipality_desc: 'CFB OTTAWA UPLANDS'
  },
  {
    cty_mun: '1210',
    municipality_desc: 'CFB TRENTON'
  },
  {
    cty_mun: '4792',
    municipality_desc: 'CHALK RIVER'
  },
  {
    cty_mun: '5454',
    municipality_desc: 'CHAMBERLAIN'
  },
  {
    cty_mun: '0209',
    municipality_desc: 'CHAMPLAIN'
  },
  {
    cty_mun: '5292',
    municipality_desc: 'CHAPLEAU'
  },
  {
    cty_mun: '5289',
    municipality_desc: 'CHAPLEAU LOCALITY EDUCATION'
  },
  {
    cty_mun: '5924',
    municipality_desc: 'CHAPPLE'
  },
  {
    cty_mun: '5468',
    municipality_desc: 'CHAPUT HUGHES'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'CHARING CROSS'
  },
  {
    cty_mun: '5446',
    municipality_desc: 'CHARLTON AND DACK'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'CHATHAM-KENT'
  },
  {
    cty_mun: '4204',
    municipality_desc: 'CHATSWORTH'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'CHELMSFORD'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'CHELTENHAM'
  },
  {
    cty_mun: '4104',
    municipality_desc: 'CHEPSTOW'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'CHERRY VALLEY'
  },
  {
    cty_mun: '4103',
    municipality_desc: 'CHESLEY'
  },
  {
    cty_mun: '0511',
    municipality_desc: 'CHESTERVILLE'
  },
  {
    cty_mun: '4831',
    municipality_desc: 'CHISHOLM'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'CHURCHILL'
  },
  {
    cty_mun: '0201',
    municipality_desc: 'CHUTE A BLONDEAU'
  },
  {
    cty_mun: '1801',
    municipality_desc: 'CLAREMONT'
  },
  {
    cty_mun: '0316',
    municipality_desc: 'CLARENCE-ROCKLAND'
  },
  {
    cty_mun: '1039',
    municipality_desc: 'CLARENDON STATION'
  },
  {
    cty_mun: '1817',
    municipality_desc: 'CLARINGTON'
  },
  {
    cty_mun: '4242',
    municipality_desc: 'CLARKSBURG'
  },
  {
    cty_mun: '0940',
    municipality_desc: 'CLAYTON'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'CLEAR CREEK'
  },
  {
    cty_mun: '4329',
    municipality_desc: 'CLEARVIEW'
  },
  {
    cty_mun: '4030',
    municipality_desc: 'CLINTON'
  },
  {
    cty_mun: '1042',
    municipality_desc: 'CLOYNE'
  },
  {
    cty_mun: '5408',
    municipality_desc: 'COBALT'
  },
  {
    cty_mun: '4758',
    municipality_desc: 'COBDEN'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'COBOCONK'
  },
  {
    cty_mun: '1421',
    municipality_desc: 'COBOURG'
  },
  {
    cty_mun: '5639',
    municipality_desc: 'COCHRANE'
  },
  {
    cty_mun: '5602',
    municipality_desc: 'COCHRANE PLT'
  },
  {
    cty_mun: '5640',
    municipality_desc: 'COCHRANE-IROQ FALLS/BR-MATH LOCALITY'
  },
  {
    cty_mun: '5134',
    municipality_desc: 'COCKBURN ISLAND'
  },
  {
    cty_mun: '1254',
    municipality_desc: 'COE HILL'
  },
  {
    cty_mun: '1411',
    municipality_desc: 'COLBORNE'
  },
  {
    cty_mun: '4351',
    municipality_desc: 'COLDWATER'
  },
  {
    cty_mun: '5401',
    municipality_desc: 'COLEMAN'
  },
  {
    cty_mun: '4331',
    municipality_desc: 'COLLINGWOOD'
  },
  {
    cty_mun: '5803',
    municipality_desc: 'COLLINS DSA LOCALITY EDUCATION'
  },
  {
    cty_mun: '4726',
    municipality_desc: 'COMBERMERE'
  },
  {
    cty_mun: '1928',
    municipality_desc: 'CONCORD'
  },
  {
    cty_mun: '3029',
    municipality_desc: 'CONESTOGO'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'CONISTON'
  },
  {
    cty_mun: '5819',
    municipality_desc: 'CONMEE'
  },
  {
    cty_mun: '4207',
    municipality_desc: 'CONN'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'CONSECON'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'COOKSTOWN'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'COPPER CLIFF'
  },
  {
    cty_mun: '1208',
    municipality_desc: 'CORBYVILLE'
  },
  {
    cty_mun: '0402',
    municipality_desc: 'CORNWALL'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'CORUNNA'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'COUNTY OF BRANT'
  },
  {
    cty_mun: '1817',
    municipality_desc: 'COURTICE'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'COURTLAND'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'COURTRIGHT'
  },
  {
    cty_mun: '1411',
    municipality_desc: 'CRAMAHE'
  },
  {
    cty_mun: '4010',
    municipality_desc: 'CREDITON'
  },
  {
    cty_mun: '4329',
    municipality_desc: 'CREEMORE'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'CRYSLER'
  },
  {
    cty_mun: '2703',
    municipality_desc: 'CRYSTAL BEACH'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'CUMBERLAND'
  },
  {
    cty_mun: '4351',
    municipality_desc: 'CUMBERLAND BEACH'
  },
  {
    cty_mun: '0231',
    municipality_desc: 'CURRAN'
  },
  {
    cty_mun: '4706',
    municipality_desc: 'DACRE'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'DALKEITH'
  },
  {
    cty_mun: '4010',
    municipality_desc: 'DASHWOOD'
  },
  {
    cty_mun: '3806',
    municipality_desc: 'DAWN-EUPHEMIA'
  },
  {
    cty_mun: '5934',
    municipality_desc: 'DAWSON'
  },
  {
    cty_mun: '4796',
    municipality_desc: 'DEEP RIVER'
  },
  {
    cty_mun: '3939',
    municipality_desc: 'DELAWARE'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'DELHI'
  },
  {
    cty_mun: '0831',
    municipality_desc: 'DELTA'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'DEMORESTVILLE'
  },
  {
    cty_mun: '1134',
    municipality_desc: 'DENBIGH'
  },
  {
    cty_mun: '3954',
    municipality_desc: 'DENFIELD'
  },
  {
    cty_mun: '4204',
    municipality_desc: 'DESBORO'
  },
  {
    cty_mun: '1202',
    municipality_desc: 'DESERONTO'
  },
  {
    cty_mun: '4798',
    municipality_desc: 'DEUX RIVIERES'
  },
  {
    cty_mun: '3926',
    municipality_desc: 'DORCHESTER'
  },
  {
    cty_mun: '5834',
    municipality_desc: 'DORION'
  },
  {
    cty_mun: '4742',
    municipality_desc: 'DOUGLAS'
  },
  {
    cty_mun: '1522',
    municipality_desc: 'DOURO-DUMMER'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'DOVER CENTRE'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'DOWLING'
  },
  {
    cty_mun: '2332',
    municipality_desc: 'DRAYTON'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'DRESDEN'
  },
  {
    cty_mun: '3245',
    municipality_desc: 'DRUMBO'
  },
  {
    cty_mun: '0919',
    municipality_desc: 'DRUMMOND/NORTH ELMSLEY'
  },
  {
    cty_mun: '6026',
    municipality_desc: 'DRYDEN'
  },
  {
    cty_mun: '3130',
    municipality_desc: 'DUBLIN'
  },
  {
    cty_mun: '5779',
    municipality_desc: 'DUBREUILVILLE'
  },
  {
    cty_mun: '4939',
    municipality_desc: 'DUNCHURCH'
  },
  {
    cty_mun: '4207',
    municipality_desc: 'DUNDALK'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'DUNDAS'
  },
  {
    cty_mun: '4070',
    municipality_desc: 'DUNGANNON'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'DUNNVILLE'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'DUNSFORD'
  },
  {
    cty_mun: '4329',
    municipality_desc: 'DUNTROON'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'DUNVEGAN'
  },
  {
    cty_mun: '4205',
    municipality_desc: 'DURHAM'
  },
  {
    cty_mun: '3429',
    municipality_desc: 'DUTTON/DUNWICH'
  },
  {
    cty_mun: '4427',
    municipality_desc: 'DWIGHT'
  },
  {
    cty_mun: '4624',
    municipality_desc: 'DYSART ET AL'
  },
  {
    cty_mun: '6042',
    municipality_desc: 'EAR FALLS'
  },
  {
    cty_mun: '5436',
    municipality_desc: 'EARLTON'
  },
  {
    cty_mun: '4834',
    municipality_desc: 'EAST FERRIS'
  },
  {
    cty_mun: '2201',
    municipality_desc: 'EAST GARAFRAXA'
  },
  {
    cty_mun: '1954',
    municipality_desc: 'EAST GWILLIMBURY'
  },
  {
    cty_mun: '0201',
    municipality_desc: 'EAST HAWKESBURY'
  },
  {
    cty_mun: '4999',
    municipality_desc: 'EAST PARRY SOUND BOARD OF EDUCATION'
  },
  {
    cty_mun: '1999',
    municipality_desc: 'EAST YORK'
  },
  {
    cty_mun: '3238',
    municipality_desc: 'EAST ZORRA-TAVISTOCK'
  },
  {
    cty_mun: '3401',
    municipality_desc: 'EDEN'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'EDWARDS'
  },
  {
    cty_mun: '0701',
    municipality_desc: 'EDWARDSBURGH/CARDINAL'
  },
  {
    cty_mun: '4738',
    municipality_desc: 'EGANVILLE'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'EGMONDVILLE'
  },
  {
    cty_mun: '1248',
    municipality_desc: 'ELDORADO'
  },
  {
    cty_mun: '0831',
    municipality_desc: 'ELGIN'
  },
  {
    cty_mun: '0801',
    municipality_desc: 'ELIZABETHTOWN-KITLEY'
  },
  {
    cty_mun: '5442',
    municipality_desc: 'ELK LAKE'
  },
  {
    cty_mun: '5741',
    municipality_desc: 'ELLIOT LAKE'
  },
  {
    cty_mun: '3029',
    municipality_desc: 'ELMIRA'
  },
  {
    cty_mun: '4341',
    municipality_desc: 'ELMVALE'
  },
  {
    cty_mun: '4205',
    municipality_desc: 'ELMWOOD'
  },
  {
    cty_mun: '2326',
    municipality_desc: 'ELORA'
  },
  {
    cty_mun: '3227',
    municipality_desc: 'EMBRO'
  },
  {
    cty_mun: '0306',
    municipality_desc: 'EMBRUN'
  },
  {
    cty_mun: '5919',
    municipality_desc: 'EMO'
  },
  {
    cty_mun: '4914',
    municipality_desc: 'EMSDALE'
  },
  {
    cty_mun: '5452',
    municipality_desc: 'ENGLEHART'
  },
  {
    cty_mun: '3816',
    municipality_desc: 'ENNISKILLEN'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'ENTERPRISE'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'ERIEAU'
  },
  {
    cty_mun: '2316',
    municipality_desc: 'ERIN'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'ERINSVILLE'
  },
  {
    cty_mun: '5226',
    municipality_desc: 'ESPANOLA'
  },
  {
    cty_mun: '5283',
    municipality_desc: 'ESPANOLA LOCALITY EDUCATION'
  },
  {
    cty_mun: '4321',
    municipality_desc: 'ESSA'
  },
  {
    cty_mun: '4382',
    municipality_desc: 'ESSA TOWNSHIP - ESSA CFB PART'
  },
  {
    cty_mun: '3754',
    municipality_desc: 'ESSEX'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'ETHEL'
  },
  {
    cty_mun: '1999',
    municipality_desc: 'ETOBICOKE'
  },
  {
    cty_mun: '5128',
    municipality_desc: 'EVANSVILLE'
  },
  {
    cty_mun: '5449',
    municipality_desc: 'EVANTUREL'
  },
  {
    cty_mun: '4301',
    municipality_desc: 'EVERETT'
  },
  {
    cty_mun: '4010',
    municipality_desc: 'EXETER'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'FALCONBRIDGE'
  },
  {
    cty_mun: '1258',
    municipality_desc: 'FARADAY'
  },
  {
    cty_mun: '5652',
    municipality_desc: 'FAUQUIER-STRICKLAND'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'FENELON FALLS'
  },
  {
    cty_mun: '2732',
    municipality_desc: 'FENWICK'
  },
  {
    cty_mun: '2326',
    municipality_desc: 'FERGUS'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'FEVERSHAM'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'FINCH'
  },
  {
    cty_mun: '3424',
    municipality_desc: 'FINGAL'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'FISHERVILLE'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'FLESHERTON'
  },
  {
    cty_mun: '1134',
    municipality_desc: 'FLINTON'
  },
  {
    cty_mun: '3806',
    municipality_desc: 'FLORENCE'
  },
  {
    cty_mun: '5293',
    municipality_desc: 'FOLEYET DSA LOCALITY EDUCATION'
  },
  {
    cty_mun: '2732',
    municipality_desc: 'FONTHILL'
  },
  {
    cty_mun: '3845',
    municipality_desc: 'FOREST'
  },
  {
    cty_mun: '4758',
    municipality_desc: 'FORESTERS FALLS'
  },
  {
    cty_mun: '4105',
    municipality_desc: 'FORMOSA'
  },
  {
    cty_mun: '2703',
    municipality_desc: 'FORT ERIE'
  },
  {
    cty_mun: '5912',
    municipality_desc: 'FORT FRANCES'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'FOURNIER'
  },
  {
    cty_mun: '1208',
    municipality_desc: 'FOXBORO'
  },
  {
    cty_mun: '1204',
    municipality_desc: 'FRANKFORD'
  },
  {
    cty_mun: '5201',
    municipality_desc: 'FRENCH RIVER'
  },
  {
    cty_mun: '0806',
    municipality_desc: 'FRONT YONGE'
  },
  {
    cty_mun: '1001',
    municipality_desc: 'FRONTENAC ISLANDS'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'GADSHILL'
  },
  {
    cty_mun: '0814',
    municipality_desc: 'GANANOQUE'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'GARSON'
  },
  {
    cty_mun: '5466',
    municipality_desc: 'GAUTHIER'
  },
  {
    cty_mun: '2415',
    municipality_desc: 'GEORGETOWN'
  },
  {
    cty_mun: '4465',
    municipality_desc: 'GEORGIAN BAY'
  },
  {
    cty_mun: '4203',
    municipality_desc: 'GEORGIAN BLUFFS'
  },
  {
    cty_mun: '1970',
    municipality_desc: 'GEORGINA'
  },
  {
    cty_mun: '5876',
    municipality_desc: 'GERALDTON'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'GILFORD'
  },
  {
    cty_mun: '5812',
    municipality_desc: 'GILLIES'
  },
  {
    cty_mun: '1248',
    municipality_desc: 'GILMOUR'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'GLEN MORRIS'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'GLEN ROBERTSON'
  },
  {
    cty_mun: '1011',
    municipality_desc: 'GLENBURNIE'
  },
  {
    cty_mun: '4301',
    municipality_desc: 'GLENCAIRN'
  },
  {
    cty_mun: '3906',
    municipality_desc: 'GLENCOE'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'GLOUCESTER'
  },
  {
    cty_mun: '4028',
    municipality_desc: 'GODERICH'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'GODFREY'
  },
  {
    cty_mun: '5295',
    municipality_desc: 'GOGAMA DSA LOCALITY EDUCATION'
  },
  {
    cty_mun: '4769',
    municipality_desc: 'GOLDEN LAKE'
  },
  {
    cty_mun: '4999',
    municipality_desc: 'GOLDEN VALLEY'
  },
  {
    cty_mun: '5124',
    municipality_desc: 'GORDON/BARRIE ISLAND'
  },
  {
    cty_mun: '5126',
    municipality_desc: 'GORE BAY'
  },
  {
    cty_mun: '1419',
    municipality_desc: 'GORES LANDING'
  },
  {
    cty_mun: '1944',
    municipality_desc: 'GORMLEY'
  },
  {
    cty_mun: '4046',
    municipality_desc: 'GORRIE'
  },
  {
    cty_mun: '3140',
    municipality_desc: 'GOWANSTOWN'
  },
  {
    cty_mun: '5490',
    municipality_desc: 'GOWGANDA'
  },
  {
    cty_mun: '1450',
    municipality_desc: 'GRAFTON'
  },
  {
    cty_mun: '3845',
    municipality_desc: 'GRAND BEND'
  },
  {
    cty_mun: '2204',
    municipality_desc: 'GRAND VALLEY'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'GRANDE POINTE'
  },
  {
    cty_mun: ['3120', '3958'],
    municipality_desc: 'GRANTON'
  },
  // {
  //   cty_mun: '3958',
  //   municipality_desc: 'GRANTON'
  // },
  {
    cty_mun: '4402',
    municipality_desc: 'GRAVENHURST'
  },
  {
    cty_mun: '4706',
    municipality_desc: 'GREATER MADAWASKA'
  },
  {
    cty_mun: '1121',
    municipality_desc: 'GREATER NAPANEE'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'GREATER SUDBURY'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'GREELY'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'GREEN VALLEY'
  },
  {
    cty_mun: '5876',
    municipality_desc: 'GREENSTONE'
  },
  {
    cty_mun: '1801',
    municipality_desc: 'GREENWOOD'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'GREY HIGHLANDS'
  },
  {
    cty_mun: '4706',
    municipality_desc: 'GRIFFITH'
  },
  {
    cty_mun: '2615',
    municipality_desc: 'GRIMSBY'
  },
  {
    cty_mun: '2308',
    municipality_desc: 'GUELPH'
  },
  {
    cty_mun: '2311',
    municipality_desc: 'GUELPH ERAMOSA'
  },
  {
    cty_mun: '5202',
    municipality_desc: 'HAGAR'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'HAGERSVILLE'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'HALDIMAND COUNTY'
  },
  {
    cty_mun: '4758',
    municipality_desc: 'HALEY STATION'
  },
  {
    cty_mun: '5620',
    municipality_desc: 'HALLEBOURG'
  },
  {
    cty_mun: '2415',
    municipality_desc: 'HALTON HILLS'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'HAMILTON'
  },
  {
    cty_mun: '1419',
    municipality_desc: 'HAMILTON TOWNSHIP'
  },
  {
    cty_mun: '0316',
    municipality_desc: 'HAMMOND'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'HANMER'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'HANNON'
  },
  {
    cty_mun: '4229',
    municipality_desc: 'HANOVER'
  },
  {
    cty_mun: '5426',
    municipality_desc: 'HARLEY'
  },
  {
    cty_mun: '5414',
    municipality_desc: 'HARRIS'
  },
  {
    cty_mun: '2341',
    municipality_desc: 'HARRISTON'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'HARROWSMITH'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'HARTINGTON'
  },
  {
    cty_mun: '5670',
    municipality_desc: 'HARTY'
  },
  {
    cty_mun: '1419',
    municipality_desc: 'HARWOOD'
  },
  {
    cty_mun: '1435',
    municipality_desc: 'HASTINGS'
  },
  {
    cty_mun: '1290',
    municipality_desc: 'HASTINGS HIGHLANDS'
  },
  {
    cty_mun: '1531',
    municipality_desc: 'HAVELOCK-BELMONT-METHUEN'
  },
  {
    cty_mun: '0208',
    municipality_desc: 'HAWKESBURY'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'HAWKESTONE'
  },
  {
    cty_mun: '1817',
    municipality_desc: 'HAYDON'
  },
  {
    cty_mun: '4798',
    municipality_desc: 'HEAD CLARA MARIA'
  },
  {
    cty_mun: '5676',
    municipality_desc: 'HEARST'
  },
  {
    cty_mun: '5620',
    municipality_desc: 'HEARST LOCALITY EDUCATION'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'HEATHCOTE'
  },
  {
    cty_mun: '3024',
    municipality_desc: 'HEIDELBERG'
  },
  {
    cty_mun: '4020',
    municipality_desc: 'HENSALL'
  },
  {
    cty_mun: '4102',
    municipality_desc: 'HEPWORTH'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'HIGHGATE'
  },
  {
    cty_mun: '4601',
    municipality_desc: 'HIGHLANDS EAST'
  },
  {
    cty_mun: '5434',
    municipality_desc: 'HILLIARD'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'HILLIER'
  },
  {
    cty_mun: '2316',
    municipality_desc: 'HILLSBURGH'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'HILLSDALE'
  },
  {
    cty_mun: '5704',
    municipality_desc: 'HILTON'
  },
  {
    cty_mun: '5706',
    municipality_desc: 'HILTON BEACH'
  },
  {
    cty_mun: '4204',
    municipality_desc: 'HOLLAND CENTRE'
  },
  {
    cty_mun: '1954',
    municipality_desc: 'HOLLAND LANDING'
  },
  {
    cty_mun: '4207',
    municipality_desc: 'HOLSTEIN'
  },
  {
    cty_mun: '5614',
    municipality_desc: 'HOLTYRE'
  },
  {
    cty_mun: '4107',
    municipality_desc: 'HOLYROOD'
  },
  {
    cty_mun: '4465',
    municipality_desc: 'HONEY HARBOUR'
  },
  {
    cty_mun: '2409',
    municipality_desc: 'HORNBY'
  },
  {
    cty_mun: '5796',
    municipality_desc: 'HORNEPAYNE'
  },
  {
    cty_mun: '4746',
    municipality_desc: 'HORTON'
  },
  {
    cty_mun: '4046',
    municipality_desc: 'HOWICK'
  },
  {
    cty_mun: '5421',
    municipality_desc: 'HUDSON'
  },
  {
    cty_mun: '4442',
    municipality_desc: 'HUNTSVILLE'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'HURON EAST'
  },
  {
    cty_mun: '5724',
    municipality_desc: 'HURON SHORES'
  },
  {
    cty_mun: '4107',
    municipality_desc: 'HURON-KINLOSS'
  },
  {
    cty_mun: '6001',
    municipality_desc: 'IGNACE'
  },
  {
    cty_mun: '3939',
    municipality_desc: 'ILDERTON'
  },
  {
    cty_mun: '3218',
    municipality_desc: 'INGERSOLL'
  },
  {
    cty_mun: '0406',
    municipality_desc: 'INGLESIDE'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'INGLEWOOD'
  },
  {
    cty_mun: '3238',
    municipality_desc: 'INNERKIP'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'INNISFIL'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'INVERARY'
  },
  {
    cty_mun: '3429',
    municipality_desc: 'IONA STATION'
  },
  {
    cty_mun: '0506',
    municipality_desc: 'IROQUOIS'
  },
  {
    cty_mun: '5631',
    municipality_desc: 'IROQUOIS FALLS'
  },
  {
    cty_mun: '5442',
    municipality_desc: 'JAMES'
  },
  {
    cty_mun: '5699',
    municipality_desc: 'JAMES BAY LOWLANDS LOCALITY EDUCATION'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'JANETVILLE'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'JARVIS'
  },
  {
    cty_mun: '0801',
    municipality_desc: 'JASPER'
  },
  {
    cty_mun: '5876',
    municipality_desc: 'JELLICOE'
  },
  {
    cty_mun: '5701',
    municipality_desc: 'JOCELYN'
  },
  {
    cty_mun: '5716',
    municipality_desc: 'JOHNSON'
  },
  {
    cty_mun: '0701',
    municipality_desc: 'JOHNSTOWN'
  },
  {
    cty_mun: '4951',
    municipality_desc: 'JOLY'
  },
  {
    cty_mun: '2622',
    municipality_desc: 'JORDAN STATION'
  },
  {
    cty_mun: '5121',
    municipality_desc: 'KAGAWONG'
  },
  {
    cty_mun: '5808',
    municipality_desc: 'KAKABEKA FALLS'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'KANATA'
  },
  {
    cty_mun: '5630',
    municipality_desc: 'KAP-SRF AND DIST LOCALITY EDUCATION'
  },
  {
    cty_mun: '5666',
    municipality_desc: 'KAPUSKASING'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'KARS'
  },
  {
    cty_mun: '4919',
    municipality_desc: 'KATRINE'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'KAWARTHA LAKES'
  },
  {
    cty_mun: '4918',
    municipality_desc: 'KEARNEY'
  },
  {
    cty_mun: '5458',
    municipality_desc: 'KEARNS'
  },
  {
    cty_mun: '4203',
    municipality_desc: 'KEMBLE'
  },
  {
    cty_mun: '0719',
    municipality_desc: 'KEMPTVILLE'
  },
  {
    cty_mun: '6016',
    municipality_desc: 'KENORA'
  },
  {
    cty_mun: '6088',
    municipality_desc: 'KENORA DIST (PAT) PLT (TOWNSHIPS)'
  },
  {
    cty_mun: '6089',
    municipality_desc: 'KENORA DIST (PAT) PLT (UNSV)'
  },
  {
    cty_mun: '6098',
    municipality_desc: 'KENORA DIST PLT (TOWNSHIPS)'
  },
  {
    cty_mun: '6099',
    municipality_desc: 'KENORA DIST PLT (UNSV)'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'KENT BRIDGE'
  },
  {
    cty_mun: '5424',
    municipality_desc: 'KERNS'
  },
  {
    cty_mun: '3946',
    municipality_desc: 'KERWOOD'
  },
  {
    cty_mun: '1970',
    municipality_desc: 'KESWICK'
  },
  {
    cty_mun: '1949',
    municipality_desc: 'KETTLEBY'
  },
  {
    cty_mun: '4731',
    municipality_desc: 'KILLALOE, HAGARTY & RICHARDS'
  },
  {
    cty_mun: '5136',
    municipality_desc: 'KILLARNEY'
  },
  {
    cty_mun: '4402',
    municipality_desc: 'KILWORTHY'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'KIMBERLEY'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'KINBURN'
  },
  {
    cty_mun: '4108',
    municipality_desc: 'KINCARDINE'
  },
  {
    cty_mun: '1949',
    municipality_desc: 'KING'
  },
  {
    cty_mun: '5480',
    municipality_desc: 'KING KIRKLAND'
  },
  {
    cty_mun: '1011',
    municipality_desc: 'KINGSTON'
  },
  {
    cty_mun: '3711',
    municipality_desc: 'KINGSVILLE'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'KINMOUNT'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'KIPPEN'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'KIRKFIELD'
  },
  {
    cty_mun: '5468',
    municipality_desc: 'KIRKLAND LAKE'
  },
  {
    cty_mun: '5480',
    municipality_desc: 'KIRKLAND LAKE LOCALITY EDUCATION'
  },
  {
    cty_mun: '3120',
    municipality_desc: 'KIRKTON'
  },
  {
    cty_mun: '3012',
    municipality_desc: 'KITCHENER'
  },
  {
    cty_mun: '1928',
    municipality_desc: 'KLEINBURG'
  },
  {
    cty_mun: '3939',
    municipality_desc: 'KOMOKA'
  },
  {
    cty_mun: '6096',
    municipality_desc: 'KPDSB-DRYDEN LOCALITY EDUCATION'
  },
  {
    cty_mun: '6007',
    municipality_desc: 'KPDSB-KENORA LOCALITY EDUCATION'
  },
  {
    cty_mun: '6093',
    municipality_desc: 'KPDSB-MACHIN TSA'
  },
  {
    cty_mun: '6095',
    municipality_desc: 'KPDSB-RED LAKE'
  },
  {
    cty_mun: '6097',
    municipality_desc: 'KPDSB-STURGEON LAKE DSB LOCALITY ED'
  },
  {
    cty_mun: '6060',
    municipality_desc: 'KPDSB-VAN HORNE AND WAINWRIGHT'
  },
  {
    cty_mun: '0209',
    municipality_desc: "L'ORIGNAL"
  },
  {
    cty_mun: '3310',
    municipality_desc: 'LA SALETTE'
  },
  {
    cty_mun: '5916',
    municipality_desc: 'LA VALLEE'
  },
  {
    cty_mun: '5711',
    municipality_desc: 'LAIRD'
  },
  {
    cty_mun: '4427',
    municipality_desc: 'LAKE OF BAYS'
  },
  {
    cty_mun: '5946',
    municipality_desc: 'LAKE OF THE WOODS'
  },
  {
    cty_mun: '5815',
    municipality_desc: 'LAKEHEAD LOCALITY EDUCATION'
  },
  {
    cty_mun: '3751',
    municipality_desc: 'LAKESHORE'
  },
  {
    cty_mun: '3227',
    municipality_desc: 'LAKESIDE'
  },
  {
    cty_mun: '3845',
    municipality_desc: 'LAMBTON SHORES'
  },
  {
    cty_mun: '0940',
    municipality_desc: 'LANARK HIGHLANDS'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'LANCASTER'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'LANGTON'
  },
  {
    cty_mun: '0812',
    municipality_desc: 'LANSDOWNE'
  },
  {
    cty_mun: '5462',
    municipality_desc: 'LARDER LAKE'
  },
  {
    cty_mun: '3734',
    municipality_desc: 'LASALLE'
  },
  {
    cty_mun: '5406',
    municipality_desc: 'LATCHFORD'
  },
  {
    cty_mun: '4792',
    municipality_desc: 'LAURENTIAN HILLS'
  },
  {
    cty_mun: '4766',
    municipality_desc: 'LAURENTIAN VALLEY'
  },
  {
    cty_mun: '3706',
    municipality_desc: 'LEAMINGTON'
  },
  {
    cty_mun: '0812',
    municipality_desc: 'LEEDS & THOUSAND ISLANDS'
  },
  {
    cty_mun: '0231',
    municipality_desc: 'LEFAIVRE'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'LEFROY'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'LEVACK'
  },
  {
    cty_mun: '2415',
    municipality_desc: 'LIMEHOUSE'
  },
  {
    cty_mun: '1251',
    municipality_desc: 'LIMERICK'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'LIMOGES'
  },
  {
    cty_mun: '2622',
    municipality_desc: 'LINCOLN'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'LINDSAY'
  },
  {
    cty_mun: '3024',
    municipality_desc: 'LINWOOD'
  },
  {
    cty_mun: '4109',
    municipality_desc: 'LIONS HEAD'
  },
  {
    cty_mun: '4301',
    municipality_desc: 'LISLE'
  },
  {
    cty_mun: '3140',
    municipality_desc: 'LISTOWEL'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'LITTLE BRITAIN'
  },
  {
    cty_mun: '5119',
    municipality_desc: 'LITTLE CURRENT'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'LIVELY'
  },
  {
    cty_mun: '1936',
    municipality_desc: 'LOCUST HILL'
  },
  {
    cty_mun: '0831',
    municipality_desc: 'LOMBARDY'
  },
  {
    cty_mun: '4030',
    municipality_desc: 'LONDESBORO'
  },
  {
    cty_mun: '3936',
    municipality_desc: 'LONDON'
  },
  {
    cty_mun: '0406',
    municipality_desc: 'LONG SAULT'
  },
  {
    cty_mun: '5876',
    municipality_desc: 'LONGLAC'
  },
  {
    cty_mun: '4301',
    municipality_desc: 'LORETTO'
  },
  {
    cty_mun: '4999',
    municipality_desc: 'LORING'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'LOWBANKS'
  },
  {
    cty_mun: '1104',
    municipality_desc: 'LOYALIST'
  },
  {
    cty_mun: '3958',
    municipality_desc: 'LUCAN BIDDULPH'
  },
  {
    cty_mun: '4107',
    municipality_desc: 'LUCKNOW'
  },
  {
    cty_mun: '0406',
    municipality_desc: 'LUNENBURG'
  },
  {
    cty_mun: '0801',
    municipality_desc: 'LYN'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'LYNDEN'
  },
  {
    cty_mun: '0812',
    municipality_desc: 'LYNDHURST'
  },
  {
    cty_mun: '0911',
    municipality_desc: 'MABERLY'
  },
  {
    cty_mun: '5751',
    municipality_desc: 'MACDONALD MEREDITH ET AL'
  },
  {
    cty_mun: '4954',
    municipality_desc: 'MACHAR'
  },
  {
    cty_mun: '6021',
    municipality_desc: 'MACHIN'
  },
  {
    cty_mun: '4798',
    municipality_desc: 'MACKEY'
  },
  {
    cty_mun: '4465',
    municipality_desc: 'MACTIER'
  },
  {
    cty_mun: '4726',
    municipality_desc: 'MADAWASKA VALLEY'
  },
  {
    cty_mun: '1236',
    municipality_desc: 'MADOC'
  },
  {
    cty_mun: '4944',
    municipality_desc: 'MAGNETAWAN'
  },
  {
    cty_mun: '0706',
    municipality_desc: 'MAITLAND'
  },
  {
    cty_mun: '3408',
    municipality_desc: 'MALAHIDE'
  },
  {
    cty_mun: '0806',
    municipality_desc: 'MALLORYTOWN'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'MANILLA'
  },
  {
    cty_mun: '5102',
    municipality_desc: 'MANITOULIN LOCALITY EDUCATION'
  },
  {
    cty_mun: '5866',
    municipality_desc: 'MANITOUWADGE'
  },
  {
    cty_mun: '5111',
    municipality_desc: 'MANITOWANING'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'MANOTICK'
  },
  {
    cty_mun: '2216',
    municipality_desc: 'MANSFIELD'
  },
  {
    cty_mun: '1928',
    municipality_desc: 'MAPLE'
  },
  {
    cty_mun: '2332',
    municipality_desc: 'MAPLETON'
  },
  {
    cty_mun: '4102',
    municipality_desc: 'MAR'
  },
  {
    cty_mun: '5859',
    municipality_desc: 'MARATHON'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'MARKDALE'
  },
  {
    cty_mun: '1936',
    municipality_desc: 'MARKHAM'
  },
  {
    cty_mun: '5208',
    municipality_desc: 'MARKSTAY-WARREN'
  },
  {
    cty_mun: '1241',
    municipality_desc: 'MARMORA AND LAKE'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'MARTINTOWN'
  },
  {
    cty_mun: '1201',
    municipality_desc: 'MARYSVILLE'
  },
  {
    cty_mun: '5218',
    municipality_desc: 'MASSEY'
  },
  {
    cty_mun: '5456',
    municipality_desc: 'MATACHEWAN'
  },
  {
    cty_mun: '5614',
    municipality_desc: 'MATHESON'
  },
  {
    cty_mun: '4821',
    municipality_desc: 'MATTAWA'
  },
  {
    cty_mun: '4819',
    municipality_desc: 'MATTAWAN'
  },
  {
    cty_mun: '5677',
    municipality_desc: 'MATTICE-VAL COTE'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'MAXVILLE'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'MAXWELL'
  },
  {
    cty_mun: '1290',
    municipality_desc: 'MAYNOOTH'
  },
  {
    cty_mun: '0940',
    municipality_desc: 'MCDONALDS CORNERS'
  },
  {
    cty_mun: '4931',
    municipality_desc: 'MCDOUGALL'
  },
  {
    cty_mun: '5458',
    municipality_desc: 'MCGARRY'
  },
  {
    cty_mun: '4928',
    municipality_desc: 'MCKELLAR'
  },
  {
    cty_mun: '5228',
    municipality_desc: 'MCKERROW'
  },
  {
    cty_mun: '4912',
    municipality_desc: 'MCMURRICH/MONTEITH'
  },
  {
    cty_mun: '4701',
    municipality_desc: 'MCNAB/BRAESIDE'
  },
  {
    cty_mun: '4210',
    municipality_desc: 'MEAFORD'
  },
  {
    cty_mun: '2219',
    municipality_desc: 'MELANCTHON'
  },
  {
    cty_mun: '3916',
    municipality_desc: 'MELBOURNE'
  },
  {
    cty_mun: '5102',
    municipality_desc: 'MELDRUM BAY'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'MERLIN'
  },
  {
    cty_mun: '0714',
    municipality_desc: 'MERRICKVILLE-WOLFORD'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'METCALFE'
  },
  {
    cty_mun: '3939',
    municipality_desc: 'MIDDLESEX CENTRE'
  },
  {
    cty_mun: '4374',
    municipality_desc: 'MIDLAND'
  },
  {
    cty_mun: '4105',
    municipality_desc: 'MILDMAY'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'MILFORD'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'MILLBANK'
  },
  {
    cty_mun: '4109',
    municipality_desc: 'MILLER LAKE'
  },
  {
    cty_mun: '2409',
    municipality_desc: 'MILTON'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'MILVERTON'
  },
  {
    cty_mun: '5104',
    municipality_desc: 'MINDEMOYA'
  },
  {
    cty_mun: '4616',
    municipality_desc: 'MINDEN HILLS'
  },
  {
    cty_mun: '4341',
    municipality_desc: 'MINESING'
  },
  {
    cty_mun: '2341',
    municipality_desc: 'MINTO'
  },
  {
    cty_mun: '5298',
    municipality_desc: 'MISSARENDA DSA LOCALITY EDUCATION'
  },
  {
    cty_mun: '2105',
    municipality_desc: 'MISSISSAUGA'
  },
  {
    cty_mun: '0931',
    municipality_desc: 'MISSISSIPPI MILLS'
  },
  {
    cty_mun: '3130',
    municipality_desc: 'MITCHELL'
  },
  {
    cty_mun: '2409',
    municipality_desc: 'MOFFAT'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'MONKLAND'
  },
  {
    cty_mun: '3130',
    municipality_desc: 'MONKTON'
  },
  {
    cty_mun: '2212',
    municipality_desc: 'MONO'
  },
  {
    cty_mun: '0901',
    municipality_desc: 'MONTAGUE'
  },
  {
    cty_mun: '5656',
    municipality_desc: 'MOONBEAM'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'MOONSTONE'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'MOORETOWN'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'MOOSE CREEK'
  },
  {
    cty_mun: '5699',
    municipality_desc: 'MOOSE FACTORY'
  },
  {
    cty_mun: '5665',
    municipality_desc: 'MOOSONEE'
  },
  {
    cty_mun: '5931',
    municipality_desc: 'MORLEY'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'MORPETH'
  },
  {
    cty_mun: '4060',
    municipality_desc: 'MORRIS-TURNBERRY'
  },
  {
    cty_mun: '0506',
    municipality_desc: 'MORRISBURG'
  },
  {
    cty_mun: '3926',
    municipality_desc: 'MOSSLEY'
  },
  {
    cty_mun: '1954',
    municipality_desc: 'MOUNT ALBERT'
  },
  {
    cty_mun: '3211',
    municipality_desc: 'MOUNT ELGIN'
  },
  {
    cty_mun: '2349',
    municipality_desc: 'MOUNT FOREST'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'MOUNT HOPE'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'MOUNT PLEASANT'
  },
  {
    cty_mun: '0511',
    municipality_desc: 'MOUNTAIN'
  },
  {
    cty_mun: '1039',
    municipality_desc: 'MOUNTAIN GROVE'
  },
  {
    cty_mun: '3916',
    municipality_desc: 'MT BRYDGES'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'MT PLEASANT'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'MUIRKIRK'
  },
  {
    cty_mun: '2216',
    municipality_desc: 'MULMUR'
  },
  {
    cty_mun: '3916',
    municipality_desc: 'MUNCEY'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'MUNSTER'
  },
  {
    cty_mun: '5808',
    municipality_desc: 'MURILLO'
  },
  {
    cty_mun: '4453',
    municipality_desc: 'MUSKOKA LAKES'
  },
  {
    cty_mun: '5231',
    municipality_desc: 'NAIRN AND HYMAN'
  },
  {
    cty_mun: '5876',
    municipality_desc: 'NAKINA'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'NANTICOKE'
  },
  {
    cty_mun: '1121',
    municipality_desc: 'NAPANEE'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'NATION'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'NAUGHTON'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'NAVAN'
  },
  {
    cty_mun: '5805',
    municipality_desc: 'NDSA - ALLANWATER'
  },
  {
    cty_mun: '5885',
    municipality_desc: 'NDSA - ARMSTRONG'
  },
  {
    cty_mun: '5883',
    municipality_desc: 'NDSA - AUDEN'
  },
  {
    cty_mun: '5884',
    municipality_desc: 'NDSA - FERLAND'
  },
  {
    cty_mun: '5895',
    municipality_desc: 'NDSA - SAVANT LAKE'
  },
  {
    cty_mun: '5801',
    municipality_desc: 'NEEBING'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'NEPEAN'
  },
  {
    cty_mun: '4205',
    municipality_desc: 'NEUSTADT'
  },
  {
    cty_mun: '3018',
    municipality_desc: 'NEW DUNDEE'
  },
  {
    cty_mun: '3018',
    municipality_desc: 'NEW HAMBURG'
  },
  {
    cty_mun: '4329',
    municipality_desc: 'NEW LOWELL'
  },
  {
    cty_mun: '4324',
    municipality_desc: 'NEW TECUMSETH'
  },
  {
    cty_mun: '0831',
    municipality_desc: 'NEWBORO'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'NEWBURGH'
  },
  {
    cty_mun: '3902',
    municipality_desc: 'NEWBURY'
  },
  {
    cty_mun: '1817',
    municipality_desc: 'NEWCASTLE'
  },
  {
    cty_mun: '0406',
    municipality_desc: 'NEWINGTON'
  },
  {
    cty_mun: '1948',
    municipality_desc: 'NEWMARKET'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'NEWTON'
  },
  {
    cty_mun: '2725',
    municipality_desc: 'NIAGARA FALLS'
  },
  {
    cty_mun: '2627',
    municipality_desc: 'NIAGARA-ON-THE-LAKE'
  },
  {
    cty_mun: '5844',
    municipality_desc: 'NIPIGON'
  },
  {
    cty_mun: '4971',
    municipality_desc: 'NIPISSING'
  },
  {
    cty_mun: '4899',
    municipality_desc: 'NIPISSING COMBINED SCHOOL BOARDS'
  },
  {
    cty_mun: '4803',
    municipality_desc: 'NIPISSING PLT'
  },
  {
    cty_mun: '1949',
    municipality_desc: 'NOBLETON'
  },
  {
    cty_mun: '5201',
    municipality_desc: 'NOELVILLE'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'NORFOLK COUNTY'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'NORLAND'
  },
  {
    cty_mun: '4769',
    municipality_desc: 'NORTH ALGONA WILBERFORCE'
  },
  {
    cty_mun: '0706',
    municipality_desc: 'NORTH AUGUSTA'
  },
  {
    cty_mun: '4844',
    municipality_desc: 'NORTH BAY'
  },
  {
    cty_mun: '3001',
    municipality_desc: 'NORTH DUMFRIES'
  },
  {
    cty_mun: '0511',
    municipality_desc: 'NORTH DUNDAS'
  },
  {
    cty_mun: '1042',
    municipality_desc: 'NORTH FRONTENAC'
  },
  {
    cty_mun: '0111',
    municipality_desc: 'NORTH GLENGARRY'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'NORTH GOWER'
  },
  {
    cty_mun: '0719',
    municipality_desc: 'NORTH GRENVILLE'
  },
  {
    cty_mun: '4966',
    municipality_desc: 'NORTH HIMSWORTH TOWNSHIP'
  },
  {
    cty_mun: '4050',
    municipality_desc: 'NORTH HURON'
  },
  {
    cty_mun: '1536',
    municipality_desc: 'NORTH KAWARTHA'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'NORTH LANCASTER'
  },
  {
    cty_mun: '3954',
    municipality_desc: 'NORTH MIDDLESEX'
  },
  {
    cty_mun: '3140',
    municipality_desc: 'NORTH PERTH'
  },
  {
    cty_mun: '5740',
    municipality_desc: 'NORTH SHORE'
  },
  {
    cty_mun: '0411',
    municipality_desc: 'NORTH STORMONT'
  },
  {
    cty_mun: '1999',
    municipality_desc: 'NORTH YORK'
  },
  {
    cty_mun: '5119',
    municipality_desc: 'NORTHEASTERN MANITOULIN & THE ISLANDS'
  },
  {
    cty_mun: '4109',
    municipality_desc: 'NORTHERN BRUCE PENINSULA'
  },
  {
    cty_mun: '2415',
    municipality_desc: 'NORVAL'
  },
  {
    cty_mun: '3202',
    municipality_desc: 'NORWICH'
  },
  {
    cty_mun: '4329',
    municipality_desc: 'NOTTAWA'
  },
  {
    cty_mun: '4914',
    municipality_desc: 'NOVAR'
  },
  {
    cty_mun: '5816',
    municipality_desc: "O'CONNOR"
  },
  {
    cty_mun: '2920',
    municipality_desc: 'OAKLAND'
  },
  {
    cty_mun: '2401',
    municipality_desc: 'OAKVILLE'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'OAKWOOD'
  },
  {
    cty_mun: '5816',
    municipality_desc: 'OCONNOR'
  },
  {
    cty_mun: '1104',
    municipality_desc: 'ODESSA'
  },
  {
    cty_mun: '3816',
    municipality_desc: 'OIL CITY'
  },
  {
    cty_mun: '3818',
    municipality_desc: 'OIL SPRINGS'
  },
  {
    cty_mun: '5808',
    municipality_desc: 'OLIVER PAIPOONGE'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'OMEMEE'
  },
  {
    cty_mun: '1042',
    municipality_desc: 'OMPAH'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'ONAPING'
  },
  {
    cty_mun: '5673',
    municipality_desc: 'OPASATIKA'
  },
  {
    cty_mun: '2214',
    municipality_desc: 'ORANGEVILLE'
  },
  {
    cty_mun: '4352',
    municipality_desc: 'ORILLIA'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'ORLEANS'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'ORO STATION'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'ORO-MEDONTE'
  },
  {
    cty_mun: '2316',
    municipality_desc: 'ORTON'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'OSGOODE'
  },
  {
    cty_mun: '1813',
    municipality_desc: 'OSHAWA'
  },
  {
    cty_mun: '1506',
    municipality_desc: 'OTONABEE-SOUTH MONAGHAN'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'OTTAWA'
  },
  {
    cty_mun: '3202',
    municipality_desc: 'OTTERVILLE'
  },
  {
    cty_mun: '4259',
    municipality_desc: 'OWEN SOUND'
  },
  {
    cty_mun: '0719',
    municipality_desc: 'OXFORD MILLS'
  },
  {
    cty_mun: '0719',
    municipality_desc: 'OXFORD STATION'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'PAIN COURT'
  },
  {
    cty_mun: '4103',
    municipality_desc: 'PAISLEY'
  },
  {
    cty_mun: '0931',
    municipality_desc: 'PAKENHAM'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'PALGRAVE'
  },
  {
    cty_mun: '4719',
    municipality_desc: 'PALMER RAPIDS'
  },
  {
    cty_mun: '3140',
    municipality_desc: 'PALMERSTON'
  },
  {
    cty_mun: '4816',
    municipality_desc: 'PAPINEAU-CAMERON'
  },
  {
    cty_mun: '1039',
    municipality_desc: 'PARHAM'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'PARIS'
  },
  {
    cty_mun: '3954',
    municipality_desc: 'PARKHILL'
  },
  {
    cty_mun: '4932',
    municipality_desc: 'PARRY SOUND'
  },
  {
    cty_mun: '5828',
    municipality_desc: 'PASS LAKE'
  },
  {
    cty_mun: '1970',
    municipality_desc: 'PEFFERLAW'
  },
  {
    cty_mun: '3701',
    municipality_desc: 'PELEE'
  },
  {
    cty_mun: '2732',
    municipality_desc: 'PELHAM'
  },
  {
    cty_mun: '4764',
    municipality_desc: 'PEMBROKE'
  },
  {
    cty_mun: '4372',
    municipality_desc: 'PENETANGUISHENE'
  },
  {
    cty_mun: '4368',
    municipality_desc: 'PERKINSFIELD'
  },
  {
    cty_mun: '4914',
    municipality_desc: 'PERRY'
  },
  {
    cty_mun: '0921',
    municipality_desc: 'PERTH'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'PERTH EAST'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'PERTH ROAD'
  },
  {
    cty_mun: '3120',
    municipality_desc: 'PERTH SOUTH'
  },
  {
    cty_mun: '4779',
    municipality_desc: 'PETAWAWA'
  },
  {
    cty_mun: '4795',
    municipality_desc: 'PETAWAWA GARRISON NORTH'
  },
  {
    cty_mun: '4797',
    municipality_desc: 'PETAWAWA GARRISON SOUTH'
  },
  {
    cty_mun: '1514',
    municipality_desc: 'PETERBOROUGH'
  },
  {
    cty_mun: '3018',
    municipality_desc: 'PETERSBURG'
  },
  {
    cty_mun: '3819',
    municipality_desc: 'PETROLIA'
  },
  {
    cty_mun: '1801',
    municipality_desc: 'PICKERING'
  },
  {
    cty_mun: '6049',
    municipality_desc: 'PICKLE LAKE'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'PICTON'
  },
  {
    cty_mun: '1208',
    municipality_desc: 'PLAINFIELD'
  },
  {
    cty_mun: '0231',
    municipality_desc: 'PLANTAGENET'
  },
  {
    cty_mun: '3245',
    municipality_desc: 'PLATTSVILLE'
  },
  {
    cty_mun: '1042',
    municipality_desc: 'PLEVNA'
  },
  {
    cty_mun: '5898',
    municipality_desc: 'PLT-SURVEYED'
  },
  {
    cty_mun: '5899',
    municipality_desc: 'PLT-UNSURVEYED'
  },
  {
    cty_mun: '5719',
    municipality_desc: 'PLUMMER ADDITIONAL'
  },
  {
    cty_mun: '3835',
    municipality_desc: 'PLYMPTON-WYOMING'
  },
  {
    cty_mun: '3831',
    municipality_desc: 'POINT EDWARD'
  },
  {
    cty_mun: '4905',
    municipality_desc: 'POINTE AU BARIL'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'PONTYPOOL'
  },
  {
    cty_mun: '5627',
    municipality_desc: 'PORCUPINE'
  },
  {
    cty_mun: '3401',
    municipality_desc: 'PORT BURWELL'
  },
  {
    cty_mun: '4453',
    municipality_desc: 'PORT CARLING'
  },
  {
    cty_mun: '2711',
    municipality_desc: 'PORT COLBORNE'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'PORT DOVER'
  },
  {
    cty_mun: '4110',
    municipality_desc: 'PORT ELGIN'
  },
  {
    cty_mun: '3845',
    municipality_desc: 'PORT FRANKS'
  },
  {
    cty_mun: '1423',
    municipality_desc: 'PORT HOPE'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'PORT LAMBTON'
  },
  {
    cty_mun: '4353',
    municipality_desc: 'PORT MCNICOLL'
  },
  {
    cty_mun: '1820',
    municipality_desc: 'PORT PERRY'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'PORT ROWAN'
  },
  {
    cty_mun: '4351',
    municipality_desc: 'PORT SEVERN'
  },
  {
    cty_mun: '3418',
    municipality_desc: 'PORT STANLEY'
  },
  {
    cty_mun: '4442',
    municipality_desc: 'PORT SYDNEY'
  },
  {
    cty_mun: '0831',
    municipality_desc: 'PORTLAND'
  },
  {
    cty_mun: '4959',
    municipality_desc: 'POWASSAN'
  },
  {
    cty_mun: '0708',
    municipality_desc: 'PRESCOTT'
  },
  {
    cty_mun: '4205',
    municipality_desc: 'PRICEVILLE'
  },
  {
    cty_mun: '5766',
    municipality_desc: 'PRINCE'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'PRINCE EDWARD COUNTY'
  },
  {
    cty_mun: '3245',
    municipality_desc: 'PRINCETON'
  },
  {
    cty_mun: '4207',
    municipality_desc: 'PROTON STATION'
  },
  {
    cty_mun: '5104',
    municipality_desc: 'PROVIDENCE BAY'
  },
  {
    cty_mun: '2301',
    municipality_desc: 'PUSLINCH'
  },
  {
    cty_mun: '1954',
    municipality_desc: 'QUEENSVILLE'
  },
  {
    cty_mun: '1204',
    municipality_desc: 'QUINTE WEST'
  },
  {
    cty_mun: '5942',
    municipality_desc: 'RAINY RIVER'
  },
  {
    cty_mun: ['5902', '5903', '5973', '5974'],
    municipality_desc: 'RAINY RIVER DIST LOCALITY ED'
  },
  // {
  //   cty_mun: '5903',
  //   municipality_desc: 'RAINY RIVER DIST LOCALITY ED'
  // },
  // {
  //   cty_mun: '5973',
  //   municipality_desc: 'RAINY RIVER DIST LOCALITY ED'
  // },
  // {
  //   cty_mun: '5974',
  //   municipality_desc: 'RAINY RIVER DIST LOCALITY ED'
  // },
  {
    cty_mun: '5998',
    municipality_desc: 'RAINY RIVER DIST PLT (TOWNSHIPS)'
  },
  {
    cty_mun: '5999',
    municipality_desc: 'RAINY RIVER DIST PLT (UNSV)'
  },
  {
    cty_mun: '4348',
    municipality_desc: 'RAMARA'
  },
  {
    cty_mun: '5614',
    municipality_desc: 'RAMORE'
  },
  {
    cty_mun: '4242',
    municipality_desc: 'RAVENNA'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'REABORO'
  },
  {
    cty_mun: '6041',
    municipality_desc: 'RED LAKE'
  },
  {
    cty_mun: '5841',
    municipality_desc: 'RED ROCK'
  },
  {
    cty_mun: '4748',
    municipality_desc: 'RENFREW'
  },
  {
    cty_mun: '4999',
    municipality_desc: 'RESTOULE'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'RICHMOND'
  },
  {
    cty_mun: '1938',
    municipality_desc: 'RICHMOND HILL'
  },
  {
    cty_mun: '0831',
    municipality_desc: 'RIDEAU LAKES'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'RIDGETOWN'
  },
  {
    cty_mun: '2732',
    municipality_desc: 'RIDGEVILLE'
  },
  {
    cty_mun: '2703',
    municipality_desc: 'RIDGEWAY'
  },
  {
    cty_mun: '4107',
    municipality_desc: 'RIPLEY'
  },
  {
    cty_mun: '1954',
    municipality_desc: 'RIVER DRIVE PARK'
  },
  {
    cty_mun: '1121',
    municipality_desc: 'ROBLIN'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'ROCKCLIFFE PARK'
  },
  {
    cty_mun: '0316',
    municipality_desc: 'ROCKLAND'
  },
  {
    cty_mun: '2311',
    municipality_desc: 'ROCKWOOD'
  },
  {
    cty_mun: '3434',
    municipality_desc: 'RODNEY'
  },
  {
    cty_mun: '1450',
    municipality_desc: 'ROSENEATH'
  },
  {
    cty_mun: '1230',
    municipality_desc: 'ROSLIN'
  },
  {
    cty_mun: '4903',
    municipality_desc: 'ROSSEAU'
  },
  {
    cty_mun: '4905',
    municipality_desc: 'ROSSEAU ROAD'
  },
  {
    cty_mun: '5811',
    municipality_desc: 'ROSSPORT'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'ROSTOCK'
  },
  {
    cty_mun: '0306',
    municipality_desc: 'RUSSELL'
  },
  {
    cty_mun: '4924',
    municipality_desc: 'RYERSON'
  },
  {
    cty_mun: '5218',
    municipality_desc: 'SABLES - SPANISH RIVERS'
  },
  {
    cty_mun: '3211',
    municipality_desc: 'SALFORD'
  },
  {
    cty_mun: '3829',
    municipality_desc: 'SARNIA'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'SARSFIELD'
  },
  {
    cty_mun: '4102',
    municipality_desc: 'SAUBLE BEACH'
  },
  {
    cty_mun: '4110',
    municipality_desc: 'SAUGEEN SHORES'
  },
  {
    cty_mun: '5761',
    municipality_desc: 'SAULT STE MARIE'
  },
  {
    cty_mun: '1999',
    municipality_desc: 'SCARBOROUGH'
  },
  {
    cty_mun: '1949',
    municipality_desc: 'SCHOMBERG'
  },
  {
    cty_mun: '5851',
    municipality_desc: 'SCHREIBER'
  },
  {
    cty_mun: '5627',
    municipality_desc: 'SCHUMACHER'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'SCOTLAND'
  },
  {
    cty_mun: '1820',
    municipality_desc: 'SCUGOG'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'SEAFORTH'
  },
  {
    cty_mun: '4348',
    municipality_desc: 'SEBRIGHT'
  },
  {
    cty_mun: '3120',
    municipality_desc: 'SEBRINGVILLE'
  },
  {
    cty_mun: '4903',
    municipality_desc: 'SEGUIN'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'SELKIRK'
  },
  {
    cty_mun: '1516',
    municipality_desc: 'SELWYN'
  },
  {
    cty_mun: '5480',
    municipality_desc: 'SESEKINIKA'
  },
  {
    cty_mun: '4351',
    municipality_desc: 'SEVERN'
  },
  {
    cty_mun: '4351',
    municipality_desc: 'SEVERN BRIDGE'
  },
  {
    cty_mun: '3110',
    municipality_desc: 'SHAKESPEARE'
  },
  {
    cty_mun: '4203',
    municipality_desc: 'SHALLOW LAKE'
  },
  {
    cty_mun: '1201',
    municipality_desc: 'SHANNONVILLE'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'SHANTY BAY'
  },
  {
    cty_mun: '1039',
    municipality_desc: 'SHARBOT LAKE'
  },
  {
    cty_mun: '1954',
    municipality_desc: 'SHARON'
  },
  {
    cty_mun: '3424',
    municipality_desc: 'SHEDDEN'
  },
  {
    cty_mun: '5119',
    municipality_desc: 'SHEGUIANDAH'
  },
  {
    cty_mun: '2221',
    municipality_desc: 'SHELBURNE'
  },
  {
    cty_mun: '2711',
    municipality_desc: 'SHERKSTON'
  },
  {
    cty_mun: '5828',
    municipality_desc: 'SHUNIAH'
  },
  {
    cty_mun: '5102',
    municipality_desc: 'SILVER WATER'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'SIMCOE'
  },
  {
    cty_mun: '4208',
    municipality_desc: 'SINGHAMPTON'
  },
  {
    cty_mun: '6034',
    municipality_desc: 'SIOUX LOOKOUT'
  },
  {
    cty_mun: '6008',
    municipality_desc: 'SIOUX NARROWS-NESTOR FALLS'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'SKEAD'
  },
  {
    cty_mun: '0904',
    municipality_desc: 'SMITHS FALLS'
  },
  {
    cty_mun: '2602',
    municipality_desc: 'SMITHVILLE'
  },
  {
    cty_mun: '5648',
    municipality_desc: 'SMOOTH ROCK FALLS'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'SOMBRA'
  },
  {
    cty_mun: '4801',
    municipality_desc: 'SOUTH ALGONQUIN'
  },
  {
    cty_mun: '4105',
    municipality_desc: 'SOUTH BRUCE'
  },
  {
    cty_mun: '4102',
    municipality_desc: 'SOUTH BRUCE PENINSULA'
  },
  {
    cty_mun: '0506',
    municipality_desc: 'SOUTH DUNDAS'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'SOUTH FRONTENAC'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'SOUTH GLENGARRY'
  },
  {
    cty_mun: '4010',
    municipality_desc: 'SOUTH HURON'
  },
  {
    cty_mun: '5627',
    municipality_desc: 'SOUTH PORCUPINE'
  },
  {
    cty_mun: '4956',
    municipality_desc: 'SOUTH RIVER'
  },
  {
    cty_mun: '4995',
    municipality_desc: 'SOUTH RIVER T.S.A.'
  },
  {
    cty_mun: '0406',
    municipality_desc: 'SOUTH STORMONT'
  },
  {
    cty_mun: '3211',
    municipality_desc: 'SOUTH-WEST OXFORD'
  },
  {
    cty_mun: '4110',
    municipality_desc: 'SOUTHAMPTON'
  },
  {
    cty_mun: '4207',
    municipality_desc: 'SOUTHGATE'
  },
  {
    cty_mun: '3906',
    municipality_desc: 'SOUTHWEST MIDDLESEX'
  },
  {
    cty_mun: '3424',
    municipality_desc: 'SOUTHWOLD'
  },
  {
    cty_mun: '5739',
    municipality_desc: 'SPANISH'
  },
  {
    cty_mun: '3418',
    municipality_desc: 'SPARTA'
  },
  {
    cty_mun: '0701',
    municipality_desc: 'SPENCERVILLE'
  },
  {
    cty_mun: '5104',
    municipality_desc: 'SPRING BAY'
  },
  {
    cty_mun: '1220',
    municipality_desc: 'SPRINGBROOK'
  },
  {
    cty_mun: '3408',
    municipality_desc: 'SPRINGFIELD'
  },
  {
    cty_mun: '3202',
    municipality_desc: 'SPRINGFORD'
  },
  {
    cty_mun: '4341',
    municipality_desc: 'SPRINGWATER'
  },
  {
    cty_mun: '4912',
    municipality_desc: 'SPRUCEDALE'
  },
  {
    cty_mun: '3018',
    municipality_desc: 'ST AGATHA'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'ST ALBERT'
  },
  {
    cty_mun: '0406',
    municipality_desc: 'ST ANDREWS WEST'
  },
  {
    cty_mun: '2602',
    municipality_desc: 'ST ANNS'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'ST BERNARDIN'
  },
  {
    cty_mun: '2629',
    municipality_desc: 'ST CATHARINES'
  },
  {
    cty_mun: '3805',
    municipality_desc: 'ST CLAIR'
  },
  {
    cty_mun: '3024',
    municipality_desc: 'ST CLEMENTS'
  },
  {
    cty_mun: '0201',
    municipality_desc: 'ST EUGENE'
  },
  {
    cty_mun: '2920',
    municipality_desc: 'ST GEORGE BRANT'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'ST ISIDORE'
  },
  {
    cty_mun: '3029',
    municipality_desc: 'ST JACOBS'
  },
  {
    cty_mun: '5708',
    municipality_desc: 'ST JOSEPH'
  },
  {
    cty_mun: '3116',
    municipality_desc: 'ST MARYS'
  },
  {
    cty_mun: '0316',
    municipality_desc: 'ST PASCAL BAYLON'
  },
  {
    cty_mun: '3130',
    municipality_desc: 'ST PAULS'
  },
  {
    cty_mun: '3421',
    municipality_desc: 'ST THOMAS'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'ST WILLIAMS'
  },
  {
    cty_mun: '5204',
    municipality_desc: 'ST. CHARLES'
  },
  {
    cty_mun: '3130',
    municipality_desc: 'STAFFA'
  },
  {
    cty_mun: '4329',
    municipality_desc: 'STAYNER'
  },
  {
    cty_mun: '1104',
    municipality_desc: 'STELLA'
  },
  {
    cty_mun: '2703',
    municipality_desc: 'STEVENSVILLE'
  },
  {
    cty_mun: '1220',
    municipality_desc: 'STIRLING-RAWDON'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'STITTSVILLE'
  },
  {
    cty_mun: '4109',
    municipality_desc: 'STOKES BAY'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'STONE MILLS'
  },
  {
    cty_mun: '4798',
    municipality_desc: 'STONECLIFFE'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'STONEY CREEK'
  },
  {
    cty_mun: '1944',
    municipality_desc: 'STOUFFVILLE'
  },
  {
    cty_mun: '3401',
    municipality_desc: 'STRAFFORDVILLE'
  },
  {
    cty_mun: '3111',
    municipality_desc: 'STRATFORD'
  },
  {
    cty_mun: '3916',
    municipality_desc: 'STRATHROY-CARADOC'
  },
  {
    cty_mun: '2105',
    municipality_desc: 'STREETSVILLE'
  },
  {
    cty_mun: '5652',
    municipality_desc: 'STRICKLAND'
  },
  {
    cty_mun: '4946',
    municipality_desc: 'STRONG'
  },
  {
    cty_mun: '4316',
    municipality_desc: 'STROUD'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'SUDBURY'
  },
  {
    cty_mun: '5299',
    municipality_desc: 'SUDBURY DISTRICT PLT'
  },
  {
    cty_mun: '5202',
    municipality_desc: 'SUDBURY LOCALITY EDUCATION'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'SUMMERSTOWN'
  },
  {
    cty_mun: '1839',
    municipality_desc: 'SUNDERLAND'
  },
  {
    cty_mun: '4948',
    municipality_desc: 'SUNDRIDGE'
  },
  {
    cty_mun: ['5810', '5811'],
    municipality_desc: 'SUPERIOR-GREENSTONE DIST LOCALITY ED'
  },
  // {
  //   cty_mun: '5811',
  //   municipality_desc: 'SUPERIOR-GREENSTONE DIST LOCALITY ED'
  // },
  {
    cty_mun: '1970',
    municipality_desc: 'SUTTON WEST'
  },
  {
    cty_mun: '5468',
    municipality_desc: 'SWASTIKA'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'SYDENHAM'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'TAMWORTH'
  },
  {
    cty_mun: '4103',
    municipality_desc: 'TARA'
  },
  {
    cty_mun: '5714',
    municipality_desc: 'TARBUTT TARBUTT ADDL'
  },
  {
    cty_mun: '5480',
    municipality_desc: 'TARZWELL'
  },
  {
    cty_mun: '3238',
    municipality_desc: 'TAVISTOCK'
  },
  {
    cty_mun: '4353',
    municipality_desc: 'TAY'
  },
  {
    cty_mun: '0911',
    municipality_desc: 'TAY VALLEY'
  },
  {
    cty_mun: '3744',
    municipality_desc: 'TECUMSEH'
  },
  {
    cty_mun: '4105',
    municipality_desc: 'TEESWATER'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'TEETERVILLE'
  },
  {
    cty_mun: '5101',
    municipality_desc: 'TEHKUMMAH'
  },
  {
    cty_mun: '4869',
    municipality_desc: 'TEMAGAMI'
  },
  {
    cty_mun: '5418',
    municipality_desc: 'TEMISKAMING SHORES'
  },
  {
    cty_mun: '2124',
    municipality_desc: 'TERRA COTTA'
  },
  {
    cty_mun: '5854',
    municipality_desc: 'TERRACE BAY'
  },
  {
    cty_mun: '3926',
    municipality_desc: 'THAMES CENTRE'
  },
  {
    cty_mun: '3227',
    municipality_desc: 'THAMESFORD'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'THAMESVILLE'
  },
  {
    cty_mun: '4905',
    municipality_desc: 'THE ARCHIPELAGO'
  },
  {
    cty_mun: '0212',
    municipality_desc: 'THE NATION'
  },
  {
    cty_mun: '3845',
    municipality_desc: 'THEDFORD'
  },
  {
    cty_mun: '5728',
    municipality_desc: 'THESSALON'
  },
  {
    cty_mun: '4242',
    municipality_desc: 'THORNBURY'
  },
  {
    cty_mun: '3926',
    municipality_desc: 'THORNDALE'
  },
  {
    cty_mun: '1928',
    municipality_desc: 'THORNHILL'
  },
  {
    cty_mun: '5438',
    municipality_desc: 'THORNLOE'
  },
  {
    cty_mun: '4321',
    municipality_desc: 'THORNTON'
  },
  {
    cty_mun: '2731',
    municipality_desc: 'THOROLD'
  },
  {
    cty_mun: '5804',
    municipality_desc: 'THUNDER BAY'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'TICHBORNE'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'TILBURY'
  },
  {
    cty_mun: '3204',
    municipality_desc: 'TILLSONBURG'
  },
  {
    cty_mun: '4898',
    municipality_desc: 'TIMISKAMING BOARD OF EDUCATION'
  },
  {
    cty_mun: '5490',
    municipality_desc: 'TIMISKAMING LOCALITY EDUCATION'
  },
  {
    cty_mun: '5499',
    municipality_desc: 'TIMISKAMING PLT'
  },
  {
    cty_mun: '5627',
    municipality_desc: 'TIMMINS'
  },
  {
    cty_mun: '4368',
    municipality_desc: 'TINY'
  },
  {
    cty_mun: '4108',
    municipality_desc: 'TIVERTON'
  },
  {
    cty_mun: '4109',
    municipality_desc: 'TOBERMORY'
  },
  {
    cty_mun: '1999',
    municipality_desc: 'TORONTO'
  },
  // {
  //   cty_mun: '1999',
  //   municipality_desc: 'TORONTO'
  // },
  // {
  //   cty_mun: '1999',
  //   municipality_desc: 'TORONTO'
  // },
  // {
  //   cty_mun: '1999',
  //   municipality_desc: 'TORONTO'
  // },
  // {
  //   cty_mun: '1999',
  //   municipality_desc: 'TORONTO'
  // },
  // {
  //   cty_mun: '1999',
  //   municipality_desc: 'TORONTO'
  // },
  {
    cty_mun: '4324',
    municipality_desc: 'TOTTENHAM'
  },
  {
    cty_mun: '2810',
    municipality_desc: 'TOWNSEND'
  },
  {
    cty_mun: '1435',
    municipality_desc: 'TRENT HILLS'
  },
  {
    cty_mun: '1542',
    municipality_desc: 'TRENT LAKES'
  },
  {
    cty_mun: '1204',
    municipality_desc: 'TRENTON'
  },
  {
    cty_mun: '4959',
    municipality_desc: 'TROUT CREEK'
  },
  {
    cty_mun: '1248',
    municipality_desc: 'TUDOR CASHEL'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'TUPPERVILLE'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'TURKEY POINT'
  },
  {
    cty_mun: '1231',
    municipality_desc: 'TWEED'
  },
  {
    cty_mun: '1201',
    municipality_desc: 'TYENDINAGA'
  },
  {
    cty_mun: '1970',
    municipality_desc: 'UDORA'
  },
  {
    cty_mun: '3418',
    municipality_desc: 'UNION'
  },
  {
    cty_mun: '1936',
    municipality_desc: 'UNIONVILLE'
  },
  {
    cty_mun: '5897',
    municipality_desc: 'UPSALA DSA LOCALITY EDUCATION'
  },
  {
    cty_mun: '4453',
    municipality_desc: 'UTTERSON'
  },
  {
    cty_mun: '1829',
    municipality_desc: 'UXBRIDGE'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'VAL CARON'
  },
  {
    cty_mun: '5614',
    municipality_desc: 'VAL GAGNE'
  },
  {
    cty_mun: '5670',
    municipality_desc: 'VAL RITA-HARTY'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'VAL THERESE'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'VANASTRA'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'VANESSA'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'VANIER'
  },
  {
    cty_mun: '0209',
    municipality_desc: 'VANKLEEK HILL'
  },
  {
    cty_mun: '4020',
    municipality_desc: 'VARNA'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'VARS'
  },
  {
    cty_mun: '1928',
    municipality_desc: 'VAUGHAN'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'VERNON'
  },
  {
    cty_mun: '1029',
    municipality_desc: 'VERONA'
  },
  {
    cty_mun: '4353',
    municipality_desc: 'VICTORIA HARBOUR'
  },
  {
    cty_mun: '3401',
    municipality_desc: 'VIENNA'
  },
  {
    cty_mun: '2622',
    municipality_desc: 'VINELAND STATION'
  },
  {
    cty_mun: '5458',
    municipality_desc: 'VIRGINIATOWN'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'VITTORIA'
  },
  {
    cty_mun: '6096',
    municipality_desc: 'WABIGOON'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'WAHNAPITAE'
  },
  {
    cty_mun: '2714',
    municipality_desc: 'WAINFLEET'
  },
  {
    cty_mun: '5218',
    municipality_desc: 'WALFORD STATION'
  },
  {
    cty_mun: '4104',
    municipality_desc: 'WALKERTON'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'WALLACEBURG'
  },
  {
    cty_mun: '3429',
    municipality_desc: 'WALLACETOWN'
  },
  {
    cty_mun: '3024',
    municipality_desc: 'WALLENSTEIN - north of Hwy 26'
  },
  {
    cty_mun: '2332',
    municipality_desc: 'WALLENSTEIN - south of Hwy 26'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'WALSINGHAM'
  },
  {
    cty_mun: '4040',
    municipality_desc: 'WALTON'
  },
  {
    cty_mun: '3906',
    municipality_desc: 'WARDSVILLE'
  },
  {
    cty_mun: '1435',
    municipality_desc: 'WARKWORTH'
  },
  {
    cty_mun: '4346',
    municipality_desc: 'WARMINSTER'
  },
  {
    cty_mun: '5208',
    municipality_desc: 'WARREN'
  },
  {
    cty_mun: '3841',
    municipality_desc: 'WARWICK'
  },
  {
    cty_mun: '4364',
    municipality_desc: 'WASAGA BEACH'
  },
  {
    cty_mun: '4348',
    municipality_desc: 'WASHAGO'
  },
  {
    cty_mun: '2518',
    municipality_desc: 'WATERDOWN'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'WATERFORD'
  },
  {
    cty_mun: '3016',
    municipality_desc: 'WATERLOO'
  },
  {
    cty_mun: '3841',
    municipality_desc: 'WATFORD'
  },
  {
    cty_mun: '4353',
    municipality_desc: 'WAUBAUSHENE'
  },
  {
    cty_mun: '5776',
    municipality_desc: 'WAWA'
  },
  {
    cty_mun: '5218',
    municipality_desc: 'WEBBWOOD'
  },
  {
    cty_mun: '2719',
    municipality_desc: 'WELLAND'
  },
  {
    cty_mun: '2602',
    municipality_desc: 'WELLANDPORT'
  },
  {
    cty_mun: '3024',
    municipality_desc: 'WELLESLEY'
  },
  {
    cty_mun: '1350',
    municipality_desc: 'WELLINGTON'
  },
  {
    cty_mun: '2349',
    municipality_desc: 'WELLINGTON NORTH'
  },
  {
    cty_mun: '0231',
    municipality_desc: 'WENDOVER'
  },
  {
    cty_mun: '3434',
    municipality_desc: 'WEST ELGIN'
  },
  {
    cty_mun: '4205',
    municipality_desc: 'WEST GREY'
  },
  {
    cty_mun: '2602',
    municipality_desc: 'WEST LINCOLN'
  },
  {
    cty_mun: '3434',
    municipality_desc: 'WEST LORNE'
  },
  {
    cty_mun: '4852',
    municipality_desc: 'WEST NIPISSING'
  },
  {
    cty_mun: '4998',
    municipality_desc: 'WEST PARRY SOUND BOARD OF EDUCATION'
  },
  {
    cty_mun: '3130',
    municipality_desc: 'WEST PERTH'
  },
  {
    cty_mun: '1011',
    municipality_desc: 'WESTBROOK'
  },
  {
    cty_mun: '4758',
    municipality_desc: 'WESTMEATH'
  },
  {
    cty_mun: '0842',
    municipality_desc: 'WESTPORT'
  },
  {
    cty_mun: '3650',
    municipality_desc: 'WHEATLEY'
  },
  {
    cty_mun: '1809',
    municipality_desc: 'WHITBY'
  },
  {
    cty_mun: '1944',
    municipality_desc: 'WHITCHURCH-STOUFFVILLE'
  },
  {
    cty_mun: '4701',
    municipality_desc: 'WHITE LAKE'
  },
  {
    cty_mun: '5791',
    municipality_desc: 'WHITE RIVER'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'WHITEFISH'
  },
  {
    cty_mun: '5283',
    municipality_desc: 'WHITEFISH FALLS'
  },
  {
    cty_mun: '4939',
    municipality_desc: 'WHITESTONE'
  },
  {
    cty_mun: '4758',
    municipality_desc: 'WHITEWATER REGION'
  },
  {
    cty_mun: '4102',
    municipality_desc: 'WIARTON'
  },
  {
    cty_mun: '0506',
    municipality_desc: 'WILLIAMSBURG'
  },
  {
    cty_mun: '4204',
    municipality_desc: 'WILLIAMSFORD'
  },
  {
    cty_mun: '0101',
    municipality_desc: 'WILLIAMSTOWN'
  },
  {
    cty_mun: '1970',
    municipality_desc: 'WILLOW BEACH'
  },
  {
    cty_mun: '3018',
    municipality_desc: 'WILMOT'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'WILSONVILLE'
  },
  {
    cty_mun: '0511',
    municipality_desc: 'WINCHESTER'
  },
  {
    cty_mun: '3310',
    municipality_desc: 'WINDHAM CENTRE'
  },
  {
    cty_mun: '3739',
    municipality_desc: 'WINDSOR'
  },
  {
    cty_mun: '4050',
    municipality_desc: 'WINGHAM'
  },
  {
    cty_mun: '1001',
    municipality_desc: 'WOLFE ISLAND'
  },
  {
    cty_mun: '1254',
    municipality_desc: 'WOLLASTON'
  },
  {
    cty_mun: '1928',
    municipality_desc: 'WOODBRIDGE'
  },
  {
    cty_mun: '4010',
    municipality_desc: 'WOODHAM'
  },
  {
    cty_mun: '0614',
    municipality_desc: 'WOODLAWN'
  },
  {
    cty_mun: '3242',
    municipality_desc: 'WOODSTOCK'
  },
  {
    cty_mun: '1651',
    municipality_desc: 'WOODVILLE'
  },
  {
    cty_mun: '1204',
    municipality_desc: 'WOOLER'
  },
  {
    cty_mun: '3029',
    municipality_desc: 'WOOLWICH'
  },
  {
    cty_mun: '5307',
    municipality_desc: 'WORTHINGTON'
  },
  {
    cty_mun: '4368',
    municipality_desc: 'WYEBRIDGE'
  },
  {
    cty_mun: '4368',
    municipality_desc: 'WYEVALE'
  },
  {
    cty_mun: '3835',
    municipality_desc: 'WYOMING'
  },
  {
    cty_mun: '1124',
    municipality_desc: 'YARKER'
  },
  {
    cty_mun: '1999',
    municipality_desc: 'YORK'
  },
  {
    cty_mun: '1829',
    municipality_desc: 'ZEPHYR'
  },
  {
    cty_mun: '3227',
    municipality_desc: 'ZORRA'
  },
  {
    cty_mun: '4020',
    municipality_desc: 'ZURICH'
  }
]
