import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Container,
    Button,
    Form,
    Table,
    Row,
    Col,
    Spinner,
    Alert
} from 'react-bootstrap'
import { updateInProgress } from '../APIService/APIService'
import { updateUpdatesInProgress } from '../../Reducers/UpdatesInProgress'
import CustomModal from '../Custom/CustomModal'
import { formatName } from '../../Common/Functions'
import { fields } from '../../Common/AdminValues'
import VwUpdates from '../../Common/VwUpdates'
import AdminContainer from '../../Common/AdminContainer'

import './InProgressTable.css'

function InProgressTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [searchField, setSearchField] = useState('roll_num')
    const [searchValue, setSearchValue] = useState('')
    const [modalType, setModalType] = useState('in-progress')
    const [modalBody, setModalBody] = useState(
        'Are you sure you would like to load the latest updates?'
    )
    const admin = useSelector(state => state.adminReducer.value)
    const updates = useSelector(state => state.updatesInProgressReducer.value)
    const [orderedUpdates, setOrderedUpdates] = useState(
        updates?.updatesInProgress
    )
    const [filteredUpdates, setFilteredUpdates] = useState(orderedUpdates)
    const keys = [
        'transaction_id',
        'in_progress_date',
        'in_progress_by',
        'completed'
    ]
    useEffect(() => {
        while (!updates) {
            setLoading(true)
        }
        setLoading(false)
    }, [])
    useEffect(() => {
        handleSearchChange({ target: { value: searchValue } }, orderedUpdates)
    }, [orderedUpdates])
    const Modal = () => {
        return (
            <CustomModal
                show={showModal}
                close={() => setShowModal(false)}
                dialog='modal-wide'
                title='Confirm'
                body={modalBody}
                footer={
                    <Button
                        onClick={() => {
                            if (modalType === 'in-progress') {
                                let toKeep = []
                                let toBeRemoved = []
                                orderedUpdates.forEach(e => {
                                    if (!e.in_progress_date) {
                                        toBeRemoved = [
                                            ...toBeRemoved,
                                            e.transaction_id
                                        ]
                                    } else {
                                        toKeep = [...toKeep, e]
                                    }
                                })
                                updateInProgress(
                                    admin.user_id,
                                    [],
                                    toBeRemoved
                                ).then(response => {
                                    // console.log(response)
                                    setModalType('after-in-progress')
                                    setModalBody(
                                        toBeRemoved.length +
                                            ' record(s) have been successfully removed from in-progress.'
                                    )
                                    dispatch(
                                        updateUpdatesInProgress({
                                            updatesInProgress: toKeep
                                        })
                                    )
                                    setOrderedUpdates(toKeep)
                                    setFilteredUpdates([])
                                    let temp = [...toKeep]
                                    if (searchValue) {
                                        temp = [...toKeep].filter(el => {
                                            return el[searchField]?.includes(
                                                searchValue?.toUpperCase()
                                            )
                                        })
                                    }
                                    setFilteredUpdates(temp)
                                })
                            } else {
                                setModalType('in-progress')
                                setShowModal(false)
                            }
                        }}
                    >
                        OK
                    </Button>
                }
            />
        )
    }
    const handleChange = e => {
        setSearchField(e.target.value)
    }
    const handleSearchChange = (e, values) => {
        setSearchValue(e.target.value)
        let temp = [...values] || [...orderedUpdates]
        if (e.target.value) {
            temp = ([...values] || [...orderedUpdates]).filter(el => {
                return el[searchField]?.includes(e.target.value?.toUpperCase())
            })
        }
        setFilteredUpdates(temp)
    }
    const childComponent = (
        <div>
            <VwUpdates />
        </div>
    )
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label='School Support Admin'
            >
                School Support Admin
            </h1>
            <div role='region' aria-label='In-Progress Updates'>
                <AdminContainer childComponent={childComponent} />
                {loading ? (
                    <Spinner
                        animation='border'
                        role='status'
                        className='mt-3'
                    />
                ) : (
                    <div>
                        <Form id='updates-table' className='mt-3'>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Select
                                            id='search_field'
                                            name='search_field'
                                            value={searchField}
                                            onChange={e => handleChange(e)}
                                            required
                                        >
                                            {fields.map((e, i) => {
                                                return (
                                                    e.value !== 'in_progress' &&
                                                    e.value !== 'complete' && (
                                                        <option
                                                            key={i}
                                                            value={e.value}
                                                        >
                                                            {e.text}
                                                        </option>
                                                    )
                                                )
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control
                                            type='text'
                                            name='search'
                                            value={searchValue}
                                            onChange={e => {
                                                handleSearchChange(e)
                                            }}
                                            placeholder='Search'
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Table striped>
                            <thead>
                                <tr>
                                    {fields.map((e, i) => {
                                        if (e.value !== 'complete') {
                                            return <th>{formatName(e.text)}</th>
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUpdates.map((e, i) => {
                                    return (
                                        <tr>
                                            {Object.entries(e).map(
                                                ([key, value]) => {
                                                    if (!keys.includes(key)) {
                                                        return (
                                                            <td>
                                                                {formatName(
                                                                    value
                                                                )}
                                                            </td>
                                                        )
                                                    }
                                                }
                                            )}
                                            <td>
                                                <Form>
                                                    <Form.Check
                                                        type='checkbox'
                                                        defaultChecked={
                                                            e.in_progress_date
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                e.in_progress_date
                                                            ) {
                                                                setOrderedUpdates(
                                                                    orderedUpdates.map(
                                                                        el =>
                                                                            el.transaction_id ===
                                                                            e.transaction_id
                                                                                ? {
                                                                                      ...el,
                                                                                      in_progress_date:
                                                                                          null,
                                                                                      in_progress_by:
                                                                                          null
                                                                                  }
                                                                                : el
                                                                    )
                                                                )
                                                            } else {
                                                                setOrderedUpdates(
                                                                    orderedUpdates.map(
                                                                        el =>
                                                                            el.transaction_id ===
                                                                            e.transaction_id
                                                                                ? {
                                                                                      ...el,
                                                                                      in_progress_date: true,
                                                                                      in_progress_by:
                                                                                          admin.user_id
                                                                                  }
                                                                                : el
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </Form>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <div className='button-container'>
                            <Button
                                onClick={() => {
                                    setModalBody(
                                        'Are you sure you would like to update the in-progress status of the selected records?'
                                    )
                                    setShowModal(true)
                                }}
                            >
                                Remove In-Progress
                            </Button>
                            <Button
                                className='button-margin'
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Back
                            </Button>
                        </div>
                        {showModal && <Modal />}
                    </div>
                )}
            </div>
        </Container>
    )
}

export default InProgressTable
