import { FaQuestionCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import CustomTooltip from '../Custom/CustomTooltip'

function TableTooltip(props) {
    const { t } = useTranslation(['tooltip', 'terms'])
    const contactUrl =
        props.lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    const TooltipText = () => {
        switch (props.column) {
            case 'citizenship_cd':
                return (
                    <>
                        {t('citizenship-cd.para-one', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('citizenship-cd.para-two', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('citizenship-cd.para-three', { ns: 'tooltip' })}
                    </>
                )
            case 'religion_cd':
                return (
                    <>
                        {t('religion-cd.para-one', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('religion-cd.para-two', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('religion-cd.para-three', { ns: 'tooltip' })}
                    </>
                )
            case 'french_language_rights_flg':
                return (
                    <>
                        {t('french-language-rights-flg.para-one', {
                            ns: 'tooltip'
                        })}
                        <br />
                        <br />
                        <ol>
                            <li>
                                {t('french-language-rights-flg.para-two', {
                                    ns: 'tooltip'
                                })}
                            </li>
                            <li>
                                {t('french-language-rights-flg.para-three', {
                                    ns: 'tooltip'
                                })}
                            </li>
                            <li>
                                {t('french-language-rights-flg.para-four', {
                                    ns: 'tooltip'
                                })}
                            </li>
                        </ol>
                    </>
                )
            case 'occupancy_cd':
                return (
                    <>
                        {t('occupancy-cd.para-one', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('occupancy-cd.para-two', { ns: 'tooltip' })}
                        <a
                            href={contactUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='blue'
                        >
                            {t('occupancy-cd.link', { ns: 'tooltip' })}
                        </a>
                        .
                        <br />
                        <br />
                        {t('occupancy-cd.para-three', { ns: 'tooltip' })}
                    </>
                )
            case 'residency_cd':
                return (
                    <>
                        {t('residency-cd.para-one', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('residency-cd.para-two', { ns: 'tooltip' })}
                    </>
                )
            case 'school_support_cd':
                return (
                    <>
                        {t('school-support-cd.para-one', { ns: 'tooltip' })}
                        <strong>
                            {t('school-support-cd.not-one', { ns: 'tooltip' })}
                        </strong>
                        {t('school-support-cd.para-two', { ns: 'tooltip' })}
                        <strong>
                            {t('school-support-cd.not-two', { ns: 'tooltip' })}
                        </strong>
                        {t('school-support-cd.para-three', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('school-support-cd.para-four', { ns: 'tooltip' })}
                        <strong>
                            {t('school-support-cd.not-one', { ns: 'tooltip' })}
                        </strong>
                        {t('school-support-cd.para-five', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('school-support-cd.para-six', { ns: 'tooltip' })}
                        <strong>
                            {t('school-support-cd.not-two', { ns: 'tooltip' })}
                        </strong>
                        {t('school-support-cd.para-seven', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('school-support-cd.para-eight', { ns: 'tooltip' })}
                        <br />
                        <br />
                        {t('school-support-cd.para-nine', { ns: 'tooltip' })}
                        <strong>
                            {t('school-support-cd.not-three', {
                                ns: 'tooltip'
                            })}
                        </strong>
                        {t('school-support-cd.para-ten', { ns: 'tooltip' })}
                    </>
                )
            case 'email_address':
                return (
                    <>
                        {t('para-twelve', { ns: 'terms' })}
                        <br />
                        <br />
                        {t('para-fourteen', { ns: 'terms' })}
                    </>
                )
            default:
                return ''
        }
    }
    return (
        <CustomTooltip
            placement='top'
            delay={props?.column === 'occupancy_cd' ? 1200 : 0}
            width={props.width || '500px'}
            content={<TooltipText />}
            display={
                <span className='table-icon'>
                    <FaQuestionCircle />
                </span>
            }
        />
    )
}

export default TableTooltip
