export const schoolSupport = [
    {
        schoolSupportCd: 'P',
        schoolSupportDesc: 'English-Public',
        schoolSupportFrenchDesc: 'Anglais-Public',
        activeFlg: 'Y'
    },
    {
        schoolSupportCd: 'S',
        schoolSupportDesc: 'English-Separate (Catholic)',
        schoolSupportFrenchDesc: 'Anglais-S\u00e9par\u00e9',
        activeFlg: 'Y'
    },
    {
        schoolSupportCd: 'D',
        schoolSupportDesc: 'Protestant-Separate',
        schoolSupportFrenchDesc: 'Prot.-S\u00e9par\u00e9',
        activeFlg: 'Y'
    },
    {
        schoolSupportCd: 'A',
        schoolSupportDesc: 'French-Public',
        schoolSupportFrenchDesc: 'Fran\u00e7ais-Public',
        activeFlg: 'Y'
    },
    {
        schoolSupportCd: 'C',
        schoolSupportDesc: 'French-Separate (Catholic)',
        schoolSupportFrenchDesc: 'Fran\u00e7ais-S\u00e9par\u00e9',
        activeFlg: 'Y'
    }
    // {
    //   schoolSupportCd: 'B',
    //   schoolSupportDesc: 'Split',
    //   schoolSupportFrenchDesc: 'R\u00e9partition l\u00e9gale',
    //   activeFlg: 'Y'
    // },
    // {
    //   schoolSupportCd: 'N',
    //   schoolSupportDesc: 'Not Applicable',
    //   schoolSupportFrenchDesc: "Ne s'applique pas",
    //   activeFlg: 'Y'
    // }
]
