import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Spinner,
    Alert
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FaQuestionCircle } from 'react-icons/fa'

import CustomTooltip from '../Custom/CustomTooltip'
import {
    getResident,
    getStreets,
    getStreetTypeCds,
    getStreetDirectionCds
} from '../APIService/APIService'
import { municipalities } from '../../Data/Municipalities'
import { addressObject } from '../Templates/AddressObject'
import { formInputObject } from '../Templates/FormInputObject'
import { userObject } from '../Templates/UserObject'
import { updateAddress } from '../../Reducers/Address'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateUserData } from '../../Reducers/UserData'
import { updateFormInput } from '../../Reducers/FormInput'
import RequiredField from '../Custom/RequiredField'
import './Search.css'

function Search({ lang }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation(['search', 'form', 'tooltip', 'common'])
    const contactUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    const [areaOccupied, setAreaOccupied] = useState('')
    const [byAddress, setByAddress] = useState(null)
    const [streets, setStreets] = useState([])
    const [streetTypeCds, setStreetTypeCds] = useState([])
    const [streetDirectionCds, setStreetDirectionCds] = useState([])
    const [loading, setLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [userData, setUserData] = useState(userObject)
    const municipalityRef = useRef()
    const streetRef = useRef()
    const streetTypeCdRef = useRef()
    const streetDirectionCdRef = useRef()

    useEffect(() => {
        dispatch(updateAddress(addressObject))
        dispatch(updateFormInput(formInputObject))
        dispatch(updateUserData(userObject))
        let toHide = document.getElementsByClassName('rbt-input-hint')
        for (let i = 0; i < toHide.length; i++) {
            toHide[i].style.display = 'none'
        }
    }, [])

    const handleChange = e => {
        if (e.target.name === 'address') {
            setByAddress(e.target.value)
        } else {
            setUserData(userData => {
                return { ...userData, [e.target.name]: e.target.value }
            })
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)
        let tempUser = {
            first_name: userData.first_name?.trim(),
            last_name: userData.last_name?.trim(),
            birth_dte: userData.birth_dte,
            municipality: userData.municipality,
            street_number: userData.street_number?.trim(),
            street_name: userData.street_name?.trim() || '',
            street_type_cd: userData.street_type_cd?.trim() || '',
            street_direction_cd: userData.street_direction_cd?.trim() || '',
            unit_identifier: userData.unit_identifier?.trim(),
            roll_num: userData.roll_num?.trim()
        }
        getResident(tempUser).then(response => {
            // console.log(response)
            setLoading(false)
            if (response.length && response[0].length) {
                let temp = response[0][0]
                temp = JSON.parse(temp)
                temp.area_occupied = areaOccupied
                dispatch(updateUserData(temp))
                let address = {
                    roll_num: temp.roll_num,
                    ips_property_id: temp.ips_property_id,
                    mun_address_30: temp.mun_address_30,
                    ips_subordinate_id: temp.ips_subordinate_id,
                    municipality: tempUser.municipality,
                    area_occupied: areaOccupied,
                    region_cd: temp.region_cd,
                    property_cd: temp.property_cd
                }
                dispatch(updateAddress(address))
                dispatch(
                    updateAuthentication({
                        authenticated: true,
                        type: 'T'
                    })
                )
                navigate('/school-support')
            } else {
                setShowError(true)
            }
        })
    }
    const clearFields = field => {
        if (field === 'municipality') {
            streetRef.current && streetRef.current.clear()
            streetTypeCdRef.current && streetTypeCdRef.current.clear()
        } else if (field === 'street_name') {
            streetTypeCdRef.current && streetTypeCdRef.current.clear()
        }
        streetDirectionCdRef.current && streetDirectionCdRef.current.clear()
    }
    const clearForm = () => {
        document.getElementById('user-form').reset()
        setUserData(userObject)
        setByAddress(null)
        municipalityRef.current && municipalityRef.current.clear()
        streetRef.current && streetRef.current.clear()
        streetTypeCdRef.current && streetTypeCdRef.current.clear()
        streetDirectionCdRef.current && streetDirectionCdRef.current.clear()
    }

    return (
        <Container className='inner-component' role='main'>
            <h1 role='heading' aria-label={t('title', { ns: 'search' })}>
                {t('title', { ns: 'search' })}
            </h1>
            <Form
                id='user-form'
                className='mt-3'
                onSubmit={e => handleSubmit(e)}
            >
                <fieldset>
                    <legend>{t('desc', { ns: 'search' })}</legend>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='first_name'>
                                    {t('first-name', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    id='first_name'
                                    name='first_name'
                                    value={userData.first_name}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='last_name'>
                                    {t('last-name', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    id='last_name'
                                    name='last_name'
                                    value={userData.last_name}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='birth_dte'>
                                    {t('birth-dte', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Control
                                    type='date'
                                    id='birth_dte'
                                    name='birth_dte'
                                    value={userData.birth_dte}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='municipality'>
                                    {t('municipality', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Typeahead
                                    inputProps={{
                                        id: 'municipality'
                                    }}
                                    id='municipality'
                                    ref={municipalityRef}
                                    labelKey='municipality_desc'
                                    options={municipalities}
                                    onChange={e => {
                                        setUserData(userData => {
                                            clearFields('municipality')
                                            if (e.length && e[0]) {
                                                let roll_num = ''
                                                if (
                                                    e.length &&
                                                    e[0].municipality_desc ===
                                                        'TORONTO'
                                                ) {
                                                    roll_num = '19'
                                                } else if (e.length) {
                                                    roll_num = e[0].cty_mun
                                                }
                                                if (e.length && e[0].cty_mun) {
                                                    getStreets(
                                                        e[0].cty_mun
                                                    ).then(response => {
                                                        if (response.length) {
                                                            let mergedArray =
                                                                [].concat.apply(
                                                                    [],
                                                                    response
                                                                )
                                                            let objectArray =
                                                                mergedArray.map(
                                                                    (e, i) => ({
                                                                        id: i?.toString(),
                                                                        street_name:
                                                                            e
                                                                    })
                                                                )
                                                            objectArray =
                                                                objectArray.filter(
                                                                    e =>
                                                                        e.street_name !==
                                                                        null
                                                                )
                                                            objectArray = [
                                                                ...objectArray,
                                                                {
                                                                    id: objectArray.length,
                                                                    street_name:
                                                                        'N/A'
                                                                }
                                                            ]
                                                            objectArray.sort(
                                                                (a, b) =>
                                                                    a.street_name.localeCompare(
                                                                        b.street_name
                                                                    )
                                                            )
                                                            setStreets(
                                                                objectArray?.length
                                                                    ? objectArray
                                                                    : [
                                                                          {
                                                                              id: 0,
                                                                              street_name:
                                                                                  'N/A'
                                                                          }
                                                                      ]
                                                            )
                                                        }
                                                    })
                                                }
                                                return {
                                                    ...userData,
                                                    municipality: e[0],
                                                    roll_num: roll_num,
                                                    street_name: '',
                                                    street_type_cd: '',
                                                    street_direction_cd: ''
                                                }
                                            } else {
                                                return {
                                                    ...userData,
                                                    municipality: '',
                                                    roll_num: '',
                                                    street_name: '',
                                                    street_type_cd: '',
                                                    street_direction_cd: ''
                                                }
                                            }
                                        })
                                    }}
                                    required
                                    autoComplete='new-password'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='area_occupied'>
                                    {t('area-occupied', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Select
                                    id='area_occupied'
                                    name='area_occupied'
                                    value={areaOccupied}
                                    onChange={e =>
                                        setAreaOccupied(e.target.value)
                                    }
                                    required
                                >
                                    <option value=''></option>
                                    <option value={1}>
                                        {t('ao-options.1', {
                                            ns: 'form'
                                        })}
                                    </option>
                                    <option value={2}>
                                        {t('ao-options.2', { ns: 'form' })}
                                    </option>
                                    <option value={3}>
                                        {t('ao-options.3', { ns: 'form' })}
                                    </option>
                                    <option value={4}>
                                        {t('ao-options.4', { ns: 'form' })}
                                    </option>
                                    <option value={5}>
                                        {t('ao-options.5', { ns: 'form' })}
                                    </option>
                                    <option value={8}>
                                        {t('ao-options.8', { ns: 'form' })}
                                    </option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className='mt-3'></Col>
                    </Row>
                </fieldset>
                <Row className='mt-3'>
                    <Form.Label>
                        {' '}
                        <div className='header-label'>
                            {t('property-location', { ns: 'search' })}
                        </div>
                    </Form.Label>
                    <Form.Group controlId='address'>
                        <Row
                            role='radiogroup'
                            aria-label={
                                t('by-address', { ns: 'search' }) +
                                '/' +
                                t('by-roll-num', { ns: 'search' })
                            }
                        >
                            <Col>
                                <Form.Check
                                    type='radio'
                                    id='By Address'
                                    name='address'
                                    value={true}
                                    label={t('by-address', { ns: 'search' })}
                                    // onChange={e => handleChange(e)}
                                    onClick={e => handleChange(e)}
                                    required
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type='radio'
                                    id='By Roll Number'
                                    name='address'
                                    value={false}
                                    label={t('by-roll-num', { ns: 'search' })}
                                    // onChange={e => handleChange(e)}
                                    onClick={e => handleChange(e)}
                                />
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                    </Form.Group>
                </Row>
                {byAddress === 'true' && (
                    <>
                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor='street_number'>
                                        {t('street-number', { ns: 'form' })}{' '}
                                        <RequiredField />{' '}
                                        {/* <CustomTooltip
                                            placement='right'
                                            content={
                                                <>
                                                    {t('street-name', {
                                                        ns: 'search'
                                                    })}
                                                </>
                                            }
                                            display={
                                                <span className='blue'>
                                                    <FaQuestionCircle />
                                                </span>
                                            }
                                        /> */}
                                    </Form.Label>
                                    <Form.Control
                                        type='text'
                                        id='street_number'
                                        name='street_number'
                                        value={userData.street_number}
                                        onChange={e => handleChange(e)}
                                        required={byAddress === 'true'}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor='street_name'>
                                        {t('street-name', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Typeahead
                                        inputProps={{
                                            id: 'street_name'
                                        }}
                                        id='street_name'
                                        ref={streetRef}
                                        labelKey={option =>
                                            `${option.street_name}`
                                        }
                                        options={streets}
                                        onChange={e => {
                                            clearFields('street_name')
                                            setUserData(userData => {
                                                if (e.length && e[0]) {
                                                    return {
                                                        ...userData,
                                                        street_name:
                                                            e[0].street_name,
                                                        street_type_cd: '',
                                                        street_direction_cd: ''
                                                    }
                                                } else {
                                                    return {
                                                        ...userData,
                                                        street_name: '',
                                                        street_type_cd: '',
                                                        street_direction_cd: ''
                                                    }
                                                }
                                            })
                                            if (e.length && e[0]) {
                                                getStreetTypeCds(
                                                    userData.municipality
                                                        ?.cty_mun,
                                                    e[0].street_name
                                                ).then(response => {
                                                    // console.log(response)
                                                    if (response.length) {
                                                        let mergedArray =
                                                            [].concat.apply(
                                                                [],
                                                                response
                                                            )
                                                        let objectArray =
                                                            mergedArray.map(
                                                                (e, i) => ({
                                                                    id: i?.toString(),
                                                                    street_type_cd:
                                                                        e
                                                                })
                                                            )
                                                        objectArray =
                                                            objectArray.filter(
                                                                e =>
                                                                    e.street_type_cd !==
                                                                    null
                                                            )
                                                        objectArray = [
                                                            ...objectArray,
                                                            {
                                                                id: objectArray.length,
                                                                street_type_cd:
                                                                    'N/A'
                                                            }
                                                        ]
                                                        objectArray.sort(
                                                            (a, b) =>
                                                                a.street_type_cd.localeCompare(
                                                                    b.street_type_cd
                                                                )
                                                        )
                                                        setStreetTypeCds(
                                                            objectArray?.length
                                                                ? objectArray
                                                                : [
                                                                      {
                                                                          id: 0,
                                                                          street_type_cd:
                                                                              'N/A'
                                                                      }
                                                                  ]
                                                        )
                                                    }
                                                })
                                            }
                                        }}
                                        defaultSelected={[
                                            {
                                                id: '0',
                                                street_name:
                                                    userData.street_name
                                            }
                                        ]}
                                        required
                                        autoComplete='new-password'
                                        disabled={!userData.municipality}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor='street_type_cd'>
                                        {t('street-type-cd', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Typeahead
                                        inputProps={{
                                            id: 'street_type_cd'
                                        }}
                                        id='street_type_cd'
                                        ref={streetTypeCdRef}
                                        labelKey={option =>
                                            `${option.street_type_cd}`
                                        }
                                        options={streetTypeCds}
                                        onChange={e => {
                                            clearFields('street_type_cd')
                                            let tempCd
                                            setUserData(userData => {
                                                if (e.length && e[0]) {
                                                    tempCd = e[0].street_type_cd
                                                    return {
                                                        ...userData,
                                                        street_type_cd:
                                                            e[0].street_type_cd,
                                                        street_direction_cd: ''
                                                    }
                                                } else {
                                                    return {
                                                        ...userData,
                                                        street_type_cd: '',
                                                        street_direction_cd: ''
                                                    }
                                                }
                                            })
                                            if (tempCd) {
                                                getStreetDirectionCds(
                                                    userData.municipality
                                                        ?.cty_mun,
                                                    userData.street_name,
                                                    e[0].street_type_cd
                                                ).then(response => {
                                                    if (response.length) {
                                                        let mergedArray =
                                                            [].concat.apply(
                                                                [],
                                                                response
                                                            )
                                                        let objectArray =
                                                            mergedArray.map(
                                                                (e, i) => ({
                                                                    id: i?.toString(),
                                                                    street_direction_cd:
                                                                        e
                                                                })
                                                            )
                                                        objectArray =
                                                            objectArray.filter(
                                                                e =>
                                                                    e.street_direction_cd !==
                                                                    null
                                                            )
                                                        setStreetDirectionCds(
                                                            objectArray || []
                                                        )
                                                    } else {
                                                        setStreetDirectionCds(
                                                            []
                                                        )
                                                    }
                                                })
                                            }
                                        }}
                                        defaultSelected={[
                                            {
                                                id: '0',
                                                street_type_cd:
                                                    userData.street_type_cd
                                            }
                                        ]}
                                        required
                                        autoComplete='new-password'
                                        disabled={!userData.street_name}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor='street_direction_cd'>
                                        {t('street-direction-cd', {
                                            ns: 'form'
                                        })}
                                    </Form.Label>
                                    <Typeahead
                                        inputProps={{
                                            id: 'street_direction_cd'
                                        }}
                                        id='street_direction_cd'
                                        ref={streetDirectionCdRef}
                                        labelKey={option =>
                                            `${option.street_direction_cd}`
                                        }
                                        options={streetDirectionCds}
                                        onChange={e => {
                                            setUserData(userData => {
                                                if (e.length && e[0]) {
                                                    return {
                                                        ...userData,
                                                        street_direction_cd:
                                                            e[0]
                                                                .street_direction_cd
                                                    }
                                                } else {
                                                    return {
                                                        ...userData,
                                                        street_direction_cd: ''
                                                    }
                                                }
                                            })
                                        }}
                                        required
                                        autoComplete='new-password'
                                        disabled={
                                            !userData.municipality ||
                                            !userData.street_name ||
                                            !userData.street_type_cd
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor='unit_identifier'>
                                        {t('unit-identifier', { ns: 'form' })}
                                    </Form.Label>
                                    <Form.Control
                                        id='unit_identifier'
                                        type='text'
                                        name='unit_identifier'
                                        value={userData.unit_identifier}
                                        onChange={e => handleChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                    </>
                )}
                {byAddress === 'false' && (
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor='roll_num'>
                                    {t('roll-num', { ns: 'form' })}{' '}
                                    <RequiredField />{' '}
                                    <CustomTooltip
                                        placement='right'
                                        content={
                                            <>
                                                {t('roll-num.para-one', {
                                                    ns: 'tooltip'
                                                })}
                                            </>
                                        }
                                        display={
                                            <span className='blue'>
                                                <FaQuestionCircle />
                                            </span>
                                        }
                                    />
                                </Form.Label>
                                <Form.Control
                                    id='roll_num'
                                    type='text'
                                    name='roll_num'
                                    value={userData.roll_num}
                                    onChange={e => handleChange(e)}
                                    required={byAddress === 'false'}
                                />
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                )}
                <div className='mt-3 button-container'>
                    <Button className='button-margin' onClick={clearForm}>
                        {t('buttons.clear', { ns: 'common' })}
                    </Button>
                    <Button
                        className='button-margin'
                        type='submit'
                        disabled={
                            !userData.municipality ||
                            (userData.roll_num?.length !== 15 &&
                                (!userData.street_number ||
                                    !userData.street_name ||
                                    !userData.street_type_cd))
                        }
                    >
                        {t('buttons.search', { ns: 'common' })}
                    </Button>
                    <br />
                    <br />
                    {loading && (
                        <Spinner
                            animation='border'
                            role='status'
                            className='mt-3'
                        />
                    )}
                </div>
            </Form>
            <div className='red'>{t('mandatory-field', { ns: 'search' })}</div>
            {showError && (
                <>
                    <Alert variant='danger' className='mt-3'>
                        {t('error-one', { ns: 'search' })}
                        <a
                            href={contactUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='contact-link'
                        >
                            {t('search-link', { ns: 'search' })}
                        </a>{' '}
                        {t('error-two', { ns: 'search' })}
                    </Alert>
                </>
            )}
        </Container>
    )
}

export default Search
