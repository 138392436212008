import { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Button, Spinner, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
    getTenantSchoolSupport,
    addTenantToProperty,
    updatePropertyResidentsTenant,
    updateSchoolSupport,
    sendResidentsEmail,
    getAllTenants,
    // updateSSTransactions,
    updateSSTransactionsDim,
    updateDelta,
    getResidents
} from '../APIService/APIService'
import CustomModal from '../Custom/CustomModal'
import { schoolSupport } from '../../Data/SchoolSupport'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateTenants } from '../../Reducers/Tenants'
// import { groupBy } from '../../Common/Functions'
import { addressObject } from '../Templates/AddressObject'
import { formInputObject } from '../Templates/FormInputObject'
import { userObject } from '../Templates/UserObject'
import { updateAddress } from '../../Reducers/Address'
import { updateFormInput } from '../../Reducers/FormInput'
import { updateUserData } from '../../Reducers/UserData'
import './Complete.css'

function Complete(props) {
    const { t } = useTranslation(['complete', 'form', 'tooltip', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const privacyUrl =
        props.lang === 'fr'
            ? 'https://www.mpac.ca/fr/AboutUs/Policies/PrivacyStatement'
            : 'https://www.mpac.ca/en/AboutUs/Policies/PrivacyStatement'
    const [authentication, setAuthentication] = useState(
        useSelector(state => state.authenticationReducer.value)
    )
    const [userData, setUserData] = useState(
        useSelector(state => state.userDataReducer.value)
    )
    const [address, setAddress] = useState(
        useSelector(state => state.addressReducer.value)
    )
    // const [formInput, setFormInput] = useState(
    //     useSelector(state => state.formInputReducer.value)
    // )
    const [tenants, setTenants] = useState(
        useSelector(state => state.tenantsReducer.value)
    )
    const [emailAddress, setEmailAddress] = useState('')
    const [showEmail, setShowEmail] = useState(false)
    const [schoolSupportCd, setSchoolSupportCd] = useState('')
    const [schoolSupportArray, setSchoolSupportArray] = useState([])
    const [showSchoolSupportModal, setShowSchoolSupportModal] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [checked, setChecked] = useState(false)
    const [awaitingResponse, setAwaitingResponse] = useState(false)
    const [allTenants, setAllTenants] = useState([])
    const originalSSCode = tenants?.length
        ? tenants[0]['property_school_support_cd']
        : ''
    useEffect(() => {
        if (!authentication || !authentication.authenticated) {
            navigate('/search')
        }
        getResidents({ rollNum: address.roll_num }).then(response => {
            let temp = []
            for (let i in response) {
                if (response[i].length) temp.push(JSON.parse(response[i][0]))
            }
            setAllTenants(temp)
        })
    }, [])
    const handleSubmit = val => {
        if (
            !emailAddress.match(
                /^[a-zA-Z0-9_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            )
        ) {
            setShowErrorAlert(true)
        } else {
            setShowErrorAlert(false)
            if (tenants && tenants.length) {
                let residentsToBeAdded = [...tenants.filter(e => e.new)]
                let residentsToBeUpdated = [...tenants.filter(e => e.updated)]
                let currentSsArray = [
                    ...new Set(
                        tenants.map(e =>
                            e.occupancy_cd !== 'B' ? e.school_support_cd : ''
                        )
                    )
                ]
                let currentIdsArray = [
                    ...new Set(tenants.map(e => e.ips_tenant_id))
                ]
                // get array of SS codes of other tenants with same subordinate id
                getTenantSchoolSupport(
                    address.roll_num,
                    userData.ips_subordinate_id
                ).then(response => {
                    if (!response.msg) {
                        mergeOriginalAndNewTenants()
                        if (!schoolSupportCd) {
                            let tempArray = [...new Set(response)]
                            tempArray.map((e, i) => {
                                if (
                                    !currentSsArray.includes(e[0]) &&
                                    !currentIdsArray.includes(e[1])
                                ) {
                                    currentSsArray = [...currentSsArray, e[0]]
                                }
                            })
                            let tempSsArray = schoolSupport.filter(e =>
                                currentSsArray.includes(e.schoolSupportCd)
                            )
                            // if there are multiple possible codes, show the modal to allow user to pick one SS code
                            if (tempSsArray.length > 1) {
                                setSchoolSupportArray(tempSsArray)
                                setShowSchoolSupportModal(true)
                                // else if there is only one possible code, update information
                            } else {
                                setSchoolSupportCd(
                                    tempSsArray[0]?.schoolSupportCd
                                )
                                if (
                                    originalSSCode !==
                                    tempSsArray[0]?.schoolSupportCd
                                ) {
                                    residentsToBeUpdated = [
                                        ...tenants.filter(e => !e.new)
                                    ]
                                }
                                callAPIs(
                                    residentsToBeAdded,
                                    residentsToBeUpdated,
                                    tempSsArray[0]?.schoolSupportCd ||
                                        originalSSCode
                                )
                            }
                        } else {
                            if (originalSSCode !== schoolSupportCd) {
                                residentsToBeUpdated = [
                                    ...tenants.filter(e => !e.new)
                                ]
                            }
                            callAPIs(
                                residentsToBeAdded,
                                residentsToBeUpdated,
                                schoolSupportCd
                            )
                        }
                    }
                })
            }
        }
    }
    const callAPIs = (residentsToBeAdded, residentsToBeUpdated, ssCd) => {
        let residentsDelta = []
        for (let i in residentsToBeAdded) {
            if (!residentsDelta.includes(residentsToBeAdded[i])) {
                residentsDelta.push(residentsToBeAdded[i])
            }
        }
        for (let i in residentsToBeUpdated) {
            if (!residentsDelta.includes(residentsToBeUpdated[i])) {
                residentsDelta.push(residentsToBeUpdated[i])
            }
        }
        setAwaitingResponse(true)
        addTenantToProperty(residentsToBeAdded, emailAddress)
            .then(response => {
                // console.log(response)
                return updatePropertyResidentsTenant(
                    residentsToBeUpdated,
                    emailAddress
                )
            })
            .then(response => {
                // console.log(response)
                let cd = schoolSupportCd || ssCd
                let cdString = ''
                if (Array.isArray(cd)) {
                    cd.map((e, i) => {
                        let val = t('ssc-options.' + e, { ns: 'form' })
                        if (i === 0) {
                            cdString = val
                        } else {
                            cdString = cdString + ',' + val
                        }
                    })
                } else {
                    cdString = t('ssc-options.' + cd, { ns: 'form' })
                }
                let hasBeenUpdated =
                    residentsToBeAdded.length || residentsToBeUpdated.length
                        ? true
                        : false
                return sendResidentsEmail(
                    emailAddress,
                    cdString,
                    address.mun_address_30,
                    hasBeenUpdated,
                    props.lang
                )
            })
            // .then(response => {
            //     // console.log(response)
            //     return setTimeout(updateSSTransactions(true), 0)
            // })
            .then(response => {
                return setTimeout(
                    updateSSTransactionsDim({
                        residents: residentsDelta,
                        address: address,
                        areaOccupied: address.area_occupied,
                        propertySchoolSupportCd: ssCd,
                        emailAddress: emailAddress
                    }),
                    0
                )
            })
            // .then(response => {
            //     console.log(residentsDelta)
            //     return setTimeout(
            //         updateDelta({
            //             residents: residentsDelta,
            //             munAddress30: address.mun_address_30,
            //             areaOccupied: address.area_occupied,
            //             propertySchoolSupportCd: schoolSupportCd,
            //             emailAddress: emailAddress
            //         }),
            //         0
            //     )
            // })
            .then(response => {
                // console.log(response)
                return updateSchoolSupport(
                    residentsToBeAdded.concat(residentsToBeUpdated),
                    ssCd,
                    userData.ips_subordinate_id,
                    'T'
                )
            })
            .then(response => {
                // console.log(response)
                dispatch(updateAuthentication({}))
                dispatch(updateTenants([]))
                dispatch(updateUserData(userObject))
                dispatch(updateAddress(addressObject))
                dispatch(updateFormInput(formInputObject))
                navigate('/confirmation')
            })
    }
    const SchoolSupportBody = () => {
        return (
            <Fragment>
                <Row className='mt-3'>
                    <Form.Group>
                        <Form.Label htmlFor='school_support_cd'>
                            {t('multi-ss', { ns: 'common' })}
                        </Form.Label>
                        <Col md={6}>
                            <Form.Select
                                id='school_support_cd'
                                name='school_support_cd'
                                value={schoolSupportCd}
                                onChange={e => {
                                    // console.log(e.target.value)
                                    setSchoolSupportCd(e.target.value)
                                }}
                            >
                                <option value=''></option>
                                {schoolSupportArray.map((e, i) => {
                                    // console.log(e)
                                    return (
                                        <option
                                            key={i}
                                            value={e?.schoolSupportCd}
                                        >
                                            {t(
                                                'ssc-options.' +
                                                    e?.schoolSupportCd,
                                                { ns: 'form' }
                                            )}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Row>
                <div className='attestation mt-3'>
                    {t('multi-ss-two', { ns: 'common' })}
                    <br />
                    <br />
                    {t('agree-one', { ns: 'common' })}
                    <a href={privacyUrl} target='_blank' rel='noreferrer'>
                        {t('agree-link', { ns: 'common' })}
                    </a>{' '}
                    <Form>
                        <Form.Check
                            id='i-agree'
                            className='mt-3 inline-block'
                            type='checkbox'
                            value='Y'
                            label={
                                <Button
                                    onClick={() => setChecked('Y')}
                                    htmlFor='i-agree'
                                >
                                    {t('buttons.i-agree', { ns: 'common' })}
                                </Button>
                            }
                            checked={checked === 'Y'}
                            onChange={e =>
                                setChecked(e.target.checked ? 'Y' : 'N')
                            }
                        />
                    </Form>
                </div>
            </Fragment>
        )
    }
    const mergeOriginalAndNewTenants = () => {
        let copy = [...tenants]
        for (let i in allTenants) {
            if (
                !tenants.find(
                    e =>
                        e.first_name === allTenants[i].first_name &&
                        e.last_name === allTenants[i].last_name &&
                        e.birth_dte === allTenants[i].birth_dte
                )
            ) {
                if (
                    allTenants[i].ips_subordinate_id ===
                    tenants[0].ips_subordinate_id
                ) {
                    console.log(allTenants[i])
                    copy.push(allTenants[i])
                }
            }
        }
        // console.log(copy)
        setTenants(copy)
    }
    const saveUser = () => {
        let tempArray = []
        let tempUser = userData
        if (tenants.length) {
            tempArray = [...tenants]
            let contains = false
            if (tempArray.length > 0) {
                tempArray.some(e => {
                    let obj1 = e
                    let obj2 = tempUser
                    if (obj1.ips_tenant_id) {
                        return obj1.ips_tenant_id === obj2.ips_tenant_id
                    } else {
                        return (
                            obj1.first_name === obj2.first_name &&
                            obj1.last_name === obj2.last_name &&
                            obj1.birth_dte === obj2.birth_dte
                        )
                    }
                })
            }
            if (!contains) {
                tempArray = [...tempArray, tempUser]
            } else {
                let index = -1
                if (tempUser.new) {
                    index = tempArray.findIndex(
                        e =>
                            e.first_name === tempUser.first_name &&
                            e.last_name === tempUser.last_name &&
                            e.birth_dte === tempUser.birth_dte
                    )
                } else {
                    index = tempArray.findIndex(
                        e =>
                            e.ips_tenant_id === tempUser.ips_tenant_id ||
                            e.subordinate_id === tempUser.subordinate_id
                    )
                }
                if (index !== -1) {
                    tempArray[index] = tempUser
                }
            }
        } else {
            tempArray = [...tempArray, tempUser]
        }
        setTenants(tempArray)
    }
    return (
        <Container className='inner-component' role='main'>
            {userData ? (
                <>
                    <h1
                        role='heading'
                        aria-label={t('title', { ns: 'complete' })}
                    >
                        {t('title', { ns: 'complete' })}
                    </h1>
                    <div
                        role='region'
                        aria-label={
                            userData.first_name?.toUpperCase() +
                            ' ' +
                            userData.last_name?.toUpperCase() +
                            t('para-one', { ns: 'complete' }) +
                            (userData.school_support_cd
                                ? t(
                                      'ssc-options.' +
                                          userData.school_support_cd,
                                      { ns: 'form' }
                                  )
                                : '')
                        }
                    >
                        {userData.first_name?.toUpperCase() +
                            ' ' +
                            userData.last_name?.toUpperCase()}
                        {t('para-one', { ns: 'complete' })}
                        <strong>
                            {userData.school_support_cd
                                ? t(
                                      'ssc-options.' +
                                          userData.school_support_cd,
                                      { ns: 'form' }
                                  )
                                : ''}
                        </strong>
                        .
                        <br />
                        <br />
                        {t('para-two', { ns: 'complete' })}
                    </div>
                    <div
                        className='button-container mt-3'
                        role='region'
                        aria-label={t('para-three', { ns: 'complete' })}
                    >
                        <Button
                            className='button-margin'
                            onClick={() => {
                                saveUser()
                                setShowEmail(true)
                            }}
                        >
                            {t('options.no', { ns: 'common' })}
                        </Button>
                        <Button
                            className='button-margin'
                            onClick={() => {
                                saveUser()
                                navigate('/update-school-support')
                            }}
                        >
                            {t('options.yes', { ns: 'common' })}
                        </Button>
                    </div>
                    {showEmail && (
                        <>
                            <Row>
                                <Col>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label htmlFor='email_address'>
                                                {t('para-three', {
                                                    ns: 'complete'
                                                })}
                                            </Form.Label>
                                            <Form.Control
                                                id='email_address'
                                                type='email'
                                                name='email_address'
                                                value={emailAddress}
                                                onChange={e => {
                                                    setEmailAddress(
                                                        e.target.value
                                                    )
                                                }}
                                                required
                                                className={
                                                    showErrorAlert &&
                                                    !emailAddress.match(
                                                        /^[a-zA-Z0-9_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                                    )
                                                        ? 'invalid-field'
                                                        : ''
                                                }
                                            />
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                            </Row>
                            {showErrorAlert && (
                                <div
                                    className='mt-3 alert alert-danger'
                                    role='alert'
                                >
                                    {t('ea-error', { ns: 'complete' })}
                                </div>
                            )}
                            <div
                                className='button-container mt-3'
                                role='region'
                                aria-label={
                                    t('buttons.cancel', { ns: 'common' }) +
                                    '/' +
                                    t('buttons.submit', { ns: 'common' })
                                }
                            >
                                <Button
                                    className='button-margin'
                                    onClick={() => {
                                        navigate(-1)
                                    }}
                                >
                                    {t('buttons.cancel', { ns: 'common' })}
                                </Button>
                                <Button
                                    className='button-margin'
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    disabled={awaitingResponse}
                                >
                                    {t('buttons.submit', { ns: 'common' })}
                                </Button>
                                {awaitingResponse && (
                                    <div>
                                        <Spinner
                                            animation='border'
                                            role='status'
                                            className='mt-3'
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {showSchoolSupportModal && (
                        <CustomModal
                            show={showSchoolSupportModal}
                            close={() => setShowSchoolSupportModal(false)}
                            dialog='modal-wide'
                            title='School Support'
                            body={<SchoolSupportBody />}
                            bodyText={t('multi-ss', { ns: 'common' })}
                            footer={
                                <Fragment>
                                    <Button
                                        onClick={() => {
                                            setShowSchoolSupportModal(false)
                                        }}
                                        disabled={!schoolSupportCd || !checked}
                                    >
                                        {t('buttons.save', { ns: 'common' })}
                                    </Button>
                                </Fragment>
                            }
                            footerText={t('buttons.save', { ns: 'common' })}
                        />
                    )}
                </>
            ) : (
                <>
                    {' '}
                    <Spinner
                        animation='border'
                        role='status'
                        className='mt-3'
                    />
                </>
            )}
        </Container>
    )
}

export default Complete
