import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { updateAuthentication } from '../../Reducers/Authentication'
import { healthCheck } from '../APIService/APIService'
import './Home.css'

function Home() {
    const { t } = useTranslation(['home', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userType, setUserType] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    useEffect(() => {
        let str = window.location.href
        let url = str.substring(str.lastIndexOf('/') + 1)
        if (url === 'health') {
            healthCheck().then(response => {
                // console.log(response)
            })
        }
    }, [])
    const handleChange = e => {
        setUserType(e.target.value)
    }
    const handleSubmit = e => {
        e.preventDefault()
        switch (userType) {
            case 'O':
                setShowAlert(true)
                break
            case 'T':
                dispatch(
                    updateAuthentication({
                        authenticated: false,
                        type: 'T'
                    })
                )
                navigate('/attestation')
                break
            default:
                break
        }
    }
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label={t('title', { ns: 'home' })}
            >
                {t('title', { ns: 'home' })}
            </h1>
            <Form className='mt-3' onSubmit={e => handleSubmit(e)}>
                <Row
                    role='radiogroup'
                    aria-label={
                        t('radio-one', { ns: 'home' }) +
                        '/' +
                        t('radio-two', { ns: 'home' })
                    }
                >
                    <Col>
                        <input
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='property-owner'
                            value='O'
                            checked={userType === 'O'}
                            onChange={e => handleChange(e)}
                            required
                        />
                        <label
                            className='form-check-label'
                            htmlFor='property-owner'
                        >
                            {t('radio-one', { ns: 'home' })}
                        </label>
                    </Col>
                    <Col>
                        <input
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='property-tenant'
                            value='T'
                            checked={userType === 'T'}
                            onChange={e => handleChange(e)}
                        />
                        <label
                            className='form-check-label'
                            htmlFor='property-tenant'
                        >
                            {t('radio-two', { ns: 'home' })}
                        </label>
                    </Col>
                </Row>
                <div className='mt-3 button-container'>
                    <Button type='submit'>
                        {t('buttons.submit', { ns: 'common' })}
                    </Button>
                </div>
            </Form>
            {showAlert && (
                <>
                    <Alert
                        id='home-alert'
                        className='mt-3'
                        role='region'
                        aria-label={
                            t('para-one', { ns: 'home' }) +
                            t('link', { ns: 'home' }) +
                            t('superscript', { ns: 'home' }) +
                            t('para-two', { ns: 'home' }) +
                            t('link', { ns: 'home' }) +
                            t('superscript', { ns: 'home' }) +
                            t('para-three', { ns: 'home' })
                        }
                    >
                        {t('para-one', { ns: 'home' })}
                        <a
                            className='blue'
                            href='https://www.aboutmyproperty.ca/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('link', { ns: 'home' })}
                            <sup>{t('superscript', { ns: 'home' })}</sup>
                        </a>{' '}
                        {t('para-two', { ns: 'home' })}
                        <a
                            className='blue'
                            href='https://www.aboutmyproperty.ca/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('link', { ns: 'home' })}
                            <sup>{t('superscript', { ns: 'home' })}</sup>
                        </a>{' '}
                        {t('para-three', { ns: 'home' })}
                    </Alert>
                </>
            )}
        </Container>
    )
}

export default Home
