import { createSlice } from '@reduxjs/toolkit'

export const formInputSlice = createSlice({
  name: 'formInput',
  initialState: {
    value: {
      check: null,
      update: null,
      citizenship_cd: null,
      religion_cd: null,
      french_one: null,
      french_two: null,
      french_three: null,
      french_language_rights_flg: null,
      school_support_cd: 'P'
    }
  },
  reducers: {
    updateFormInput: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateFormInput } = formInputSlice.actions

export default formInputSlice.reducer
