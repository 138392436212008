import CustomTooltip from '../Custom/CustomTooltip'
import { useTranslation } from 'react-i18next'

function RequiredField() {
    const { t } = useTranslation(['common'])
    return (
        <CustomTooltip
            placement='top'
            width={0}
            content={<>{t('required-field', { ns: 'common' })}</>}
            display={<span className='required-field'>*</span>}
        />
    )
}

export default RequiredField
