import { useTranslation } from 'react-i18next'

function TermsText({ lang }) {
    const { t } = useTranslation(['terms'])
    const contactUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    const feedbackUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/Feedback'
            : 'https://www.mpac.ca/en/Feedback'
    return (
        <ol>
            <li className='mt-3'>{t('para-one', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-two', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-three', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-four', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-five', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-six', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-seven', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-eight', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-nine', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-ten', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-eleven', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-twelve', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-thirteen', { ns: 'terms' })}</li>
            <li className='mt-3'>{t('para-fourteen', { ns: 'terms' })}</li>
            <li className='mt-3'>
                {t('para-fifteen', { ns: 'terms' })}
                <a
                    href={contactUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='blue'
                >
                    {t('terms-link', { ns: 'terms' })}
                </a>{' '}
                {t('para-sixteen', { ns: 'terms' })}
                <a
                    href={feedbackUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='blue'
                >
                    {t('terms-link-two', { ns: 'terms' })}
                </a>
            </li>
        </ol>
    )
}

export default TermsText
