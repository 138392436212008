import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateAdmin } from '../../Reducers/AdminUser'
import { Container, Button, Table, Spinner } from 'react-bootstrap'
import { decodeToken, getStats } from '../APIService/APIService'
import AdminContainer from '../../Common/AdminContainer'

import './Stats.css'

function Stats() {
    const authenticated = useSelector(
        state => state.authenticationReducer.value
    )
    const [loading, setLoading] = useState(true)
    const [updated, setUpdated] = useState(0)
    const [completed, setCompleted] = useState(0)
    const [inProgress, setInProgress] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        const token = localStorage.getItem('ssToken')
        if (
            (!authenticated.authenticated || authenticated.type !== 'A') &&
            !token
        ) {
            navigate('/')
        } else {
            if (token) {
                decodeToken(token).then(response => {
                    if (response.decoded_token) {
                        const temp = response.decoded_token
                        const loginTime = temp.iat * 1000
                        const expireTime = loginTime + 60 * 60 * 1000
                        if (Date.now() < expireTime) {
                            // not expired
                            updateInfo({
                                user_principal_name: temp.name,
                                token: token,
                                perms: temp.roles
                            })
                            setup()
                        } else {
                            // expired
                            localStorage.removeItem('ssToken')
                            dispatch(updateAuthentication({}))
                            dispatch(updateAdmin({}))
                            navigate('/admin')
                        }
                    } else {
                        localStorage.removeItem('ssToken')
                        navigate('/')
                    }
                })
            } else {
                setup()
            }
        }
    }, [])
    const setup = () => {
        getStats().then(response => {
            // console.log(response)
            setUpdated(response.updated_count[0])
            setCompleted(response.completed_count[0])
            setInProgress(response.in_progress_count[0])
            setLoading(false)
        })
    }
    const updateInfo = data => {
        dispatch(
            updateAuthentication({
                authenticated: true,
                type: 'A'
            })
        )
        dispatch(
            updateAdmin({
                user_id: data?.user_principal_name,
                token: data?.token,
                isSupervisor: data?.perms?.includes('supervisor')
            })
        )
    }
    const Stats = () => {
        const childComponent = loading ? (
            <Spinner animation='border' role='status' className='mt-3' />
        ) : (
            <div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Total Updates Received</th>
                            <th>Total Completed Updates</th>
                            <th className='last-col'>
                                Total In-Progress Updates
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{updated}</td>
                            <td>{completed}</td>
                            <td>{inProgress}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className='button-container'>
                    <Button
                        className='button-margin'
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        Back
                    </Button>
                </div>
            </div>
        )
        return <AdminContainer childComponent={childComponent} />
    }
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label='School Support Admin'
            >
                School Support Admin
            </h1>
            <div role='region' aria-label='Stats'>
                <Stats />
            </div>
        </Container>
    )
}

export default Stats
