import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateAdmin } from '../Reducers/AdminUser'
import { updateAuthentication } from '../Reducers/Authentication'
import { updateDownload } from '../Reducers/Download'

function AdminContainer({ childComponent }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <div id='home-container'>
            <div id='menu-container'>
                <div
                    className='cursor-pointer'
                    onClick={() => navigate('/view-updates')}
                >
                    View Updates
                </div>
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        dispatch(
                            updateDownload({ type: 'updates', values: [] })
                        )
                        navigate('/download')
                    }}
                >
                    Download Updates
                </div>
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        dispatch(
                            updateDownload({ type: 'completed', values: [] })
                        )
                        navigate('/download')
                    }}
                >
                    Download Completed
                </div>
                <div
                    className='cursor-pointer'
                    onClick={() => navigate('/stats')}
                >
                    Stats
                </div>
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        localStorage.removeItem('ssToken')
                        dispatch(updateAuthentication({}))
                        dispatch(updateAdmin({}))
                        navigate('/admin')
                    }}
                >
                    Logout
                </div>
            </div>
            <div id='admin-desc'>{childComponent}</div>
        </div>
    )
}

export default AdminContainer
