import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Spinner,
    InputGroup
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import TableTooltip from '../Custom/TableTooltip'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateFormInput } from '../../Reducers/FormInput'
import { updateUserData } from '../../Reducers/UserData'
import { getSchoolBoards, resendEmail } from '../APIService/APIService'
import { sortSchoolSupportArray } from '../../Common/Functions'
import CustomModal from '../Custom/CustomModal'
import './SchoolSupport.css'

function SchoolSupport({ lang }) {
    const { t } = useTranslation(['schoolSupport', 'form', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [authentication, setAuthentication] = useState(
        useSelector(state => state.authenticationReducer.value)
    )
    const [userData, setUserData] = useState(
        useSelector(state => state.userDataReducer.value)
    )
    const [formInput, setFormInput] = useState(
        useSelector(state => state.formInputReducer.value)
    )
    const [ssOptions, setSsOptions] = useState([])
    const [emailAddress, setEmailAddress] = useState('')
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        if (!authentication || !authentication.authenticated) {
            navigate('/search')
        } else {
            const rollNum = userData?.roll_num
            if (rollNum) {
                const ctyMun = rollNum.substring(0, 4)
                if (ctyMun) {
                    getSchoolBoards({ cty_mun: ctyMun }).then(response => {
                        let arr = []
                        for (let i in response) {
                            //   if (response[i]?.length) {
                            if (!arr.includes(response[i][0])) {
                                if (response[i][0] == 'FP') {
                                    arr.push('A')
                                } else if (response[i][0] == 'FS') {
                                    arr.push('C')
                                } else if (response[i][0] == 'PS') {
                                    arr.push('D')
                                } else {
                                    arr.push(response[i][0])
                                }
                                //   }
                            }
                        }
                        setSsOptions(sortSchoolSupportArray(arr))
                    })
                }
            }
        }
    }, [])
    const handleChange = e => {
        let name = e.target.name
        let value = e.target.value
        let temp_flg = formInput.french_language_rights_flg
        if (
            (name === 'french_one' ||
                name === 'french_two' ||
                name === 'french_three') &&
            value === 'Y'
        ) {
            temp_flg = 'Y'
        }
        if (name === 'french_one' && value === 'N') {
            if (
                formInput.french_two === 'N' &&
                formInput.french_three === 'N'
            ) {
                temp_flg = 'N'
            }
        }
        if (name === 'french_two' && value === 'N') {
            if (
                formInput.french_one === 'N' &&
                formInput.french_three === 'N'
            ) {
                temp_flg = 'N'
            }
        }
        if (name === 'french_three' && value === 'N') {
            if (formInput.french_one === 'N' && formInput.french_two === 'N') {
                temp_flg = 'N'
            }
        }
        setFormInput({
            ...formInput,
            [name]: value,
            french_language_rights_flg: temp_flg
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        let temp = { ...formInput }
        if (formInput.check === 'Y') {
            temp = {
                citizenship_cd: userData?.citizenship_cd,
                religion_cd: userData?.religion_cd,
                french_language_rights_flg:
                    userData?.french_language_rights_flg,
                french_one: null,
                french_two: null,
                french_three: null,
                school_support_cd: userData?.school_support_cd
            }
        }
        dispatch(updateFormInput(temp))
        if (JSON.stringify(temp) !== JSON.stringify(userData)) {
            temp = { ...userData, updated: true }
            dispatch(updateUserData(temp))
        }
        navigate('/confirm')
    }
    const handleEmailAddress = val => {
        setEmailAddress(val)
    }
    const checkValidEmail = emailAddress => {
        if (!emailAddress) {
            return false
        } else if (
            !emailAddress.match(
                /^[a-zA-Z0-9_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            )
        ) {
            return false
        }
        return true
    }
    const resendConfirmationEmail = () => {
        let result = checkValidEmail(emailAddress)
        if (result) {
            resendEmail(userData?.roll_num, emailAddress, lang).then(
                response => {
                    setShowModal(true)
                    return
                }
            )
        }
    }
    return (
        <Container className='inner-component' role='main'>
            {userData ? (
                <>
                    <h1 role='heading' aria-label={t('title')}>
                        {t('title')}
                    </h1>
                    <div
                        role='region'
                        aria-label={
                            userData.first_name?.toUpperCase() +
                            ' ' +
                            userData.last_name?.toUpperCase() +
                            t('para-one') +
                            (userData.school_support_cd
                                ? t(
                                      'ssc-options.' +
                                          userData.school_support_cd,
                                      {
                                          ns: 'form'
                                      }
                                  ).toUpperCase()
                                : t('ssc-options.P', {
                                      ns: 'form'
                                  }).toUpperCase())
                        }
                    >
                        {userData.first_name?.toUpperCase() +
                            ' ' +
                            userData.last_name?.toUpperCase()}
                        {t('para-one')} '
                        {userData.school_support_cd
                            ? t('ssc-options.' + userData.school_support_cd, {
                                  ns: 'form'
                              }).toUpperCase()
                            : t('ssc-options.P', {
                                  ns: 'form'
                              }).toUpperCase()}
                        '.
                    </div>
                    <Form
                        id='user-form'
                        className='m-3'
                        onSubmit={e => handleSubmit(e)}
                    >
                        <Row role='radiogroup' aria-label={t('option-one')}>
                            <Col md={4} className='blue'>
                                {t('option-one')}
                            </Col>
                            <Col>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name='check'
                                    id='check_yes'
                                    value='Y'
                                    checked={formInput.check === 'Y'}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor='check_yes'
                                >
                                    {t('options.yes', { ns: 'common' })}
                                </label>
                            </Col>
                            <Col>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name='check'
                                    id='check_no'
                                    value='N'
                                    checked={formInput.check === 'N'}
                                    onChange={e => handleChange(e)}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor='check_no'
                                >
                                    {t('options.no', { ns: 'common' })}
                                </label>
                            </Col>
                            <Col md={6} />
                        </Row>
                        {formInput.check === 'Y' && (
                            <>
                                <Col className='mt-3 resend-email-heading'>
                                    {t('resend-email', { ns: 'form' })}
                                </Col>
                                <Form className='resend-email-container'>
                                    <div className='resend-email-input'>
                                        <InputGroup>
                                            <Form.Control
                                                id='email-address'
                                                type='email'
                                                name='email_address'
                                                value={emailAddress}
                                                onChange={e =>
                                                    handleEmailAddress(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                        </InputGroup>
                                    </div>
                                    <Button
                                        onClick={() => {
                                            resendConfirmationEmail()
                                        }}
                                        disabled={showModal}
                                    >
                                        Send
                                    </Button>
                                </Form>
                            </>
                        )}
                        {formInput.check === 'N' && (
                            <div>
                                <Row className='mt-3'>{t('para-two')}</Row>
                                <Row
                                    className='mt-3'
                                    role='radiogroup'
                                    aria-label={t('option-two')}
                                >
                                    <Col md={4} className='blue'>
                                        {t('option-two')}{' '}
                                        <TableTooltip column='citizenship_cd' />
                                    </Col>
                                    <Col>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='citizenship_cd'
                                            id='citizenship_cd_y'
                                            value='C'
                                            checked={
                                                formInput.citizenship_cd === 'C'
                                            }
                                            onChange={e => handleChange(e)}
                                            required={formInput.check === 'N'}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='citizenship_cd_y'
                                        >
                                            {t('options.yes', {
                                                ns: 'common'
                                            })}
                                        </label>
                                    </Col>
                                    <Col>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='citizenship_cd'
                                            id='citizenship_cd_n'
                                            value='A'
                                            checked={
                                                formInput.citizenship_cd === 'A'
                                            }
                                            onChange={e => handleChange(e)}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='citizenship_cd_n'
                                        >
                                            {t('options.no', {
                                                ns: 'common'
                                            })}
                                        </label>
                                    </Col>
                                    <Col md={6} />
                                </Row>
                                <Row
                                    className='mt-3'
                                    role='radiogroup'
                                    aria-label={t('option-three')}
                                >
                                    <Col md={4} className='blue'>
                                        {t('option-three')}{' '}
                                        <TableTooltip column='religion_cd' />
                                    </Col>
                                    <Col>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='religion_cd'
                                            id='religion_cd_y'
                                            value='R'
                                            checked={
                                                formInput.religion_cd === 'R'
                                            }
                                            onChange={e => handleChange(e)}
                                            required={formInput.check === 'N'}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='religion_cd_y'
                                        >
                                            {t('options.yes', {
                                                ns: 'common'
                                            })}
                                        </label>
                                    </Col>
                                    <Col>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='religion_cd'
                                            id='religion_cd_n'
                                            value='N'
                                            checked={
                                                formInput.religion_cd === 'N'
                                            }
                                            onChange={e => handleChange(e)}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='religion_cd_n'
                                        >
                                            {t('options.no', {
                                                ns: 'common'
                                            })}
                                        </label>
                                    </Col>
                                    <Col md={6} />
                                </Row>
                                <Row
                                    className='mt-3'
                                    role='radiogroup'
                                    aria-label={t('option-four')}
                                >
                                    <Col md={4} className='blue'>
                                        {t('option-four')}
                                    </Col>
                                    <Col>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='french_one'
                                            id='french_one_y'
                                            value='Y'
                                            checked={
                                                formInput.french_one === 'Y'
                                            }
                                            onChange={e => handleChange(e)}
                                            required={formInput.check === 'N'}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='french_one_y'
                                        >
                                            {t('options.yes', {
                                                ns: 'common'
                                            })}
                                        </label>
                                    </Col>
                                    <Col>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='french_one'
                                            id='french_one_n'
                                            value='N'
                                            checked={
                                                formInput.french_one === 'N'
                                            }
                                            onChange={e => handleChange(e)}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='french_one_n'
                                        >
                                            {t('options.no', {
                                                ns: 'common'
                                            })}
                                        </label>
                                    </Col>
                                    <Col md={6} />
                                </Row>
                                {formInput.french_one === 'N' && (
                                    <Row
                                        className='mt-3'
                                        role='radiogroup'
                                        aria-label={t('option-five')}
                                    >
                                        <Col md={4} className='blue'>
                                            {t('option-five')}
                                        </Col>
                                        <Col>
                                            <input
                                                className='form-check-input'
                                                type='radio'
                                                name='french_two'
                                                id='french_two_y'
                                                value='Y'
                                                checked={
                                                    formInput.french_two === 'Y'
                                                }
                                                onChange={e => handleChange(e)}
                                                required={
                                                    formInput.check === 'N' &&
                                                    formInput.french_one === 'N'
                                                }
                                            />
                                            <label
                                                className='form-check-label'
                                                htmlFor='french_two_y'
                                            >
                                                {t('options.yes', {
                                                    ns: 'common'
                                                })}
                                            </label>
                                        </Col>
                                        <Col>
                                            <input
                                                className='form-check-input'
                                                type='radio'
                                                name='french_two'
                                                id='french_two_n'
                                                value='N'
                                                checked={
                                                    formInput.french_two === 'N'
                                                }
                                                onChange={e => handleChange(e)}
                                            />
                                            <label
                                                className='form-check-label'
                                                htmlFor='french_two_n'
                                            >
                                                {t('options.no', {
                                                    ns: 'common'
                                                })}
                                            </label>
                                        </Col>
                                        <Col md={6} />
                                    </Row>
                                )}
                                {formInput.french_one === 'N' &&
                                    formInput.french_two === 'N' && (
                                        <Row
                                            className='mt-3'
                                            role='radiogroup'
                                            aria-label={t('option-six')}
                                        >
                                            <Col md={4} className='blue'>
                                                {t('option-six')}
                                            </Col>
                                            <Col>
                                                <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='french_three'
                                                    id='french_three_y'
                                                    value='Y'
                                                    checked={
                                                        formInput.french_three ===
                                                        'Y'
                                                    }
                                                    onChange={e =>
                                                        handleChange(e)
                                                    }
                                                    required={
                                                        formInput.check ===
                                                            'N' &&
                                                        formInput.french_one ===
                                                            'N' &&
                                                        formInput.french_two ===
                                                            'N'
                                                    }
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor='french_three_y'
                                                >
                                                    {t('options.yes', {
                                                        ns: 'common'
                                                    })}
                                                </label>
                                            </Col>
                                            <Col>
                                                <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='french_three'
                                                    id='french_three_n'
                                                    value='N'
                                                    checked={
                                                        formInput.french_three ===
                                                        'N'
                                                    }
                                                    onChange={e =>
                                                        handleChange(e)
                                                    }
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor='french_three_n'
                                                >
                                                    {t('options.no', {
                                                        ns: 'common'
                                                    })}
                                                </label>
                                            </Col>
                                            <Col md={6} />
                                        </Row>
                                    )}
                                <Row className='mt-3'>{t('para-three')}</Row>
                                <Row
                                    className='mt-3'
                                    role='radiogroup'
                                    aria-label={t('para-three')}
                                >
                                    {ssOptions.map((o, idx) => {
                                        const value = o?.toString()
                                        if (
                                            value === 'S' &&
                                            formInput.religion_cd !== 'R'
                                        ) {
                                            return
                                        } else if (
                                            value === 'A' &&
                                            ((formInput.french_one !== 'Y' &&
                                                formInput.french_two !== 'Y' &&
                                                formInput.french_three !==
                                                    'Y') ||
                                                formInput.citizenship_cd !==
                                                    'C')
                                        ) {
                                            return
                                        } else if (
                                            value === 'C' &&
                                            ((formInput.french_one !== 'Y' &&
                                                formInput.french_two !== 'Y' &&
                                                formInput.french_three !==
                                                    'Y') ||
                                                formInput.citizenship_cd !==
                                                    'C' ||
                                                formInput.religion_cd !== 'R')
                                        ) {
                                            return
                                        } else if (
                                            value === 'D' &&
                                            (formInput.religion_cd === 'R' ||
                                                (userData.region_cd !== '16' &&
                                                    userData.roll_num?.substring(
                                                        0,
                                                        4
                                                    ) !== '4372'))
                                        ) {
                                            return
                                        } else {
                                            return (
                                                <Col>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        name='school_support_cd'
                                                        id={
                                                            'school_support_cd_' +
                                                            value.toLowerCase()
                                                        }
                                                        value={value}
                                                        checked={
                                                            formInput.school_support_cd ===
                                                            value
                                                        }
                                                        onChange={e =>
                                                            handleChange(e)
                                                        }
                                                        required
                                                    />
                                                    <label
                                                        className='form-check-label'
                                                        htmlFor={
                                                            'school_support_cd_' +
                                                            value.toLowerCase()
                                                        }
                                                    >
                                                        {t(
                                                            'ssc-options.' +
                                                                value,
                                                            {
                                                                ns: 'form'
                                                            }
                                                        )}
                                                    </label>
                                                </Col>
                                            )
                                        }
                                    })}
                                </Row>
                            </div>
                        )}
                        <div className='mt-3 school-support-info'>
                            {t('disclaimer')}
                        </div>
                        <br />
                        <div className='button-container'>
                            <Button
                                className='button-margin'
                                onClick={() => {
                                    dispatch(updateAuthentication({}))
                                    navigate('/search')
                                }}
                            >
                                {t('buttons.cancel', { ns: 'common' })}
                            </Button>
                            <Button className='button-margin' type='submit'>
                                {t('buttons.next', { ns: 'common' })}
                            </Button>
                        </div>
                    </Form>
                </>
            ) : (
                <>
                    {' '}
                    <Spinner
                        animation='border'
                        role='status'
                        className='mt-3'
                    />
                </>
            )}
            <CustomModal
                show={showModal}
                close={() => setShowModal(false)}
                dialog='modal-wide'
                title={t('resend-email-header', { ns: 'form' })}
                body={<div>{t('resend-email-body', { ns: 'form' })}</div>}
                footer={
                    <Button onClick={() => setShowModal(false)}>Close</Button>
                }
                showCancel={false}
            />
        </Container>
    )
}

export default SchoolSupport
