import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function CustomModal({
    show,
    close,
    dialog,
    title,
    body,
    bodyText,
    footer,
    footerText,
    showCancel = true
}) {
    const { t } = useTranslation(['common'])
    return (
        <div id='popup'>
            <Modal
                show={show}
                onHide={close}
                dialogClassName={dialog ? dialog : ''}
                aria-labelledby='popup'
            >
                <Modal.Header closeButton>
                    <Modal.Title role='region' aria-label={title}>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body role='region' aria-label={bodyText}>
                    {body}
                </Modal.Body>
                {footer && (
                    <Modal.Footer
                        role='region'
                        aria-label={
                            t('buttons.cancel', { ns: 'common' }) +
                            '/' +
                            footerText
                        }
                    >
                        {showCancel ? (
                            <Button variant='secondary' onClick={close}>
                                {t('buttons.cancel', { ns: 'common' })}
                            </Button>
                        ) : (
                            <></>
                        )}
                        {footer}
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    )
}

export default CustomModal