import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Button, Form, Alert } from 'react-bootstrap'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateAdmin } from '../../Reducers/AdminUser'
import { decodeToken, login } from '../APIService/APIService'
import AdminContainer from '../../Common/AdminContainer'

import './Admin.css'

function Admin() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userData, setUserData] = useState({
        username: '',
        password: ''
    })
    const [loading, setLoading] = useState(true)
    const authenticated = useSelector(
        state => state.authenticationReducer.value
    )
    const token = localStorage.getItem('ssToken')
    const [invalidLogin, setInvalidLogin] = useState(false)
    useEffect(() => {
        if (token) {
            decodeToken(token).then(response => {
                if (response.decoded_token) {
                    const temp = response.decoded_token
                    const loginTime = temp.iat * 1000
                    const expireTime = loginTime + 60 * 60 * 1000
                    if (Date.now() < expireTime) {
                        // not expired
                        updateInfo({
                            user_principal_name: temp.name,
                            token: token,
                            perms: temp.roles
                        })
                        setLoading(false)
                    } else {
                        // expired
                        localStorage.removeItem('ssToken')
                        dispatch(updateAuthentication({}))
                        dispatch(updateAdmin({}))
                        navigate('/admin')
                    }
                } else {
                    localStorage.removeItem('ssToken')
                    navigate('/')
                }
            })
        } else {
            setLoading(false)
        }
    }, [])
    const updateInfo = data => {
        dispatch(
            updateAuthentication({
                authenticated: true,
                type: 'A'
            })
        )
        dispatch(
            updateAdmin({
                user_id: data?.user_principal_name,
                token: data?.token,
                isSupervisor: data?.perms?.includes('supervisor')
            })
        )
    }
    const handleChange = e => {
        setUserData(userData => {
            return { ...userData, [e.target.name]: e.target.value }
        })
    }
    const HomePage = () => {
        const childComponent = (
            <>
                Welcome to the School Support Administrator console. Please
                select one of the available options from the menu located on the
                left.
            </>
        )
        return <AdminContainer childComponent={childComponent} />
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (!userData.username || !userData.password) {
            setInvalidLogin(true)
        } else {
            login(userData).then(response => {
                if (response.statusCode === 200) {
                    const body = JSON.parse(response.body)
                    setUserData({ username: '', password: '' })
                    setInvalidLogin(false)
                    localStorage.setItem('ssToken', body.token)
                    updateInfo(body)
                } else {
                    setInvalidLogin(true)
                }
            })
        }
    }
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label='School Support Admin'
            >
                School Support Admin
            </h1>
            <div role='region' aria-label='School Support Admin Login'>
                {!authenticated?.authenticated && (
                    <Form id='login-form' onSubmit={e => handleSubmit(e)}>
                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Username:</Form.Label>
                            <Form.Control
                                type='text'
                                name='username'
                                value={userData.username}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Form.Group>
                        <Form.Group
                            className='mb-3'
                            controlId='formBasicPassword'
                        >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type='password'
                                name='password'
                                value={userData.password}
                                onChange={e => handleChange(e)}
                                autoComplete='off'
                                required
                            />
                        </Form.Group>
                        <div className='button-container'>
                            <Button variant='primary' type='submit'>
                                Submit
                            </Button>
                        </div>
                    </Form>
                )}
                {authenticated.authenticated && authenticated.type === 'A' && (
                    <HomePage />
                )}
                {!authenticated.authenticated && invalidLogin && (
                    <Alert variant='danger' className='mt-3' id='invalid-login'>
                        Invalid username and/or password.
                    </Alert>
                )}
            </div>
        </Container>
    )
}

export default Admin
