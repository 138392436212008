import { createSlice } from '@reduxjs/toolkit'

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    value: ''
  },
  reducers: {
    updateAuthentication: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateAuthentication } = authenticationSlice.actions

export default authenticationSlice.reducer
