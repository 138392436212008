export const userObject = {
    first_name: '',
    middle_name: '',
    last_name: '',
    consolidated_name: '',
    birth_dte: '',
    citizenship_cd: '',
    religion_cd: '',
    french_language_rights_flg: '',
    occupancy_cd: '',
    residency_cd: '',
    school_support_cd: '',
    ips_property_id: '',
    municipality: '',
    street_number: '',
    street_name: '',
    street_type_cd: '',
    street_direction_cd: '',
    unit_identifier: '',
    roll_num: '',
    email_address: '',
    id: '',
    area_occupied: ''
}
