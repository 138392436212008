import { useNavigate } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import './FAQ.css'

function FAQ() {
    const { t } = useTranslation(['faq', 'common'])
    const navigate = useNavigate()
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label={t('title', { ns: 'faq' })}
            >
                {t('title', { ns: 'faq' })}
            </h1>
            <div
                className='mt-3 mb-3'
                role='region'
                aria-label={t('title', { ns: 'faq' })}
            >
                <h2 className='subtitle' role='heading'>
                    {t('changing.title', { ns: 'faq' })}
                </h2>
                {t('changing.para-one', { ns: 'faq' })}
                <br />
                <br />
                {t('changing.para-two', { ns: 'faq' })}
                <h2 className='mt-3 subtitle' role='heading'>
                    {t('purpose.title', { ns: 'faq' })}
                </h2>
                {t('purpose.para-one', { ns: 'faq' })}
                <br />
                <br />
                <ul>
                    <li>{t('purpose.bp-one', { ns: 'faq' })}</li>
                    <li>{t('purpose.bp-two', { ns: 'faq' })}</li>
                    <li>{t('purpose.bp-three', { ns: 'faq' })}</li>
                    <li>{t('purpose.bp-four', { ns: 'faq' })}</li>
                    <li>{t('purpose.bp-five', { ns: 'faq' })}</li>
                </ul>
                <h2 className='subtitle' role='heading'>
                    {t('who.title', { ns: 'faq' })}
                </h2>
                {t('who.para-one', { ns: 'faq' })}
                <br />
                <br />
                <h2 className='subtitle' role='heading'>
                    {t('criteria.title', { ns: 'faq' })}
                </h2>
                {t('criteria.para-one', { ns: 'faq' })}
                <br />
                <br />
                <ul>
                    <li>{t('criteria.bp-one', { ns: 'faq' })}</li>
                    <li>{t('criteria.bp-two', { ns: 'faq' })}</li>
                    <li>{t('criteria.bp-three', { ns: 'faq' })}</li>
                    <li>{t('criteria.bp-four', { ns: 'faq' })}</li>
                </ul>
                {t('criteria.para-two', { ns: 'faq' })}
                <br />
                <br />
                <ul id='fr-list'>
                    <li>{t('criteria.bp-five', { ns: 'faq' })}</li>
                    <li>{t('criteria.bp-six', { ns: 'faq' })}</li>
                    <li>{t('criteria.bp-seven', { ns: 'faq' })}</li>
                    <li>{t('criteria.bp-eight', { ns: 'faq' })}</li>
                </ul>
                {t('criteria.para-three', { ns: 'faq' })}
                <h2 className='mt-3 subtitle' role='heading'>
                    {t('benefits.title', { ns: 'faq' })}
                </h2>
                {t('benefits.para-one', { ns: 'faq' })}
            </div>
            <div
                className='button-container mt-3'
                role='region'
                aria-label={t('buttons.return', { ns: 'common' })}
            >
                <Button onClick={() => navigate(-1)}>
                    {t('buttons.return', { ns: 'common' })}
                </Button>
            </div>
        </Container>
    )
}

export default FAQ
