import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Container,
    Button,
    Form,
    Row,
    Col,
    Table,
    Alert
} from 'react-bootstrap'
import { updateAdmin } from '../../Reducers/AdminUser'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateDownload } from '../../Reducers/Download'
import { decodeToken, getDownloads, getCSV } from '../APIService/APIService'
import { order, fields } from '../../Common/AdminValues'
import { formatName } from '../../Common/Functions'
import ResponsivePagination from 'react-responsive-pagination'

import './Download.css'

function Download() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authenticated = useSelector(
        state => state.authenticationReducer.value
    )
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [showValues, setShowValues] = useState(false)
    const download = useSelector(state => state.downloadReducer.value)
    const values = download.values
    const [filteredValues, setFilteredValues] = useState(values)
    const [currentValues, setCurrentValues] = useState(filteredValues)
    const [searchField, setSearchField] = useState('roll_num')
    const [searchValue, setSearchValue] = useState('')
    const [showNoResults, setShowNoResults] = useState(false)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const [numPages, setNumPages] = useState(0)
    useEffect(() => {
        const token = localStorage.getItem('ssToken')
        if (
            (!authenticated.authenticated || authenticated.type !== 'A') &&
            !token
        ) {
            navigate('/')
        } else {
            if (token) {
                decodeToken(token).then(response => {
                    if (response.decoded_token) {
                        const temp = response.decoded_token
                        const loginTime = temp.iat * 1000
                        const expireTime = loginTime + 60 * 60 * 1000
                        if (Date.now() < expireTime) {
                            // not expired
                            updateInfo({
                                user_principal_name: temp.name,
                                token: token,
                                perms: temp.roles
                            })
                            setLoading(false)
                        } else {
                            // expired
                            localStorage.removeItem('ssToken')
                            dispatch(updateAuthentication({}))
                            dispatch(updateAdmin({}))
                            navigate('/admin')
                        }
                    } else {
                        localStorage.removeItem('ssToken')
                        navigate('/')
                    }
                })
            } else {
                setLoading(false)
            }
        }
    }, [])
    useEffect(() => {
        let temp = filteredValues
        let tempNum = Math.ceil(temp.length / 5)
        setNumPages(tempNum)
        // setCurrentValues(
        //     temp.length < 5
        //         ? temp.slice((page - 1) * 5, (page - 1) * 5 + temp.length)
        //         : temp.slice((page - 1) * 5, page * 5)
        // )
        let tempPages = []
        for (let i = 0; i < tempNum; i++) {
            tempPages.push(i + 1)
        }
        setPages(tempPages)
        if (page > tempNum && page > 0) {
            setPage(tempNum)
        }
    }, [filteredValues])
    useEffect(() => {
        let temp = filteredValues
        let tempArray = temp.slice((page - 1) * 5, page * 5)
        setCurrentValues(tempArray)
    }, [page, filteredValues])
    const updateInfo = data => {
        dispatch(
            updateAuthentication({
                authenticated: true,
                type: 'A'
            })
        )
        dispatch(
            updateAdmin({
                user_id: data?.user_principal_name,
                token: data?.token,
                isSupervisor: data?.perms?.includes('supervisor')
            })
        )
    }
    const handleChange = (e, type) => {
        if (type === 'start') {
            setStartDate(e.target.value)
        } else {
            setEndDate(e.target.value)
        }
    }
    const handleSelectChange = e => {
        setSearchField(e.target.value)
    }
    const handleSearchChange = e => {
        setSearchValue(e.target.value)
        let temp = values
        if (e.target.value) {
            temp = temp.filter(el => {
                return el[searchField]?.includes(e.target.value?.toUpperCase())
            })
        }
        setFilteredValues(temp)
    }
    const handleSubmit = e => {
        e.preventDefault()
        setShowNoResults(false)
        let temp = new Date(endDate)
        temp.setDate(temp.getDate() + 1)
        temp = temp.toISOString().split('T')[0]
        getDownloads(startDate, temp, download.type).then(response => {
            if (response.length) {
                let temp = []
                response.forEach((e, i) => {
                    let obj = JSON.parse(JSON.stringify(JSON.parse(e), order))
                    if (obj.sub_roll_num) {
                        obj.roll_num = obj.sub_roll_num
                    } else {
                        obj.roll_num = obj.roll_num + '0000'
                    }
                    delete obj.sub_roll_num
                    temp = [...temp, obj]
                })
                dispatch(updateDownload({ ...download, values: temp }))
                setFilteredValues(temp)
                setShowValues(true)
            } else {
                dispatch(updateDownload({ ...download, values: [] }))
                setFilteredValues([])
                setShowNoResults(true)
            }
        })
    }
    function convertToCSV(arr) {
        if (arr[0]) {
            const array = [Object.keys(arr[0])].concat(arr)

            return array
                .map(e => {
                    return Object.values(e).toString()
                })
                .join('\n')
        } else {
            return false
        }
    }
    const downloadFile = response => {
        // console.log(response)
        const url = window.URL.createObjectURL(
            new Blob([response], { type: 'application/csv' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'School_Support_Admin.csv')
        document.body.appendChild(link)
        link.click()
    }
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label='School Support Admin'
            >
                School Support Admin
            </h1>
            <div role='region' aria-label='Menu'>
                <div id='home-container'>
                    <div id='menu-container'>
                        <div
                            className='cursor-pointer'
                            onClick={() => navigate('/view-updates')}
                        >
                            View Updates
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={() => {
                                dispatch(
                                    updateDownload({
                                        type: 'updates',
                                        values: []
                                    })
                                )
                                setStartDate('')
                                setEndDate('')
                                setShowValues(false)
                                navigate('/download')
                            }}
                        >
                            Download Updates
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={() => {
                                dispatch(
                                    updateDownload({
                                        type: 'completed',
                                        values: []
                                    })
                                )
                                setStartDate('')
                                setEndDate('')
                                setShowValues(false)
                                navigate('/download')
                            }}
                        >
                            Download Completed
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={() => navigate('/stats')}
                        >
                            Stats
                        </div>
                        <div
                            className='cursor-pointer'
                            onClick={() => {
                                localStorage.removeItem('ssToken')
                                dispatch(updateAuthentication({}))
                                dispatch(updateAdmin({}))
                                navigate('/admin')
                            }}
                        >
                            Logout
                        </div>
                    </div>
                    <div id='admin-desc'>
                        {' '}
                        <div>
                            <Form onSubmit={e => handleSubmit(e)}>
                                <Row>
                                    <Col>
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type='date'
                                                value={startDate}
                                                onChange={e =>
                                                    handleChange(e, 'start')
                                                }
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className='mb-3'>
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type='date'
                                                value={endDate}
                                                onChange={e =>
                                                    handleChange(e, 'end')
                                                }
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {showNoResults && (
                                    <Alert variant='danger' className='mt-3'>
                                        No results found.
                                    </Alert>
                                )}
                                <div className='button-container'>
                                    <Button
                                        onClick={() => {
                                            let temp = '2123-12-31'
                                            if (endDate) {
                                                temp = new Date(endDate)
                                                temp.setDate(temp.getDate() + 1)
                                                temp = temp
                                                    .toISOString()
                                                    .split('T')[0]
                                            }
                                            setShowNoResults(false)
                                            getCSV(
                                                startDate || '0000-00-00',
                                                temp,
                                                download.type
                                            ).then(response => {
                                                if (response) {
                                                    // console.log(response)
                                                    let tempResponse =
                                                        convertToCSV(response)
                                                    if (tempResponse) {
                                                        downloadFile(
                                                            tempResponse
                                                        )
                                                    } else {
                                                        setShowNoResults(true)
                                                    }
                                                }
                                            })
                                        }}
                                    >
                                        Download{' '}
                                        {download.type === 'updates'
                                            ? 'Updates'
                                            : 'Completed'}
                                    </Button>
                                    <Button
                                        className='button-margin'
                                        type='submit'
                                    >
                                        Search{' '}
                                        {download.type === 'updates'
                                            ? 'Updates'
                                            : 'Completed'}
                                    </Button>
                                    <Button
                                        className='button-margin'
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            {showValues && (
                <div className='mt-3'>
                    <Form id='updates-table'>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Select
                                        id='search_field'
                                        name='search_field'
                                        value={searchField}
                                        onChange={e => handleSelectChange(e)}
                                        required
                                    >
                                        {fields.map((e, i) => {
                                            return (
                                                e.value !== 'complete' &&
                                                e.value !== 'in_progress' && (
                                                    <option
                                                        key={i}
                                                        value={e.value}
                                                    >
                                                        {e.text}
                                                    </option>
                                                )
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Control
                                        type='text'
                                        name='search'
                                        value={searchValue}
                                        onChange={e => {
                                            handleSearchChange(e)
                                        }}
                                        placeholder='Search'
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    <Table striped>
                        <thead>
                            <tr>
                                {fields.map((e, i) => {
                                    if (
                                        e.value !== 'complete' &&
                                        e.value !== 'in_progress'
                                    ) {
                                        return (
                                            <th
                                                className={
                                                    e.value ===
                                                    'property_school_support_cd'
                                                        ? 'float-right'
                                                        : ''
                                                }
                                            >
                                                {e.text}
                                            </th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {currentValues &&
                                currentValues.map((e, i) => {
                                    return (
                                        <tr>
                                            {Object.entries(e).map(
                                                ([key, value]) => {
                                                    // console.log(e)
                                                    if (
                                                        key !== 'transaction_id'
                                                    ) {
                                                        return (
                                                            <td>
                                                                {formatName(
                                                                    value
                                                                )}
                                                            </td>
                                                        )
                                                    }
                                                }
                                            )}
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                </div>
            )}
            {pages.length > 1 && (
                <ResponsivePagination
                    current={page}
                    total={numPages - 1}
                    onPageChange={e => {
                        setPage(e)
                    }}
                />
            )}
        </Container>
    )
}

export default Download
