export const formInputObject = {
    check: null,
    update: null,
    citizenship_cd: null,
    religion_cd: null,
    french_one: null,
    french_two: null,
    french_three: null,
    french_language_rights_flg: null,
    school_support_cd: 'P'
}
