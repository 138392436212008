import { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import CustomTooltip from '../Custom/CustomTooltip'
import RequiredField from '../Custom/RequiredField'
import { userObject } from '../Templates/UserObject'
import './Add.css'

function Add(props) {
    const { t } = useTranslation(['add, form, common'])
    let temp = userObject
    const contactUrl =
        props.lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    const [address, setAddress] = useState(null)
    const [userData, setUserData] = useState(null)
    const ssOptions = props.ssOptions
    useEffect(() => {
        temp = {
            ...temp,
            citizenship_cd: 'C',
            occupancy_cd: 'S',
            residency_cd: 'U',
            school_support_cd: 'P',
            french_language_rights_flg: 'N',
            religion_cd: 'N',
            roll_num: props.address?.roll_num,
            region_cd: props.address?.region_cd,
            ips_property_id: props.address?.ips_property_id,
            i: props.residents?.length,
            area_occupied: props.areaOccupied,
            new: true
        }
        setUserData(temp)
    }, [])
    useEffect(() => {
        setAddress(props.address)
    }, [props.address, props.areaOccupied])
    const handleChange = e => {
        setUserData(userData => {
            return { ...userData, [e.target.name]: e.target.value }
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        let tempResidents = [...props.residents, userData]
        props.updateResidents(tempResidents)
        setUserData(temp)
        props.setShowForm(false)
    }
    return (
        <>
            {userData && address && (
                <div className='mt-3'>
                    <h2
                        className='subtitle'
                        role='heading'
                        aria-label={t('title', { ns: 'add' })}
                    >
                        {t('title', { ns: 'add' })}{' '}
                        <CustomTooltip
                            placement='top'
                            delay={1200}
                            width='500px'
                            content={
                                <>
                                    {t('disclaimer-one', { ns: 'add' })}
                                    <a
                                        href={contactUrl}
                                        target='_blank'
                                        rel='noreferrer'
                                        className='blue cursor-pointer'
                                    >
                                        {t('add-link', { ns: 'add' })}
                                    </a>
                                    .
                                    <br />
                                    <br />
                                    {t('disclaimer-two', { ns: 'add' })}
                                </>
                            }
                            display={
                                <span>
                                    <FaQuestionCircle />
                                </span>
                            }
                        />
                    </h2>
                    <Form onSubmit={e => handleSubmit(e)} className='mt-3 mb-3'>
                        <Row>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='first_name'>
                                        {t('first-name', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Form.Control
                                        id='first_name'
                                        type='text'
                                        name='first_name'
                                        value={userData.first_name}
                                        onChange={e => handleChange(e)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='last_name'>
                                        {t('last-name', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Form.Control
                                        id='last_name'
                                        type='text'
                                        name='last_name'
                                        value={userData.last_name}
                                        onChange={e => handleChange(e)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='birth_dte'>
                                        {t('birth-dte', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Form.Control
                                        id='birth_dte'
                                        type='date'
                                        name='birth_dte'
                                        value={userData.birth_dte}
                                        onChange={e => handleChange(e)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='occupancy_cd'>
                                        {t('occupancy-cd', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Form.Select
                                        id='occupancy_cd'
                                        name='occupancy_cd'
                                        value={userData.occupancy_cd}
                                        onChange={e => handleChange(e)}
                                        required
                                    >
                                        <option value='S'>
                                            {t('oc-options.S', { ns: 'form' })}
                                        </option>
                                        <option value='B'>
                                            {t('oc-options.B', { ns: 'form' })}
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='residency_cd'>
                                        {t('residency-cd', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Form.Select
                                        id='residency_cd'
                                        name='residency_cd'
                                        value={userData.residency_cd}
                                        onChange={e => handleChange(e)}
                                        required
                                    >
                                        <option value='U'>
                                            {t('rc-options.U', { ns: 'form' })}
                                        </option>
                                        {userData.occupancy_cd !== 'B' && (
                                            <>
                                                <option value='M'>
                                                    {t('rc-options.M', {
                                                        ns: 'form'
                                                    })}
                                                </option>
                                                <option value='A'>
                                                    {t('rc-options.A', {
                                                        ns: 'form'
                                                    })}
                                                </option>
                                                <option value='N'>
                                                    {t('rc-options.N', {
                                                        ns: 'form'
                                                    })}
                                                </option>
                                            </>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='citizenship_cd'>
                                        {t('citizenship-cd', { ns: 'form' })}{' '}
                                        <RequiredField />
                                    </Form.Label>
                                    <Form.Select
                                        id='citizenship_cd'
                                        name='citizenship_cd'
                                        value={userData.citizenship_cd}
                                        onChange={e => handleChange(e)}
                                        required
                                    >
                                        <option value='C'>
                                            {t('cc-options.C', { ns: 'form' })}
                                        </option>
                                        <option value='A'>
                                            {t('cc-options.A', { ns: 'form' })}
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <div>
                                        {t('religion-cd', { ns: 'form' })}
                                    </div>
                                    <Row
                                        role='radiogroup'
                                        aria-label={t('religion-cd', {
                                            ns: 'form'
                                        })}
                                    >
                                        <Col>
                                            <Form.Check
                                                id='religion_cd'
                                                type='radio'
                                                value='R'
                                                name='religion_cd'
                                                checked={
                                                    userData.religion_cd === 'R'
                                                }
                                                label={t('options.yes', {
                                                    ns: 'common'
                                                })}
                                                onChange={e => handleChange(e)}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                id='religion'
                                                type='radio'
                                                value='N'
                                                name='religion_cd'
                                                checked={
                                                    userData.religion_cd === 'N'
                                                }
                                                label={t('options.no', {
                                                    ns: 'common'
                                                })}
                                                onChange={e => handleChange(e)}
                                            />
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <div>
                                        {t('french-language-rights-flg', {
                                            ns: 'form'
                                        })}
                                    </div>
                                    <Row
                                        role='radiogroup'
                                        aria-label={t(
                                            'french-language-rights-flg',
                                            {
                                                ns: 'form'
                                            }
                                        )}
                                    >
                                        <Col>
                                            <Form.Check
                                                id='french_language_rights_flg'
                                                type='radio'
                                                value='Y'
                                                name='french_language_rights_flg'
                                                checked={
                                                    userData.french_language_rights_flg ===
                                                    'Y'
                                                }
                                                label={t('options.yes', {
                                                    ns: 'common'
                                                })}
                                                onChange={e => handleChange(e)}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                id='french_language_rights'
                                                type='radio'
                                                value='N'
                                                name='french_language_rights_flg'
                                                checked={
                                                    userData.french_language_rights_flg ===
                                                    'N'
                                                }
                                                label={t('options.no', {
                                                    ns: 'common'
                                                })}
                                                onChange={e => handleChange(e)}
                                            />
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3'>
                                <Form.Group>
                                    <Form.Label htmlFor='school_support_cd'>
                                        {t('school-support-cd', { ns: 'form' })}
                                    </Form.Label>
                                    <Form.Select
                                        id='school_support_cd'
                                        name='school_support_cd'
                                        value={userData.school_support_cd}
                                        onChange={e => handleChange(e)}
                                    >
                                        {ssOptions.map((o, idx) => {
                                            const value = o?.toString()
                                            if (
                                                value === 'S' &&
                                                userData.religion_cd !== 'R'
                                            ) {
                                                return
                                            } else if (
                                                value === 'A' &&
                                                (userData.citizenship_cd !==
                                                    'C' ||
                                                    userData.french_language_rights_flg !==
                                                        'Y')
                                            ) {
                                                return
                                            } else if (
                                                value === 'C' &&
                                                (userData.citizenship_cd !==
                                                    'C' ||
                                                    userData.french_language_rights_flg !==
                                                        'Y' ||
                                                    userData.religion_cd !==
                                                        'R')
                                            ) {
                                                return
                                            } else if (
                                                value === 'D' &&
                                                (userData.religion_cd === 'R' ||
                                                    address?.region_cd !==
                                                        '16' ||
                                                    address?.roll_num.substring(
                                                        0,
                                                        4
                                                    ) !== '4372')
                                            ) {
                                                return
                                            } else {
                                                return (
                                                    <option value={value}>
                                                        {t(
                                                            'ssc-options.' +
                                                                value,
                                                            {
                                                                ns: 'form'
                                                            }
                                                        )}
                                                    </option>
                                                )
                                            }
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='mt-3'></Col>
                        </Row>
                        <div className='mt-3 button-container'>
                            <Button onClick={() => props.setShowForm(false)}>
                                {t('buttons.cancel', { ns: 'common' })}
                            </Button>
                            <Button className='button-margin' type='submit'>
                                {t('buttons.add', { ns: 'common' })}
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </>
    )
}

export default Add
