import ReCaptcha from './ReCaptcha'
import { useTranslation } from 'react-i18next'

function ReCaptchaContainer({
    type,
    onChange,
    handleCaptchaChange,
    checked,
    setChecked,
    recaptchaRef,
    lang
}) {
    const { t } = useTranslation(['terms', 'common'])
    const privacyUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/AboutUs/Policies/PrivacyStatement'
            : 'https://www.mpac.ca/en/AboutUs/Policies/PrivacyStatement'
    return (
        <div className='mt-3 attestation' role='region' aria-label='ReCaptcha'>
            {type === 'attestation' && (
                <>
                    {t('para-twelve', { ns: 'terms' })}
                    <br />
                    <br />
                    {t('para-thirteen', { ns: 'terms' })}
                    <br />
                    <br />
                    {t('para-fourteen', { ns: 'terms' })}
                    <br />
                    <br />
                </>
            )}
            {t('agree-one', { ns: 'common' })}
            <a
                href={privacyUrl}
                target='_blank'
                rel='noreferrer'
                className='blue'
            >
                {t('agree-link', { ns: 'common' })}
            </a>{' '}
            {t('agree-two', { ns: 'common' })}
            <ReCaptcha
                onChange={onChange}
                handleCaptchaChange={handleCaptchaChange}
                checked={checked}
                setChecked={setChecked}
                recaptchaRef={recaptchaRef}
                lang={lang}
            />
        </div>
    )
}

export default ReCaptchaContainer
