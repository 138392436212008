import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Form, InputGroup, FormLabel, Button } from 'react-bootstrap'
import { FaQuestionCircle, FaEdit } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import CustomTooltip from '../Custom/CustomTooltip'
import RequiredField from '../Custom/RequiredField'
import { resendEmail } from '../APIService/APIService'
import CustomModal from '../Custom/CustomModal'
import './ViewProperty.css'

function ViewProperty(props) {
    const { t } = useTranslation(['form', 'tooltip', 'common', 'profile'])
    const input = useRef(null)
    const contactUrl =
        props.lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    const [address, setAddress] = useState(null)
    const [checkTenants, setCheckTenants] = useState(null)
    const [schoolSupportCd, setSchoolSupportCd] = useState(null)
    const [ssTooltip, setSsTooltip] = useState(false)
    const [isArray, setIsArray] = useState(false)
    const [areaOccupied, setAreaOccupied] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [showModal, setShowModal] = useState(false)
    const schoolLeaseFlag = useSelector(
        state => state.schoolLeaseFlagReducer.value
    )
    useEffect(() => {
        setEmailAddress(props.emailAddress || '')
    }, [props.emailAddress])
    useEffect(() => {
        setAddress(props.address)
        setCheckTenants(props.checkTenants)
        setSchoolSupportCd(props.schoolSupportCd)
        setSsTooltip(props.ssTooltip)
        setIsArray(Array.isArray(schoolSupportCd) && schoolSupportCd.length > 1)
        setAreaOccupied(props.areaOccupied)
        setEmailAddress(props.emailAddress || '')
    }, [])
    const formatSchoolSupportCd = schoolSupportCd => {
        if (schoolSupportCd) {
            if (Array.isArray(schoolSupportCd)) {
                let temp = ''
                schoolSupportCd.map((e, i) => {
                    let val = t('ssc-options.' + e, { ns: 'form' })
                    temp = temp + val
                    if (i !== schoolSupportCd.length - 1) {
                        temp = temp + ', '
                    }
                })
                return temp
            } else {
                return t('ssc-options.' + schoolSupportCd, { ns: 'form' })
            }
        } else {
            return ''
        }
    }
    const checkValidEmail = emailAddress => {
        if (!emailAddress) {
            return false
        } else if (
            !emailAddress.match(
                /^[a-zA-Z0-9_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            )
        ) {
            return false
        }
        return true
    }
    const resendConfirmationEmail = () => {
        let result = checkValidEmail(emailAddress)
        if (result) {
            resendEmail(address?.roll_num, emailAddress, props.lang).then(
                response => {
                    setShowModal(true)
                    return
                }
            )
        }
    }
    return (
        <div role='region' aria-label={t('details', { ns: 'profile' })}>
            <Row>
                <Col className='mt-3 view-property-heading'>
                    {t('municipality', { ns: 'form' })}
                </Col>
                <Col className='mt-3 view-property-heading'>
                    {t('roll-num', { ns: 'form' })}
                </Col>
                <Col className='mt-3 view-property-heading'>
                    {t('street-address', { ns: 'form' })}
                </Col>
            </Row>
            <Row>
                <Col className='mt-3'>{address?.municipality}</Col>
                <Col className='mt-3'>{address?.roll_num}</Col>
                <Col className='mt-3'>{address?.mun_address_30}</Col>
            </Row>
            <Row>
                <Col className='mt-3 view-property-heading'>
                    {t('school-support-cd', { ns: 'form' })}{' '}
                    {isArray || ssTooltip ? (
                        <CustomTooltip
                            placement='top'
                            delay={ssTooltip ? 1200 : 0}
                            width='500px'
                            content={
                                <>
                                    {isArray && (
                                        <>
                                            {t('multi-ssc.para-one', {
                                                ns: 'tooltip'
                                            })}
                                            <br />
                                            <br />
                                            {t('multi-ssc.para-two', {
                                                ns: 'tooltip'
                                            })}
                                        </>
                                    )}
                                    {isArray && ssTooltip && <hr />}
                                    {ssTooltip && (
                                        <>
                                            {t('has-tenants.para-one', {
                                                ns: 'tooltip'
                                            })}
                                            <a
                                                className='blue'
                                                href={contactUrl}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                {t('has-tenants.link', {
                                                    ns: 'tooltip'
                                                })}
                                            </a>
                                            .
                                        </>
                                    )}
                                </>
                            }
                            display={
                                <span>
                                    <FaQuestionCircle />
                                </span>
                            }
                        />
                    ) : (
                        <></>
                    )}
                </Col>
                <Col className='mt-3 view-property-heading'>
                    {t('occupants', { ns: 'form' })}{' '}
                    {checkTenants ? (
                        <CustomTooltip
                            placement='top'
                            delay={1200}
                            width='500px'
                            content={
                                <>
                                    {' '}
                                    {t('occupants.para-one', {
                                        ns: 'tooltip'
                                    })}{' '}
                                    <a
                                        href={contactUrl}
                                        target='_blank'
                                        rel='noreferrer'
                                        className='blue'
                                    >
                                        {t('occupants.link', { ns: 'tooltip' })}
                                    </a>{' '}
                                    {/* {t('occupants.para-two', { ns: 'tooltip' })} */}
                                </>
                            }
                            display={
                                <span>
                                    <FaQuestionCircle />
                                </span>
                            }
                        />
                    ) : (
                        <></>
                    )}
                </Col>
                <Col className='mt-3 view-property-heading'>
                    <FormLabel htmlFor='area-occupied'>
                        {t('area-occupied', { ns: 'form' })} <RequiredField />
                    </FormLabel>{' '}
                    <CustomTooltip
                        placement='top'
                        width='500px'
                        content={
                            <>
                                {t('area-occupied.para-one', { ns: 'tooltip' })}
                            </>
                        }
                        display={
                            <span>
                                <FaQuestionCircle />
                            </span>
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col className='mt-3'>
                    {schoolLeaseFlag === 'Y'
                        ? 'Please refer to your property notice'
                        : formatSchoolSupportCd(
                              props.schoolSupportCd
                          )?.toUpperCase()}
                </Col>
                <Col className='mt-3'>
                    {t('options.' + (checkTenants ? 'yes' : 'no'), {
                        ns: 'common'
                    })?.toUpperCase()}
                </Col>
                <Col className='mt-3'>
                    <Form>
                        <Form.Group>
                            <Form.Select
                                id='area-occupied'
                                className={
                                    props.showErrorAlert &&
                                    !props.areaOccupied &&
                                    'invalid-field'
                                }
                                name='area_occupied'
                                value={props.areaOccupied}
                                onChange={e =>
                                    props.handleAreaOccupied(e.target.value)
                                }
                                required
                            >
                                <option value=''></option>
                                <option value={1}>
                                    {t('ao-options.1', {
                                        ns: 'form'
                                    })}
                                </option>
                                <option value={2}>
                                    {t('ao-options.2', { ns: 'form' })}
                                </option>
                                <option value={3}>
                                    {t('ao-options.3', { ns: 'form' })}
                                </option>
                                <option value={4}>
                                    {t('ao-options.4', { ns: 'form' })}
                                </option>
                                <option value={5}>
                                    {t('ao-options.5', { ns: 'form' })}
                                </option>
                                <option value={6}>
                                    {t('ao-options.6', { ns: 'form' })}
                                </option>
                                <option value={7}>
                                    {t('ao-options.7', { ns: 'form' })}
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col className='mt-3 view-property-heading'>
                    <FormLabel htmlFor='email-address'>
                        {t('email-address', { ns: 'form' })} <RequiredField />
                    </FormLabel>{' '}
                    <CustomTooltip
                        placement='top'
                        width='500px'
                        content={
                            <>
                                {t('email-address.para-one', { ns: 'tooltip' })}
                            </>
                        }
                        display={
                            <span>
                                <FaQuestionCircle />
                            </span>
                        }
                    />
                </Col>
                <Col className='mt-3 view-property-heading'></Col>
                <Col className='mt-3'></Col>
            </Row>
            <Row>
                <Col className='mt-3 view-property-heading'>
                    <Form>
                        <InputGroup>
                            <Form.Control
                                id='email-address'
                                type='email'
                                name='email_address'
                                value={emailAddress}
                                className={
                                    props.showErrorAlert &&
                                    !checkValidEmail(emailAddress) &&
                                    'invalid-field'
                                }
                                onChange={e =>
                                    props.handleEmailAddress(e.target.value)
                                }
                                ref={input}
                                required
                            />
                            <InputGroup.Text
                                id='owner-email-end'
                                className={
                                    props.showErrorAlert &&
                                    !checkValidEmail(emailAddress)
                                        ? 'invalid-field cursor-pointer'
                                        : 'cursor-pointer'
                                }
                                onClick={() => input.current.focus()}
                            >
                                <FaEdit className='blue' />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                </Col>
                <Col className='mt-3'>
                    <Button
                        onClick={() => {
                            resendConfirmationEmail()
                        }}
                        disabled={showModal}
                    >
                        {t('resend-email', { ns: 'form' })}
                    </Button>
                </Col>
                <Col className='mt-3'></Col>
            </Row>
            <CustomModal
                show={showModal}
                close={() => setShowModal(false)}
                dialog='modal-wide'
                title={t('resend-email-header', { ns: 'form' })}
                body={<div>{t('resend-email-body', { ns: 'form' })}</div>}
                footer={
                    <Button onClick={() => setShowModal(false)}>Close</Button>
                }
                showCancel={false}
            />
        </div>
    )
}

export default ViewProperty
