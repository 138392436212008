import { useEffect, useRef, useState, Fragment } from 'react'
import { Row, Col, Form, Table, Pagination, Button } from 'react-bootstrap'
import { FaEdit, FaCheck, FaTimes, FaTrash } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import ResponsivePagination from 'react-responsive-pagination'

import CustomTooltip from '../Custom/CustomTooltip'
import CustomModal from '../Custom/CustomModal'
import TableTooltip from '../Custom/TableTooltip'
import { getSchoolBoards } from '../APIService/APIService'
import { sortSchoolSupportArray } from '../../Common/Functions'
import './OccupantsTable.css'

function OccupantsTable(props) {
    const { t } = useTranslation(['form', 'profile', 'common'])
    const [residents, setResidents] = useState([])
    const [filteredResidents, setFilteredResidents] = useState([])
    const [currentResidents, setCurrentResidents] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [editable, setEditable] = useState(false)
    const [currentRow, setCurrentRow] = useState(null)
    const [sortedField, setSortedField] = useState(null)
    const [sortOrder, setSortOrder] = useState(false)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState([])
    const [numPages, setNumPages] = useState(0)
    const [showCheck, setShowCheck] = useState(false)
    const [tempHolder, setTempHolder] = useState(null)
    const ssOptions = props.ssOptions
    const wrapperRef = useRef()
    useEffect(() => {
        setEditable(props.editable)
        setCurrentRow(props.currentRow)
        document.addEventListener('click', handleClickOutside, false)
        return () => {
            document.removeEventListener('click', handleClickOutside, false)
        }
    }, [props])
    const handleClickOutside = e => {
        if (
            e.target.closest('span') &&
            e.target.closest('span').id === 'fa-edit'
        ) {
        } else if (!wrapperRef.current?.contains(e.target)) {
            if (props.editable && checkValid(props.currentRow)) {
                props.handleEditable(false)
            }
        } else if (e.target.closest('thead')) {
            props.handleEditable(false)
        }
    }
    useEffect(() => {
        let temp = props.residents.filter(e => !e.deleted)
        let tempNum = Math.ceil(temp.length / 5)
        setNumPages(tempNum)
        setResidents(temp)
        setFilteredResidents(temp)
        // setCurrentResidents(
        //     temp.length < 5
        //         ? temp.slice((page - 1) * 5, (page - 1) * 5 + temp.length)
        //         : temp.slice((page - 1) * 5, page * 5)
        // )
        let tempPages = []
        for (let i = 0; i < tempNum; i++) {
            tempPages.push(i + 1)
        }
        setPages(tempPages)
        // if (page > tempNum && page > 0) {
        //     setPage(tempNum)
        // }
    }, [props.residents])
    useEffect(() => {
        let temp = filteredResidents
        let tempArray = temp.slice((page - 1) * 5, page * 5)
        setCurrentResidents(tempArray)
    }, [page, filteredResidents])
    const HeaderSort = props => {
        return (
            <span>
                {sortedField === props.colName
                    ? sortOrder === true
                        ? '▴'
                        : '▾'
                    : '▴▾'}
            </span>
        )
    }
    const sortCol = colName => {
        let tempOrder = sortOrder
        if (sortedField !== colName) {
            setSortedField(colName)
            setSortOrder(true)
        } else {
            setSortOrder(!tempOrder)
        }
        let temp = residents
        temp.sort((a, b) => {
            if (!a[colName]) {
                return tempOrder ? 1 : -1
            }
            if (!b[colName]) {
                return tempOrder ? -1 : 1
            }
            if (a[colName] < b[colName]) {
                return tempOrder ? -1 : 1
            }
            if (a[colName] > b[colName]) {
                return tempOrder ? 1 : -1
            }
            return 0
        })
        props.updateResidents(temp)
    }
    const handleChange = (ev, i, e) => {
        let ssUpdated = false
        let name = ev.target.name
        if (name === 'school_support_cd' || name === 'occupancy_cd') {
            ssUpdated = true
        }
        let value = ev.target.value
        let temp = residents
        temp[(page - 1) * 5 + i][name] = value
        if (
            (name === 'citizenship_cd' && value !== 'C') ||
            (name === 'french_language_rights_flg' && value !== 'Y')
        ) {
            if (e.school_support_cd === 'A' || e.school_support_cd === 'C') {
                temp[(page - 1) * 5 + i]['school_support_cd'] = 'P'
                ssUpdated = true
            }
        }
        if (name === 'religion_cd' && value !== 'R') {
            if (e.school_support_cd === 'S' || e.school_support_cd === 'C') {
                temp[(page - 1) * 5 + i]['school_support_cd'] = 'P'
                ssUpdated = true
            }
        }
        if (name === 'religion_cd' && value === 'R') {
            if (e.school_support_cd === 'D') {
                temp[(page - 1) * 5 + i]['school_support_cd'] = 'P'
                ssUpdated = true
            }
        }
        props.updateResidents(temp, ssUpdated)
    }
    const handleSearchChange = value => {
        let keys = [
            'first_name',
            'last_name',
            'birth_dte',
            'occupancy_cd',
            'residency_cd',
            'citizenship_cd',
            'religion_cd',
            'french_language_rights_flg',
            'school_support_cd',
            'email_address'
        ]
        setSearchValue(value)
        if (value) {
            value = typeof value === 'string' ? value?.toUpperCase() : value
            let temp = []
            residents.filter(el => {
                let check = false
                keys.map(k => {
                    let checkValue = ''
                    if (k === 'occupancy_cd') {
                        checkValue = el[k]
                            ? t('oc-options.' + el[k], { ns: 'form' })
                            : ''
                    } else if (k === 'residency_cd') {
                        checkValue = el[k]
                            ? t('rc-options.' + el[k], { ns: 'form' })
                            : ''
                    } else if (k === 'school_support_cd') {
                        checkValue = el[k]
                            ? t('ssc-options.' + el[k], { ns: 'form' })
                            : ''
                    } else if (k === 'birth_dte') {
                        return
                    } else {
                        checkValue =
                            typeof k === 'string' ? el[k]?.toUpperCase() : el[k]
                    }
                    if (checkValue?.includes(value)) {
                        check = true
                    }
                })
                if (check) {
                    temp.push(el)
                }
            })
            setFilteredResidents(temp)
        } else {
            setFilteredResidents(residents)
        }
    }
    const resetRow = i => {
        let temp = [...residents]
        if (tempHolder) {
            temp[i] = tempHolder
        }
        props.updateResidents(temp)
        setTempHolder(null)
        props.handleEditable(!editable)
    }
    const checkValid = e => {
        if (e.new && (!e.first_name || !e.last_name || !e.birth_dte)) {
            return false
        }
        return true
    }
    const formatName = name => {
        if (name) {
            if (name.length < 11) {
                return name.toUpperCase()
            } else {
                return (
                    <CustomTooltip
                        placement='top'
                        width={0}
                        content={<>{name.toUpperCase()}</>}
                        display={
                            <span>
                                {name.slice(0, 8)?.toUpperCase() + '...'}
                            </span>
                        }
                    />
                )
            }
        }
        return ''
    }
    const overlay = (name, input) => {
        return (
            <CustomTooltip
                placement='top'
                width={0}
                content={<>{name.toUpperCase()}</>}
                display={<span>{input}</span>}
            />
        )
    }
    const formatNameInput = (name, type) => {
        if (name) {
            let input = ''
            if (type === 'occupancy_cd') {
                name = t('oc-options.O', { ns: 'form' })
            }
            input = (
                <Form.Group>
                    <Form.Control
                        type='text'
                        name='name'
                        placeholder={name}
                        value={
                            type === 'occupancy_cd'
                                ? name?.slice(0, 8) + '...'
                                : name?.slice(0, 3) + '...'
                        }
                        disabled={true}
                    />
                </Form.Group>
            )
            if (name.length < 5) {
                return input
            } else {
                return overlay(name, input)
            }
        }
        return ''
    }
    const CheckBody = () => {
        return (
            <>
                {t('check.para-one', { ns: 'occupantsTable' })}
                <i>{t('check.para-two', { ns: 'occupantsTable' })}</i>
                {t('check.para-three', { ns: 'occupantsTable' })}
                <i>{t('check.para-four', { ns: 'occupantsTable' })}</i>.
            </>
        )
    }
    return (
        <div className='mt-3'>
            <fieldset>
                <legend className='header-label'>
                    {t('sub-title-two', { ns: 'profile' })}
                </legend>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label
                                htmlFor='search'
                                className='view-property-heading'
                            >
                                Search
                            </Form.Label>
                            <Form.Control
                                type='text'
                                id='search'
                                name='search'
                                value={searchValue || ''}
                                onChange={e =>
                                    handleSearchChange(e.target.value)
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <div
                    className='table-responsive'
                    role='region'
                    aria-label={t('sub-title-two', { ns: 'profile' })}
                >
                    <Table striped ref={wrapperRef}>
                        <thead>
                            <tr>
                                <th>
                                    <span
                                        id='first-name'
                                        className='cursor-pointer'
                                        onClick={() => sortCol('first_name')}
                                    >
                                        {t('first-name.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t('first-name.two', {
                                                ns: 'occupantsTable'
                                            })}
                                            <HeaderSort colName='first_name' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='last-name'
                                        className='cursor-pointer'
                                        onClick={() => sortCol('last_name')}
                                    >
                                        {t('last-name.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t('last-name.two', {
                                                ns: 'occupantsTable'
                                            })}{' '}
                                            <HeaderSort colName='last_name' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='birth-dte'
                                        className='cursor-pointer'
                                        onClick={() => sortCol('birth_dte')}
                                    >
                                        {t('birth-dte.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t('birth-dte.two', {
                                                ns: 'occupantsTable'
                                            })}{' '}
                                            <HeaderSort colName='birth_dte' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='occupancy-cd-label'
                                        className='cursor-pointer'
                                        onClick={() => sortCol('occupancy_cd')}
                                    >
                                        {t('occupancy-cd.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t('occupancy-cd.two', {
                                                ns: 'occupantsTable'
                                            })}
                                            <TableTooltip
                                                column='occupancy_cd'
                                                lang={props.lang}
                                            />
                                            <HeaderSort colName='occupancy_cd' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='residency-cd-label'
                                        className='cursor-pointer'
                                        onClick={() => sortCol('residency_cd')}
                                    >
                                        <span className='table-header'>
                                            {t('residency-cd.one', {
                                                ns: 'occupantsTable'
                                            })}
                                            <TableTooltip column='residency_cd' />
                                            <HeaderSort colName='residency_cd' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='citizenship-cd-label'
                                        className='cursor-pointer'
                                        onClick={() =>
                                            sortCol('citizenship_cd')
                                        }
                                    >
                                        <span className='table-header'>
                                            {t('citizenship-cd.one', {
                                                ns: 'occupantsTable'
                                            })}
                                            <TableTooltip column='citizenship_cd' />
                                            <HeaderSort colName='citizenship_cd' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='religion-cd-label'
                                        className='cursor-pointer'
                                        onClick={() => sortCol('religion_cd')}
                                    >
                                        {t('religion-cd.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t('religion-cd.two', {
                                                ns: 'occupantsTable'
                                            })}
                                            <TableTooltip column='religion_cd' />
                                            <HeaderSort colName='religion_cd' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='french-language-rights-flg-label'
                                        className='cursor-pointer form-label-table'
                                        onClick={() =>
                                            sortCol(
                                                'french_language_rights_flg'
                                            )
                                        }
                                    >
                                        {t('french-language-rights-flg.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t(
                                                'french-language-rights-flg.two',
                                                {
                                                    ns: 'occupantsTable'
                                                }
                                            )}
                                            <TableTooltip column='french_language_rights_flg' />
                                            <HeaderSort colName='french_language_rights_flg' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span
                                        id='school-support-cd-label'
                                        className='cursor-pointer'
                                        onClick={() =>
                                            sortCol('school_support_cd')
                                        }
                                    >
                                        {t('school-support-cd.one', {
                                            ns: 'occupantsTable'
                                        })}
                                        <span className='table-header'>
                                            {t('school-support-cd.two', {
                                                ns: 'occupantsTable'
                                            })}
                                            <TableTooltip
                                                column='school_support_cd'
                                                width='1000px'
                                            />
                                            <HeaderSort colName='school_support_cd' />
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    {t('edit', { ns: 'occupantsTable' })}
                                    <div>
                                        {t('delete', { ns: 'occupantsTable' })}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentResidents?.map((e, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            {editable && currentRow === e ? (
                                                e.new ? (
                                                    <Form.Group>
                                                        <Form.Control
                                                            id='first_name'
                                                            type='text'
                                                            name='first_name'
                                                            value={
                                                                e.first_name ||
                                                                ''
                                                            }
                                                            onChange={ev =>
                                                                handleChange(
                                                                    ev,
                                                                    i,
                                                                    e
                                                                )
                                                            }
                                                            disabled={!e.new}
                                                            className={
                                                                e.new &&
                                                                !e.first_name &&
                                                                'invalid-field'
                                                            }
                                                        />
                                                    </Form.Group>
                                                ) : (
                                                    <>
                                                        {formatNameInput(
                                                            e.first_name
                                                        )}
                                                    </>
                                                )
                                            ) : (
                                                <>{formatName(e.first_name)}</>
                                            )}
                                        </td>
                                        {/* <td>{e.middle_name}</td> */}
                                        <td>
                                            {editable && currentRow === e ? (
                                                e.new ? (
                                                    <Form.Group>
                                                        <Form.Control
                                                            id='last_name'
                                                            type='text'
                                                            name='last_name'
                                                            value={
                                                                e.last_name ||
                                                                ''
                                                            }
                                                            onChange={ev =>
                                                                handleChange(
                                                                    ev,
                                                                    i,
                                                                    e
                                                                )
                                                            }
                                                            disabled={!e.new}
                                                            className={
                                                                e.new &&
                                                                !e.last_name &&
                                                                'invalid-field'
                                                            }
                                                        />
                                                    </Form.Group>
                                                ) : (
                                                    <>
                                                        {formatNameInput(
                                                            e.last_name
                                                        )}
                                                    </>
                                                )
                                            ) : (
                                                <>{formatName(e.last_name)}</>
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                <Form.Group>
                                                    <Form.Control
                                                        id={'birth_dte' + { i }}
                                                        type='date'
                                                        name='birth_dte'
                                                        value={
                                                            e.birth_dte || ''
                                                        }
                                                        onChange={ev =>
                                                            handleChange(
                                                                ev,
                                                                i,
                                                                e
                                                            )
                                                        }
                                                        className={
                                                            e.new &&
                                                            !e.birth_dte &&
                                                            'invalid-field'
                                                        }
                                                    />
                                                </Form.Group>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                e.occupancy_cd !== 'O' ? (
                                                    <Form.Group>
                                                        <Form.Select
                                                            id={
                                                                'occupancy_cd' +
                                                                { i }
                                                            }
                                                            aria-labelledby={t(
                                                                'occupancy-cd',
                                                                {
                                                                    ns: 'form'
                                                                }
                                                            )}
                                                            name='occupancy_cd'
                                                            value={
                                                                e.occupancy_cd ||
                                                                ''
                                                            }
                                                            onChange={e =>
                                                                handleChange(
                                                                    e,
                                                                    i
                                                                )
                                                            }
                                                            disabled={
                                                                e.occupancy_cd ===
                                                                'O'
                                                            }
                                                        >
                                                            {e.occupancy_cd ===
                                                                'O' && (
                                                                <option value='O'>
                                                                    {t(
                                                                        'oc-options.O',
                                                                        {
                                                                            ns: 'form'
                                                                        }
                                                                    )}
                                                                </option>
                                                            )}
                                                            <option value='S'>
                                                                {t(
                                                                    'oc-options.S',
                                                                    {
                                                                        ns: 'form'
                                                                    }
                                                                )}
                                                            </option>
                                                            <option value='B'>
                                                                {t(
                                                                    'oc-options.B',
                                                                    {
                                                                        ns: 'form'
                                                                    }
                                                                )}
                                                            </option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                ) : (
                                                    <>
                                                        {formatNameInput(
                                                            e.occupancy_cd,
                                                            'occupancy_cd'
                                                        )}
                                                    </>
                                                )
                                            ) : e.occupancy_cd ? (
                                                t(
                                                    'oc-options.' +
                                                        e.occupancy_cd,
                                                    { ns: 'form' }
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                <Form.Group>
                                                    <Form.Select
                                                        id={
                                                            'residency_cd' +
                                                            { i }
                                                        }
                                                        aria-labelledby={t(
                                                            'residency-cd',
                                                            { ns: 'form' }
                                                        )}
                                                        name='residency_cd'
                                                        value={
                                                            e.residency_cd || ''
                                                        }
                                                        onChange={ev =>
                                                            handleChange(
                                                                ev,
                                                                i,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {e.occupancy_cd ===
                                                            'B' && (
                                                            <option hidden>
                                                                {t(
                                                                    'rc-options.U',
                                                                    {
                                                                        ns: 'form'
                                                                    }
                                                                )}
                                                            </option>
                                                        )}
                                                        <option value='U'>
                                                            {t('rc-options.U', {
                                                                ns: 'form'
                                                            })}
                                                        </option>
                                                        {e.occupancy_cd !==
                                                            'B' && (
                                                            <>
                                                                <option value='M'>
                                                                    {t(
                                                                        'rc-options.M',
                                                                        {
                                                                            ns: 'form'
                                                                        }
                                                                    )}
                                                                </option>
                                                                <option value='A'>
                                                                    {t(
                                                                        'rc-options.A',
                                                                        {
                                                                            ns: 'form'
                                                                        }
                                                                    )}
                                                                </option>
                                                                <option value='N'>
                                                                    {t(
                                                                        'rc-options.N',
                                                                        {
                                                                            ns: 'form'
                                                                        }
                                                                    )}
                                                                </option>
                                                            </>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            ) : e.residency_cd ? (
                                                t(
                                                    'rc-options.' +
                                                        e.residency_cd,
                                                    { ns: 'form' }
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                <Form.Group>
                                                    <Form.Select
                                                        id={
                                                            'citizenship_cd' +
                                                            { i }
                                                        }
                                                        aria-labelledby={t(
                                                            'citizenship-cd',
                                                            {
                                                                ns: 'form'
                                                            }
                                                        )}
                                                        name='citizenship_cd'
                                                        value={
                                                            e.citizenship_cd ||
                                                            ''
                                                        }
                                                        onChange={ev =>
                                                            handleChange(
                                                                ev,
                                                                i,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        <option value='C'>
                                                            {t('cc-options.C', {
                                                                ns: 'form'
                                                            })}
                                                        </option>
                                                        {e.citizenship_cd ===
                                                            'U' && (
                                                            <option value='U'>
                                                                {t(
                                                                    'cc-options.A',
                                                                    {
                                                                        ns: 'form'
                                                                    }
                                                                )}
                                                            </option>
                                                        )}
                                                        {e.citizenship_cd !==
                                                            'U' && (
                                                            <option value='A'>
                                                                {t(
                                                                    'cc-options.A',
                                                                    {
                                                                        ns: 'form'
                                                                    }
                                                                )}
                                                            </option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                <Form.Group>
                                                    <Form.Select
                                                        id={
                                                            'religion_cd' +
                                                            { i }
                                                        }
                                                        aria-labelledby={t(
                                                            'religion-cd',
                                                            { ns: 'form' }
                                                        )}
                                                        name='religion_cd'
                                                        value={
                                                            e.religion_cd || ''
                                                        }
                                                        onChange={ev =>
                                                            handleChange(
                                                                ev,
                                                                i,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        <option value='R'>
                                                            {t('options.yes', {
                                                                ns: 'common'
                                                            })}
                                                        </option>
                                                        <option value='N'>
                                                            {t('options.no', {
                                                                ns: 'common'
                                                            })}
                                                        </option>
                                                    </Form.Select>
                                                </Form.Group>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                <Form.Group>
                                                    <Form.Select
                                                        id={
                                                            'french_language_rights_flg' +
                                                            { i }
                                                        }
                                                        aria-labelledby={t(
                                                            'french-language-rights-flg',
                                                            {
                                                                ns: 'form'
                                                            }
                                                        )}
                                                        name='french_language_rights_flg'
                                                        value={
                                                            e.french_language_rights_flg ||
                                                            ''
                                                        }
                                                        onChange={ev =>
                                                            handleChange(
                                                                ev,
                                                                i,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        <option value='Y'>
                                                            {t('options.yes', {
                                                                ns: 'common'
                                                            })}
                                                        </option>
                                                        <option value='N'>
                                                            {t('options.no', {
                                                                ns: 'common'
                                                            })}
                                                        </option>
                                                    </Form.Select>
                                                </Form.Group>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>
                                            {editable && currentRow === e ? (
                                                <Form.Group>
                                                    <Form.Select
                                                        id={
                                                            'school_support_cd' +
                                                            { i }
                                                        }
                                                        aria-labelledby={t(
                                                            'school-support-cd',
                                                            {
                                                                ns: 'form'
                                                            }
                                                        )}
                                                        name='school_support_cd'
                                                        value={
                                                            e.school_support_cd ||
                                                            ''
                                                        }
                                                        onChange={ev =>
                                                            handleChange(
                                                                ev,
                                                                i,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {ssOptions.map(
                                                            (o, idx) => {
                                                                const value =
                                                                    o?.toString()
                                                                if (
                                                                    value ===
                                                                        'S' &&
                                                                    e.religion_cd !==
                                                                        'R'
                                                                ) {
                                                                    return
                                                                } else if (
                                                                    value ===
                                                                        'A' &&
                                                                    (e.citizenship_cd !==
                                                                        'C' ||
                                                                        e.french_language_rights_flg !==
                                                                            'Y')
                                                                ) {
                                                                    return
                                                                } else if (
                                                                    value ===
                                                                        'C' &&
                                                                    (e.citizenship_cd !==
                                                                        'C' ||
                                                                        e.french_language_rights_flg !==
                                                                            'Y' ||
                                                                        e.religion_cd !==
                                                                            'R')
                                                                ) {
                                                                    return
                                                                } else if (
                                                                    value ===
                                                                        'D' &&
                                                                    (e.religion_cd ===
                                                                        'R' ||
                                                                        props
                                                                            .address
                                                                            ?.region_cd !==
                                                                            '16' ||
                                                                        props.address?.roll_num.substring(
                                                                            0,
                                                                            4
                                                                        ) !==
                                                                            '4372')
                                                                ) {
                                                                    return
                                                                } else {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                value
                                                                            }
                                                                        >
                                                                            {t(
                                                                                'ssc-options.' +
                                                                                    value,
                                                                                {
                                                                                    ns: 'form'
                                                                                }
                                                                            )}
                                                                        </option>
                                                                    )
                                                                }
                                                            }
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            ) : e.school_support_cd ? (
                                                t(
                                                    'ssc-options.' +
                                                        e.school_support_cd,
                                                    { ns: 'form' }
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td className='icons-column'>
                                            {e.ips_owner_id !== null ? (
                                                editable && currentRow === e ? (
                                                    <>
                                                        <span
                                                            id='fa-other-save'
                                                            className='cursor-pointer blue'
                                                            onClick={() => {
                                                                let check =
                                                                    checkValid(
                                                                        e
                                                                    )
                                                                if (check) {
                                                                    props.handleEditable(
                                                                        !editable
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            <FaCheck />{' '}
                                                            {t('actions.save', {
                                                                ns: 'occupantsTable'
                                                            })}
                                                        </span>
                                                        <br />
                                                        <span
                                                            id='fa-other-cancel'
                                                            className='cursor-pointer blue'
                                                            onClick={() => {
                                                                resetRow(i)
                                                            }}
                                                        >
                                                            <FaTimes />{' '}
                                                            {t(
                                                                'actions.cancel',
                                                                {
                                                                    ns: 'occupantsTable'
                                                                }
                                                            )}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span
                                                        className={
                                                            '' +
                                                            (editable
                                                                ? 'fa-disabled'
                                                                : 'cursor-pointer blue')
                                                        }
                                                        onClick={() => {
                                                            setTempHolder({
                                                                ...e
                                                            })
                                                            props.handleCurrentRow(
                                                                e
                                                            )
                                                            setShowCheck(true)
                                                        }}
                                                    >
                                                        <FaEdit />{' '}
                                                        {t('actions.edit', {
                                                            ns: 'occupantsTable'
                                                        })}
                                                        <br />
                                                    </span>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                            {e.occupancy_cd !== 'O' ? (
                                                <span
                                                    className={
                                                        '' +
                                                        (editable &&
                                                        currentRow !== i
                                                            ? 'fa-disabled'
                                                            : 'cursor-pointer blue')
                                                    }
                                                    onClick={() => {
                                                        props.handleDelete(e, i)
                                                    }}
                                                >
                                                    <FaTrash />{' '}
                                                    {t('actions.delete', {
                                                        ns: 'occupantsTable'
                                                    })}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                {pages.length > 1 && (
                    <ResponsivePagination
                        current={page}
                        total={numPages}
                        onPageChange={e => {
                            setPage(e)
                        }}
                        // ariaPreviousLabel={t('pagination.next', {
                        //     ns: 'profile'
                        // })}
                        // ariaNextLabel={t('pagination.next', { ns: 'profile' })}
                    />
                )}
                {showCheck && (
                    <CustomModal
                        show={showCheck}
                        close={() => setShowCheck(false)}
                        dialog='modal-wide'
                        title={t('check.title', { ns: 'occupantsTable' })}
                        body={<CheckBody />}
                        bodyText={t('check.para-one', { ns: 'occupantsTable' })}
                        footer={
                            <Fragment>
                                <span id='fa-edit'>
                                    <Button
                                        id='edit-btn'
                                        onClick={() => {
                                            props.handleEditable(!editable)
                                            setShowCheck(false)
                                        }}
                                    >
                                        {t('buttons.ok', { ns: 'common' })}
                                    </Button>
                                </span>
                            </Fragment>
                        }
                        footerText={t('buttons.ok', { ns: 'common' })}
                    />
                )}
            </fieldset>
        </div>
    )
}

export default OccupantsTable
