import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'

function ReCaptcha({
    onChange,
    handleCaptchaChange,
    checked,
    setChecked,
    recaptchaRef,
    lang
}) {
    const { t } = useTranslation(['common'])
    const [language, setLanguage] = useState(lang)
    useEffect(() => {
        let iframe = document.querySelector('[title="reCAPTCHA"]')
        if (iframe) {
            iframe.removeAttribute('role')
        }
    }, [])
    useEffect(() => {
        let iframe = document.querySelector('[title="reCAPTCHA"]')
        if (iframe && language !== lang) {
            iframe.setAttribute(
                'src',
                iframe
                    .getAttribute('src')
                    .replace(/hl=(.*?)&/, 'hl=' + lang + '&')
            )
            setLanguage(lang)
        }
    }, [lang])
    return (
        <Form className='mt-3'>
            <input
                className='form-check-input'
                type='checkbox'
                name='flexRadioDefault'
                id='i-agree'
                value='Y'
                checked={checked === 'Y'}
                onChange={onChange}
                required
            />
            <label className='form-check-label' htmlFor='i-agree'>
                <Button onClick={() => setChecked('Y')}>
                    {t('buttons.i-agree', { ns: 'common' })}
                </Button>
            </label>
            <div className='mt-3'>
                <ReCAPTCHA
                    sitekey='6Ldmi54hAAAAAJ2Req1BJtyL596z6Yr3puUAimHA'
                    ref={recaptchaRef}
                    onChange={e => handleCaptchaChange(e)}
                />
            </div>
        </Form>
    )
}

export default ReCaptcha
