import { createSlice } from '@reduxjs/toolkit'

export const updatesInProgressSlice = createSlice({
    name: 'updatesInProgress',
    initialState: {
        updatesInProgress: []
    },
    reducers: {
        updateUpdatesInProgress: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updateUpdatesInProgress } = updatesInProgressSlice.actions

export default updatesInProgressSlice.reducer
