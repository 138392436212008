import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Button, Table, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AdminContainer from '../../Common/AdminContainer'
import CustomModal from '../Custom/CustomModal'
import {
    decodeToken,
    getInProgress,
    getLastUpdatedCompleted,
    getLatestUpdates,
    setLastUpdated
} from '../APIService/APIService'
import VwUpdates from '../../Common/VwUpdates'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateAdmin } from '../../Reducers/AdminUser'
import { updateUpdates } from '../../Reducers/Updates'
import { order } from '../../Common/AdminValues'

import './ViewUpdates.css'
import { updateUpdatesInProgress } from '../../Reducers/UpdatesInProgress'

function ViewUpdates() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authenticated = useSelector(
        state => state.authenticationReducer.value
    )
    const admin = useSelector(state => state.adminReducer.value)
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [modalBody, setModalBody] = useState('')
    const [updates, setUpdates] = useState([])
    // const [updated, setUpdated] = useState({})
    // const [completed, setCompleted] = useState({})
    useEffect(() => {
        const token = localStorage.getItem('ssToken')
        if (
            (!authenticated.authenticated || authenticated.type !== 'A') &&
            !token
        ) {
            navigate('/')
        } else {
            if (token) {
                decodeToken(token).then(response => {
                    if (response.decoded_token) {
                        const temp = response.decoded_token
                        const loginTime = temp.iat * 1000
                        const expireTime = loginTime + 60 * 60 * 1000
                        if (Date.now() < expireTime) {
                            // not expired
                            updateInfo({
                                user_principal_name: temp.name,
                                token: token,
                                perms: temp.roles
                            })
                            setup()
                        } else {
                            // expired
                            localStorage.removeItem('ssToken')
                            dispatch(updateAuthentication({}))
                            dispatch(updateAdmin({}))
                            navigate('/admin')
                        }
                    } else {
                        localStorage.removeItem('ssToken')
                        navigate('/')
                    }
                })
            } else {
                setup()
            }
        }
    }, [])
    const setup = () => {
        let tempUpdated = {}
        if (!updates.lastUpdatedDate) {
            getLastUpdatedCompleted(admin.token)
                .then(response => {
                    // console.log(response)
                    if (!response.msg) {
                        tempUpdated = {
                            lastUpdatedDate: response.last_updated[0],
                            lastUpdatedBy: response.last_updated[1],
                            lastCompletedDate: response.last_completed[0],
                            lastCompletedBy: response.last_completed[1]
                        }
                        return getLatestUpdates(admin.user_id)
                    } else {
                        navigate('/')
                    }
                })
                .then(response => {
                    // console.log(response)
                    if (response.length) {
                        let temp = []
                        response.forEach(e => {
                            let obj = JSON.parse(
                                JSON.stringify(JSON.parse(e), order)
                            )
                            if (obj.sub_roll_num) {
                                obj.roll_num = obj.sub_roll_num
                            } else {
                                obj.roll_num = obj.roll_num + '0000'
                            }
                            delete obj.sub_roll_num
                            temp = [...temp, obj]
                        })
                        dispatch(
                            updateUpdates({
                                updates: temp,
                                ...tempUpdated
                            })
                        )
                        setUpdates(temp)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                })
        }
    }
    const updateInfo = data => {
        dispatch(
            updateAuthentication({
                authenticated: true,
                type: 'A'
            })
        )
        dispatch(
            updateAdmin({
                user_id: data?.user_principal_name,
                token: data?.token,
                isSupervisor: data?.perms?.includes('supervisor')
            })
        )
    }
    const ViewUpdates = () => {
        const childComponent = (
            <div>
                {loading ? (
                    <Spinner
                        animation='border'
                        role='status'
                        className='mt-3'
                    />
                ) : (
                    <div>
                        <VwUpdates />
                        <div className='button-container'>
                            {admin.isSupervisor && (
                                <Button
                                    onClick={() => {
                                        setShowModal(true)
                                        setModalType('in-progress')
                                        setModalBody(
                                            updates?.filter(
                                                e => e.in_progress_date
                                            )?.length
                                                ? 'Are you sure you would like to load the in-progress updates?'
                                                : 'No in-progress records found. Please check again at a later time'
                                        )
                                    }}
                                >
                                    Show In-Progress
                                </Button>
                            )}
                            <Button
                                className='button-margin'
                                onClick={e => {
                                    setShowModal(true)
                                    setModalType('updates')
                                    setModalBody(
                                        updates.length
                                            ? 'Are you sure you would like to load the latest updates?'
                                            : 'No records found. Please check again at a later time'
                                    )
                                }}
                            >
                                Get Latest Updates
                            </Button>
                            <Button
                                className='button-margin'
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        )
        return <AdminContainer childComponent={childComponent} />
    }
    const Modal = () => {
        return (
            <CustomModal
                show={showModal}
                close={() => setShowModal(false)}
                dialog='modal-wide'
                title='Confirm'
                body={modalBody}
                footer={
                    <Button
                        onClick={() => {
                            if (updates.length) {
                                if (modalType === 'in-progress') {
                                    getInProgress().then(response => {
                                        // console.log(response)
                                        if (response.length) {
                                            let temp = []
                                            response.forEach(e => {
                                                let obj = JSON.parse(
                                                    JSON.stringify(
                                                        JSON.parse(e),
                                                        order
                                                    )
                                                )
                                                if (obj.sub_roll_num) {
                                                    obj.roll_num =
                                                        obj.sub_roll_num
                                                } else {
                                                    obj.roll_num =
                                                        obj.roll_num + '0000'
                                                }
                                                delete obj.sub_roll_num
                                                temp = [...temp, obj]
                                            })
                                            dispatch(
                                                updateUpdatesInProgress({
                                                    updatesInProgress: temp
                                                })
                                            )
                                            navigate('/in-progress')
                                        }
                                    })
                                } else {
                                    setLastUpdated(
                                        new Date()
                                            .toISOString()
                                            .slice(0, 19)
                                            .replace('T', ' '),
                                        admin.user_id
                                    ).then(response => {
                                        // console.log(response)
                                        navigate('/updates')
                                    })
                                }
                            }
                            setShowModal(false)
                        }}
                    >
                        OK
                    </Button>
                }
            />
        )
    }
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label='School Support Admin'
            >
                School Support Admin
            </h1>
            <div role='region' aria-label='View Updates'>
                <ViewUpdates />
                {showModal && <Modal />}
            </div>
        </Container>
    )
}

export default ViewUpdates
