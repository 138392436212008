import { useState, Fragment } from 'react'
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import 'bootstrap/dist/css/bootstrap.min.css'

import Home from './Components/Home/Home'
import FAQ from './Components/FAQ/FAQ'
import Verify from './Components/Verify/Verify'
import Terms from './Components/Terms/Terms'
import Profile from './Components/Profile/Profile'
import Confirmation from './Components/Confirmation/Confirmation'
import Attestation from './Components/Attestation/Attestation'
import Search from './Components/Search/Search'
import UpdateSchoolSupport from './Components/UpdateSchoolSupport/UpdateSchoolSupport'
import SchoolSupport from './Components/SchoolSupport/SchoolSupport'
import Confirm from './Components/Confirm/Confirm'
import Complete from './Components/Complete/Complete'
import PropertyNotFound from './Components/PropertyNotFound/PropertyNotFound'
import Admin from './Components/Admin/Admin'
import ViewUpdates from './Components/ViewUpdates/ViewUpdates'
import InProgressTable from './Components/InProgressTable/InProgressTable'
import UpdatesTable from './Components/UpdatesTable/UpdatesTable'
import Download from './Components/Download/Download'
import Stats from './Components/Stats/Stats'
import NotFound from './Components/NotFound/NotFound'
import CustomModal from './Components/Custom/CustomModal'
import TermsText from './Data/Terms'
import eaIcon from './Images/ea_app_icon_new.png'
import logo from './Images/EN_Blue_Screen.png'
import frLogo from './Images/FR_Blue_Screen.png'
import './App.css'

function App() {
    const { t } = useTranslation(['common', 'terms'])
    const [lang, setLang] = useState('en')
    const handleChangeLanguage = () => {
        switch (i18n.language) {
            case 'en':
                i18n.changeLanguage('fr')
                setLang('fr')
                break
            case 'fr':
                i18n.changeLanguage('en')
                setLang('en')
                break
            default:
                break
        }
    }

    const accessibilityUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/AboutUs/Policies/AccessibilityOntariansDisabilities'
            : 'https://www.mpac.ca/en/AboutUs/Policies/AccessibilityOntariansDisabilities'
    const eaUrl =
        lang === 'fr'
            ? 'https://www.essentialaccessibility.com/mpac-fr'
            : 'https://www.essentialaccessibility.com/mpac-en'
    const contactUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    const privacyUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/AboutUs/Policies/PrivacyStatement'
            : 'https://www.mpac.ca/en/AboutUs/Policies/PrivacyStatement'
    const [showTermsModal, setShowTermsModal] = useState(false)
    const handleModalClose = () => setShowTermsModal(false)
    const handleShowTermsModal = () => setShowTermsModal(true)
    const host = window.location.host

    return (
        <Router>
            <div className='app'>
                <Navbar bg='light' expand='lg'>
                    <Container>
                        <Navbar.Brand as={Link} to='/' className='blue'>
                            <h1>
                                <img
                                    src={i18n.language === 'fr' ? frLogo : logo}
                                    alt='EA Icon'
                                />
                                {t('header.title', { ns: 'common' })}
                            </h1>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse id='basic-navbar-nav'>
                            <Nav className='ms-auto'>
                                <Nav.Link href='/#/faq'>
                                    {' '}
                                    {t('header.faq', { ns: 'common' })}
                                </Nav.Link>
                                <Nav.Link
                                    href={accessibilityUrl}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {t('header.accessibility', {
                                        ns: 'common'
                                    })}
                                </Nav.Link>
                                <Nav.Link
                                    href={eaUrl}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <img
                                        src={eaIcon}
                                        alt='EA Icon'
                                        className='ea-icon'
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    href={contactUrl}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {t('header.contact', { ns: 'common' })}
                                </Nav.Link>
                                <Nav.Link onClick={handleChangeLanguage}>
                                    {t('header.language', { ns: 'common' })}
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div className='decorative-banner' />
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/verify' element={<Verify lang={lang} />} />
                    <Route path='/terms' element={<Terms lang={lang} />} />
                    <Route path='/profile' element={<Profile lang={lang} />} />
                    <Route path='/confirmation' element={<Confirmation />} />
                    <Route
                        path='/attestation'
                        element={<Attestation lang={lang} />}
                    />
                    <Route
                        exact
                        path='/search'
                        element={<Search lang={lang} />}
                    />
                    <Route path='/school-support' element={<SchoolSupport lang={lang} />} />
                    <Route path='/confirm' element={<Confirm lang={lang} />} />
                    <Route
                        path='/update-school-support'
                        element={<UpdateSchoolSupport />}
                    />
                    <Route
                        path='/complete'
                        element={<Complete lang={lang} />}
                    />
                    <Route
                        path='/property-not-found'
                        element={<PropertyNotFound lang={lang} />}
                    />
                    {host !== 'school-support.mpac.ca' && (
                        <>
                            <Route path='/admin' element={<Admin />} />
                            <Route
                                path='/view-updates'
                                element={<ViewUpdates />}
                            />
                            <Route
                                path='/in-progress'
                                element={<InProgressTable />}
                            />
                            <Route path='/updates' element={<UpdatesTable />} />
                            <Route path='/download' element={<Download />} />
                            <Route path='/stats' element={<Stats />} />
                        </>
                    )}
                    <Route path='/health' element={<Home />} />
                    <Route path='/:token' element={<Verify lang={lang} />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
                <div id='footer'>
                    <Row id='footer-row'>
                        <Col
                            role='region'
                            aria-label={t('footer.privacyPolicy')}
                        >
                            <a
                                id='privacy-policy'
                                href={privacyUrl}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {t('footer.privacyPolicy')}
                            </a>
                        </Col>
                        <Col
                            role='region'
                            aria-label={t('footer.terms', { ns: 'common' })}
                        >
                            <span
                                className='cursor-pointer'
                                onClick={handleShowTermsModal}
                            >
                                {t('footer.terms', { ns: 'common' })}
                            </span>
                        </Col>
                        <Col
                            role='region'
                            aria-label={t('footer.copyright', { ns: 'common' })}
                        >
                            {t('footer.copyright', { ns: 'common' })}
                        </Col>
                    </Row>
                </div>
            </div>
            {showTermsModal && (
                <CustomModal
                    show={showTermsModal}
                    close={handleModalClose}
                    dialog='modal-wide'
                    title={t('title', { ns: 'terms' })}
                    body={
                        <Fragment>
                            <TermsText lang={lang} />
                        </Fragment>
                    }
                    bodyText={t('para-one', { ns: 'terms' })}
                    footer={<Fragment></Fragment>}
                />
            )}
        </Router>
    )
}

export default App
