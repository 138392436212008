import { useNavigate } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import './NotFound.css'

function NotFound() {
    const { t } = useTranslation(['notFound', 'common'])
    const navigate = useNavigate()
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label={t('title', { ns: 'notFound' })}
            >
                {t('title', { ns: 'notFound' })}
            </h1>
            <div role='region' aria-label={t('desc', { ns: 'notFound' })}>
                {t('desc', { ns: 'notFound' })}
            </div>
            <div
                className='mt-3 button-container'
                role='region'
                aria-label={t('buttons.return-to-home', { ns: 'common' })}
            >
                <Button onClick={() => navigate('/')}>
                    {t('buttons.return-to-home', { ns: 'common' })}
                </Button>
            </div>
        </Container>
    )
}

export default NotFound
