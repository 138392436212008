export const order = [
    'region_cd',
    'roll_num',
    'sub_roll_num',
    'mun_address_30',
    'first_name',
    'last_name',
    'birth_dte',
    'occupancy_cd',
    'residency_cd',
    'citizenship_cd',
    'religion_cd',
    'french_language_rights_flg',
    'school_support_cd',
    'property_school_support_cd',
    'transaction_id',
    'in_progress_date',
    'in_progress_by'
]

export const fields = [
    // { text: 'Region', value: 'region_cd' },
    { text: 'Roll', value: 'roll_num' },
    // { text: 'Address', value: 'mun_address_30' },
    { text: 'First Name', value: 'first_name' },
    { text: 'Last Name', value: 'last_name' },
    { text: 'DOB', value: 'birth_dte' },
    { text: 'Occupancy', value: 'occupancy_cd' },
    { text: 'Residency', value: 'residency_cd' },
    { text: 'Citizenship', value: 'citizenship_cd' },
    { text: 'Religion', value: 'religion_cd' },
    { text: 'French', value: 'french_language_rights_flg' },
    { text: 'School Support', value: 'school_support_cd' },
    { text: 'Property School Support', value: 'property_school_support_cd' },
    { text: 'In-Progress', value: 'in_progress' },
    { text: 'Complete', value: 'complete' }
]
