import { createSlice } from '@reduxjs/toolkit'

export const viewPropertySlice = createSlice({
  name: 'viewProperty',
  initialState: {
    value: {
      emailAddress: '',
      areaOccupied: ''
    }
  },
  reducers: {
    updateViewProperty: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateViewProperty } = viewPropertySlice.actions

export default viewPropertySlice.reducer
