import { useTranslation } from 'react-i18next'

function AttestationText(title) {
    const { t } = useTranslation(['attestation'])
    return (
        <div role='region' aria-label={title}>
            <div className='mt-3'>{t('para-one', { ns: 'attestation' })}</div>
            <div className='mt-3'>{t('para-two', { ns: 'attestation' })}</div>
            <div className='mt-3'>{t('para-three', { ns: 'attestation' })}</div>
        </div>
    )
}

export default AttestationText
