import { createSlice } from '@reduxjs/toolkit'

export const addressSlice = createSlice({
    name: 'address',
    initialState: {
        value: {
            ips_property_id: 0,
            mun_address_30: '',
            municipality: { cty_mun: '', municipality_desc: '' },
            roll_num: '',
            ips_suborindate_id: 0,
            area_occupied: ''
        }
    },
    reducers: {
        updateAddress: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updateAddress } = addressSlice.actions

export default addressSlice.reducer
