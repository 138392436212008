import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Container, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import TermsText from '../../Data/Terms'
import './Terms.css'

function Terms({ lang }) {
    const { t } = useTranslation(['common', 'terms'])
    const navigate = useNavigate()
    const [authentication, setAuthentication] = useState(
        useSelector(state => state.authenticationReducer.value)
    )
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Container className='inner-component' role='main'>
            <h1
                role='heading'
                aria-label={t('secondary-title', { ns: 'terms' })}
            >
                {t('secondary-title', { ns: 'terms' })}
            </h1>
            <TermsText lang={lang} />
            <div
                className='button-container'
                role='region'
                aria-label={
                    t('buttons.cancel', { ns: 'common' }) +
                    '/' +
                    t('buttons.i-agree', { ns: 'common' })
                }
            >
                <Button
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    {t('buttons.cancel', { ns: 'common' })}
                </Button>
                <Button
                    className='button-margin'
                    onClick={() => {
                        if (authentication.type === 'O') {
                            navigate('/profile')
                        } else {
                            navigate('/search')
                        }
                    }}
                >
                    {t('buttons.i-agree', { ns: 'common' })}
                </Button>
            </div>
        </Container>
    )
}

export default Terms
