// import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

function RequiredField({ placement, delay, width, content, display }) {
    const useStyles = makeStyles({
        arrow: {
            '&::before': {
                backgroundColor: '#cfdaf1'
            }
        },
        tooltip: {
            textAlign: 'left',
            padding: '0.5rem',
            backgroundColor: '#cfdaf1',
            borderColor: '#add9ed',
            color: '#000',
            fontFamily:
                "'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            fontSize: '16px',
            fontWeight: 400,
            minWidth: width
        }
    })
    const classes = useStyles()
    return (
        <Tooltip
            interactive
            title={content}
            placement={placement}
            leaveDelay={delay}
            // className={className}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            arrow
        >
            <span>{display}</span>
        </Tooltip>
    )
}

export default RequiredField
