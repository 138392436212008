import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import AttestationText from '../../Common/AttestationText'
import ReCaptchaContainer from '../../Common/ReCaptchaContainer'
import './Attestation.css'

function Attestation({ lang }) {
    const { t } = useTranslation(['attestation', 'common'])
    const navigate = useNavigate()
    const [recaptcha, setRecaptcha] = useState(null)
    const [showNext, setShowNext] = useState(false)
    const [checked, setChecked] = useState(false)
    const recaptchaRef = useRef()
    useEffect(() => {
        setShowNext(checked && recaptcha)
    }, [checked, recaptcha])
    const handleCaptchaChange = e => {
        setRecaptcha(e)
    }
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label={t('title-tenant', { ns: 'attestation' })}
            >
                {' '}
                {t('title-tenant', { ns: 'attestation' })}
            </h1>
            <AttestationText title={t('title-tenant', { ns: 'attestation' })} />
            <ReCaptchaContainer
                type='attestation'
                onChange={e => setChecked(e.target.checked ? 'Y' : 'N')}
                handleCaptchaChange={handleCaptchaChange}
                checked={checked}
                setChecked={setChecked}
                recaptchaRef={recaptchaRef}
                lang={lang}
            />
            <div
                className='mt-3 button-container'
                role='region'
                aria-label={t('buttons.continue', { ns: 'common' })}
            >
                <Button
                    onClick={() => {
                        navigate('/terms')
                    }}
                    disabled={checked !== 'Y' || !showNext}
                >
                    {t('buttons.continue', { ns: 'common' })}
                </Button>
            </div>
        </Container>
    )
}

export default Attestation
