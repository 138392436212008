import { createSlice } from '@reduxjs/toolkit'
import { userObject } from '../Components/Templates/UserObject'

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: { value: { userObject } },
  reducers: {
    updateUserData: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateUserData } = userDataSlice.actions

export default userDataSlice.reducer
