import { useNavigate } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import './PropertyNotFound.css'

function PropertyNotFound({ lang }) {
    const { t } = useTranslation(['notFound', 'common'])
    const navigate = useNavigate()
    const contactUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/Contact'
            : 'https://www.mpac.ca/en/Contact'
    return (
        <Container className='inner-component' role='main'>
            <h1 role='heading' aria-label={t('title', { ns: 'notFound' })}>
                {t('title', { ns: 'notFound' })}
            </h1>
            <div
                role='region'
                aria-label={
                    t('alt-desc', { ns: 'notFound' }) +
                    t('alt-desc-link', { ns: 'notFound' }) +
                    t('alt-desc-two', { ns: 'notFound' })
                }
            >
                {t('alt-desc', { ns: 'notFound' })}
                <a
                    id='not-found-contact'
                    href={contactUrl}
                    target='_blank'
                    rel='noreferrer'
                >
                    {t('alt-desc-link', { ns: 'notFound' })}
                </a>{' '}
                {t('alt-desc-two', { ns: 'notFound' })}
            </div>
            <div
                className='mt-3 button-container'
                role='region'
                aria-label={t('buttons.return-to-home', { ns: 'common' })}
            >
                <Button onClick={() => navigate('/')}>
                    {t('buttons.return-to-home', { ns: 'common' })}
                </Button>
            </div>
        </Container>
    )
}

export default PropertyNotFound
