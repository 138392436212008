import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function Confirmation() {
    const { t } = useTranslation(['confirmation'])
    return (
        <Container className='inner-component' role='main'>
            <h1 role='heading' aria-label={t('title', { ns: 'confirmation' })}>
                {t('title', { ns: 'confirmation' })}
            </h1>
            <div
                role='region'
                aria-label={
                    t('desc-two', { ns: 'confirmation' }) +
                    t('confirmation-link', { ns: 'confirmation' }) +
                    t('desc-three', { ns: 'confirmation' })
                }
            >
                {t('desc-two', { ns: 'confirmation' })}
                <span className='blue'>
                    <Link to='/'>
                        {t('confirmation-link', { ns: 'confirmation' })}
                    </Link>
                </span>{' '}
                {t('desc-three', { ns: 'confirmation' })}
            </div>
        </Container>
    )
}

export default Confirmation
