import { createSlice } from '@reduxjs/toolkit'

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState: {
    value: []
  },
  reducers: {
    updateTenants: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateTenants } = tenantsSlice.actions

export default tenantsSlice.reducer
