import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import addressReducer from './Reducers/Address'
import adminReducer from './Reducers/AdminUser'
import authenticationReducer from './Reducers/Authentication'
import downloadReducer from './Reducers/Download'
import formInputReducer from './Reducers/FormInput'
import rollNumReducer from './Reducers/RollNum'
import schoolLeaseFlagReducer from './Reducers/SchoolLeaseFlag'
import tempHolderReducer from './Reducers/TempHolder'
import tenantsReducer from './Reducers/Tenants'
import tokenReducer from './Reducers/Token'
import updatesReducer from './Reducers/Updates'
import updatesInProgressReducer from './Reducers/UpdatesInProgress'
import userDataReducer from './Reducers/UserData'
import viewPropertyReducer from './Reducers/ViewProperty'

// Translation Files
import addEn from './Translations/add-en.json'
import addFr from './Translations/add-fr.json'
import attestationEn from './Translations/attestation-en.json'
import attestationFr from './Translations/attestation-fr.json'
import commonEn from './Translations/common-en.json'
import commonFr from './Translations/common-fr.json'
import completeEn from './Translations/complete-en.json'
import completeFr from './Translations/complete-fr.json'
import confirmationEn from './Translations/confirmation-en.json'
import confirmationFr from './Translations/confirmation-fr.json'
import faqEn from './Translations/faq-en.json'
import faqFr from './Translations/faq-fr.json'
import formEn from './Translations/form-en.json'
import formFr from './Translations/form-fr.json'
import homeEn from './Translations/home-en.json'
import homeFr from './Translations/home-fr.json'
import notFoundEn from './Translations/not-found-en.json'
import notFoundFr from './Translations/not-found-fr.json'
import occupantsTableEn from './Translations/occupants-table-en.json'
import occupantsTableFr from './Translations/occupants-table-fr.json'
import profileEn from './Translations/profile-en.json'
import profileFr from './Translations/profile-fr.json'
import schoolSupportEn from './Translations/school-support-en.json'
import schoolSupportFr from './Translations/school-support-fr.json'
import searchEn from './Translations/search-en.json'
import searchFr from './Translations/search-fr.json'
import termsEn from './Translations/terms-en.json'
import termsFr from './Translations/terms-fr.json'
import tooltipEn from './Translations/tooltip-en.json'
import tooltipFr from './Translations/tooltip-fr.json'
import updateEn from './Translations/update-en.json'
import updateFr from './Translations/update-fr.json'
import './index.css'

export const store = configureStore({
    reducer: {
        addressReducer: addressReducer,
        adminReducer: adminReducer,
        authenticationReducer: authenticationReducer,
        downloadReducer: downloadReducer,
        formInputReducer: formInputReducer,
        rollNumReducer: rollNumReducer,
        schoolLeaseFlagReducer: schoolLeaseFlagReducer,
        tempHolderReducer: tempHolderReducer,
        tenantsReducer: tenantsReducer,
        tokenReducer: tokenReducer,
        updatesReducer: updatesReducer,
        updatesInProgressReducer: updatesInProgressReducer,
        userDataReducer: userDataReducer,
        viewPropertyReducer: viewPropertyReducer
    }
})

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                add: addEn,
                attestation: attestationEn,
                home: homeEn,
                common: commonEn,
                complete: completeEn,
                confirmation: confirmationEn,
                faq: faqEn,
                form: formEn,
                notFound: notFoundEn,
                occupantsTable: occupantsTableEn,
                profile: profileEn,
                schoolSupport: schoolSupportEn,
                search: searchEn,
                terms: termsEn,
                tooltip: tooltipEn,
                update: updateEn
            },
            fr: {
                add: addFr,
                attestation: attestationFr,
                home: homeFr,
                common: commonFr,
                complete: completeFr,
                confirmation: confirmationFr,
                faq: faqFr,
                form: formFr,
                notFound: notFoundFr,
                occupantsTable: occupantsTableFr,
                profile: profileFr,
                schoolSupport: schoolSupportFr,
                search: searchFr,
                terms: termsFr,
                tooltip: tooltipFr,
                update: updateFr
            }
        },
        lng: 'en', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en'
    })

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
