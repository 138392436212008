import { useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'

function VwUpdates() {
    const updates = useSelector(state => state.updatesReducer.value)
    const ViewUpdates = () => {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Last Updated</th>
                        <th>Last Updated By</th>
                        <th>Last Completed</th>
                        <th className='last-col'>Last Completed By</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{updates?.lastUpdatedDate || 'N/A'}</td>
                        <td>{updates?.lastUpdatedBy || 'N/A'}</td>
                        <td>{updates?.lastCompletedDate || 'N/A'}</td>
                        <td>{updates?.lastCompletedBy || 'N/A'}</td>
                    </tr>
                </tbody>
            </Table>
        )
    }
    return <ViewUpdates />
}

export default VwUpdates
