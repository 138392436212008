import { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Button, Spinner, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { schoolSupport } from '../../Data/SchoolSupport'
import ViewProperty from '../ViewProperty/ViewProperty'
import OccupantsTable from '../OccupantsTable/OccupantsTable'
import Add from '../Add/Add'
import CustomModal from '../Custom/CustomModal'
import { groupBy, sortSchoolSupportArray } from '../../Common/Functions'
import {
    getMunicipality,
    getResidents,
    updatePropertyResidents,
    deleteResidents,
    addResidentsToProperty,
    updateSchoolSupport,
    getAddress,
    setPropertyInfo,
    sendResidentsEmail,
    getSchoolBoards,
    updateSSTransactionsDim
} from '../APIService/APIService'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateToken } from '../../Reducers/Token'
import { updateRollNum } from '../../Reducers/RollNum'
import { updateViewProperty } from '../../Reducers/ViewProperty'
import './Profile.css'

function Profile({ lang }) {
    const { t } = useTranslation(['profile', 'form', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ssUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/MakingChangesUpdates/ChangingYourSchoolSupport'
            : 'https://www.mpac.ca/en/MakingChangesUpdates/ChangingYourSchoolSupport'
    const [loading, setLoading] = useState(true)
    const [residents, setResidents] = useState([])
    const [originalResidents, setOriginalResidents] = useState([])
    const [deletedResidents, setDeletedResidents] = useState([])
    const [address, setAddress] = useState(null)
    const [authentication, setAuthentication] = useState(
        useSelector(state => state.authenticationReducer.value)
    )
    const [rollNum, setRollNum] = useState(
        useSelector(state => state.rollNumReducer.value)
    )
    const [areaOccupied, setAreaOccupied] = useState(
        useSelector(state => state.viewPropertyReducer.value?.areaOccupied)
    )
    const [emailAddress, setEmailAddress] = useState(
        useSelector(state => state.viewPropertyReducer.value?.emailAddress)
    )
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [save, setSave] = useState(false)
    const [hasBeenUpdated, setHasBeenUpdated] = useState(false)
    const [awaitingResponse, setAwaitingResponse] = useState(false)
    const [ipsOwnerId, setIpsOwnerId] = useState(null)
    const [ipsSubordinateId, setIpsSubordinateId] = useState(null)
    // school support
    const [schoolSupportCd, setSchoolSupportCd] = useState('')
    const [propertySSUpdated, setPropertySSUpdated] = useState(false)
    const [tempCd, setTempCd] = useState('')
    const [schoolSupportArray, setSchoolSupportArray] = useState([])
    const [mismatchingSchoolSupport, setMismatchingSchoolSupport] =
        useState(false)
    // ViewProperty
    const [tenants, setTenants] = useState([])
    const [checkTenants, setCheckTenants] = useState(false)
    const [ssTooltip, setSsTooltip] = useState(false)
    // OccupantsTable
    const [editable, setEditable] = useState(false)
    const [currentRow, setCurrentRow] = useState(null)
    // add
    const [showForm, setShowForm] = useState(false)
    const [showCheck, setShowCheck] = useState(false)
    // modals
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmTitle, setConfirmTitle] = useState('')
    const [confirmBody, setConfirmBody] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteBody, setDeleteBody] = useState('')
    const [deleteFooter, setDeleteFooter] = useState('')
    const [showSchoolSupportModal, setShowSchoolSupportModal] = useState(false)
    const [schoolSupportBody, setSchoolSupportBody] = useState(false)
    const [ssOptions, setSsOptions] = useState([])
    const isAmppa = localStorage.getItem('amppa')
    useEffect(() => {
        let residentsResponse = ''
        let municipalityResponse = ''
        if (authentication && authentication.authenticated) {
            getResidents({ rollNum: rollNum })
                .then(response => {
                    // console.log(response)
                    residentsResponse = response
                    return getMunicipality({
                        cty_mun: rollNum?.toString()?.substring(0, 4)
                    })
                })
                .then(munResponse => {
                    municipalityResponse = munResponse
                    return getAddress({ rollNum: rollNum })
                })
                .then(addressResponse => {
                    if (!residentsResponse.msg) {
                        let tempTenants = []
                        let tempResidents = []
                        let tempAddress = {}
                        residentsResponse.map((e, i) => {
                            let json = JSON.parse(e)
                            if (i === 0) {
                                setAreaOccupied(json.area_occupied || '')
                                setIpsOwnerId(json.ips_owner_id || '')
                                setIpsSubordinateId(json.ips_subordinate_id || '')
                            }
                            if (json.ips_subordinate_id !== null) {
                                if (json.occupancy_cd === 'O') {
                                    setSsTooltip(true)
                                }
                                tempTenants = [...tempTenants, json]
                            } else {
                                json.i = tempResidents.length
                                tempResidents = [...tempResidents, json]
                            }
                        })
                        setTenants(tempTenants)
                        if (tempTenants.length > 0) {
                            setCheckTenants(true)
                        }
                        setResidents(tempResidents)
                        setOriginalResidents(
                            JSON.parse(JSON.stringify([...tempResidents]))
                        )
                        let addressCopy = JSON.parse(addressResponse[0])
                        tempAddress = {
                            municipality: municipalityResponse.length
                                ? municipalityResponse[0][0]
                                : '',
                            mun_address_30: addressCopy.mun_address_30,
                            roll_num: addressCopy.roll_num,
                            region_cd: addressCopy.region_cd,
                            ips_property_id: addressCopy.ips_property_id,
                            school_support_cd: addressCopy.school_support_cd,
                            property_cd: addressCopy.property_cd
                        }
                        // need to clean up after
                        if (tempTenants.length) {
                            let tempSchoolSupportCd = []
                            let sortedArray = groupBy(
                                tempTenants.filter(
                                    e => e.ips_subordinate_id !== null
                                ),
                                'ips_subordinate_id'
                            )
                            sortedArray.map((e, i) => {
                                if (
                                    e[0]?.sub_school_support_cd &&
                                    !tempSchoolSupportCd.includes(
                                        e[0].sub_school_support_cd
                                    )
                                ) {
                                    tempSchoolSupportCd = [
                                        ...tempSchoolSupportCd,
                                        e[0].sub_school_support_cd
                                    ]
                                } else {
                                    let allMatching = true
                                    for (let idx in e) {
                                        if (
                                            e[idx]?.school_support_cd !==
                                            e[0]?.school_support_cd
                                        ) {
                                            allMatching = false
                                        }
                                    }
                                    if (
                                        allMatching &&
                                        !tempSchoolSupportCd.includes(
                                            e[0]?.school_support_cd
                                        )
                                    ) {
                                        tempSchoolSupportCd = [
                                            ...tempSchoolSupportCd,
                                            e[0].school_support_cd
                                        ]
                                    } else if (
                                        !allMatching &&
                                        !tempSchoolSupportCd.includes('P')
                                    ) {
                                        tempSchoolSupportCd = [
                                            ...tempSchoolSupportCd,
                                            'P'
                                        ]
                                    }
                                }
                            })
                            setSchoolSupportCd(tempSchoolSupportCd)
                        } else if (addressCopy.school_support_cd) {
                            let tempSchoolSupportCd =
                                addressCopy.school_support_cd
                            if (tempSchoolSupportCd.includes(',')) {
                                tempSchoolSupportCd =
                                    tempSchoolSupportCd.split(',')
                                tempSchoolSupportCd =
                                    sortSchoolSupportArray(tempSchoolSupportCd)
                            }
                            setSchoolSupportCd(tempSchoolSupportCd)
                        } else {
                            setSchoolSupportCd('')
                            getSchoolSupportCd(tempResidents, tempTenants)
                        }
                        setAddress(tempAddress)
                        setLoading(false)
                        return getSchoolBoards({
                            cty_mun: rollNum?.substring(0, 4)
                        })
                    }
                })
                .then(response => {
                    let arr = []
                    for (let i in response) {
                        //   if (response[i]?.length) {
                        if (!arr.includes(response[i][0])) {
                            if (response[i][0] == 'FP') {
                                arr.push('A')
                            } else if (response[i][0] == 'FS') {
                                arr.push('C')
                            } else if (response[i][0] == 'PS') {
                                arr.push('D')
                            } else {
                                arr.push(response[i][0])
                            }
                            //   }
                        }
                    }
                    setSsOptions(sortSchoolSupportArray(arr))
                })
        } else {
            navigate('/')
        }
    }, [])
    const getSchoolSupportCd = (res, ten, ssUpdated) => {
        let resArray = res || residents
        resArray = resArray.filter(
            e => e.occupancy_cd === 'O' || e.occupancy_cd === 'S'
        )
        let tenArray = ten || tenants
        let mismatching = false
        setMismatchingSchoolSupport(false)
        setSchoolSupportArray([])
        let temp = ''
        let tempArray = []
        if (resArray.length || tenArray.length) {
            if (tenArray.length === 0) {
                for (let i = 0; i < resArray.length; i++) {
                    if (!tempArray.includes(resArray[i].school_support_cd)) {
                        tempArray = [
                            ...tempArray,
                            resArray[i].school_support_cd
                        ]
                    }
                    if (i === 0) {
                        temp = resArray[i].school_support_cd
                    } else {
                        if (temp !== resArray[i].school_support_cd) {
                            setMismatchingSchoolSupport(true)
                            mismatching = true
                        }
                    }
                }
            } else {
                if (!ssUpdated) {
                    let sortedArray = groupBy(tenArray, 'ips_subordinate_id')
                    sortedArray.map((e, i) => {
                        let allMatching = true
                        if (e.length === 1) {
                            if (!tempArray.includes(e[0].school_support_cd)) {
                                tempArray = [
                                    ...tempArray,
                                    e[0].school_support_cd
                                ]
                            }
                        } else {
                            for (let idx = 0; idx < e.length; idx++) {
                                if (
                                    idx !== 0 &&
                                    e[idx].school_support_cd !==
                                        e[0].school_support_cd
                                ) {
                                    allMatching = false
                                    if (!tempArray.includes('P')) {
                                        tempArray = [...tempArray, 'P']
                                    }
                                }
                            }
                            if (allMatching === true) {
                                if (
                                    !tempArray.includes(e[0].school_support_cd)
                                ) {
                                    tempArray = [
                                        ...tempArray,
                                        e[0].school_support_cd
                                    ]
                                }
                            }
                        }
                    })
                } else {
                    tempArray = [...schoolSupportCd]
                }
            }
            if (!mismatching) {
                setSchoolSupportCd(temp)
            } else {
                setSchoolSupportCd('')
            }
            tempArray = sortSchoolSupportArray(tempArray)
            setSchoolSupportArray(tempArray)
            if (tenArray.length > 0) {
                setSchoolSupportCd(tempArray)
            }
        }
    }
    const updateResidents = (temp, ssUpdated) => {
        setResidents(temp)
        // if resident ss has been updated or new resident has been added, compare all ss codes
        if (ssUpdated || temp.length !== residents.length) {
            getSchoolSupportCd(temp, null, ssUpdated)
        }
    }
    const handleEditable = val => {
        setEditable(val)
    }
    const handleCurrentRow = val => {
        setCurrentRow(val)
    }
    const handleAreaOccupied = val => {
        setAreaOccupied(val)
        let tempResidents = [...residents]
        tempResidents.map((e, i) => {
            e.area_occupied = val
        })
        setResidents([...tempResidents])
    }
    const handleEmailAddress = val => {
        setEmailAddress(val)
    }
    const checkValidEmail = emailAddress => {
        if (!emailAddress) {
            return false
        } else if (
            !emailAddress.match(
                /^[a-zA-Z0-9_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            )
        ) {
            return false
        }
        return true
    }
    const handleConfirm = () => {
        let residentsToBeAdded = residents.filter(e => e.new)
        let residentsToBeUpdated = residents.filter(e => {
            let original = originalResidents.find(o => o.i === e.i)
            return original
                ? JSON.stringify(original) !== JSON.stringify(e) ||
                      propertySSUpdated
                : null
        })
        let residentsToBeDeleted = deletedResidents
        let title = t('save-details', { ns: 'profile' })
        let body = ''
        if (
            residentsToBeAdded.length ||
            residentsToBeUpdated.length ||
            residentsToBeDeleted.length
        ) {
            body = t('save-changes', { ns: 'profile' })
            setHasBeenUpdated(true)
        } else {
            body = t('confirm-no-changes', { ns: 'profile' })
        }
        if (!checkValidEmail(emailAddress) || !areaOccupied) {
            setShowErrorAlert(true)
        } else {
            setShowErrorAlert(false)
            setConfirmTitle(title)
            setConfirmBody(body)
            setShowConfirmModal(true)
        }
    }
    const handleDelete = (e, i) => {
        setDeleteBody(
            <Fragment>{t('confirm-delete', { ns: 'profile' })}</Fragment>
        )
        setDeleteFooter(
            <Fragment>
                <Button
                    onClick={() => {
                        if (!e.new) {
                            setDeletedResidents([...deletedResidents, e])
                        }
                        let temp = residents.filter(r => r.i !== e.i)
                        setResidents(temp)
                        setShowDeleteModal(false)
                    }}
                >
                    {t('buttons.confirm', { ns: 'common' })}
                </Button>
            </Fragment>
        )
        setShowDeleteModal(true)
    }
    const handleSchoolSupport = () => {
        let options = []
        schoolSupportArray.map(e => {
            options = [
                ...options,
                schoolSupport.filter(s => s.schoolSupportCd === e)
            ]
        })
        setSchoolSupportBody(
            <Fragment>
                <Row>
                    <Form.Group>
                        <Form.Label htmlFor='school_support_cd'>
                            {' '}
                            {t('multi-ss', { ns: 'common' })}
                        </Form.Label>
                        <Col md={6}>
                            <Form.Select
                                id='school_support_cd'
                                name='school_support_cd'
                                // value={schoolSupportCd}
                                onChange={e => {
                                    setTempCd(e.target.value)
                                    setSave(e.target.value ? true : false)
                                }}
                            >
                                <option value=''></option>
                                {options.map((e, i) => {
                                    return (
                                        <option
                                            key={i}
                                            value={e[0]?.schoolSupportCd}
                                        >
                                            {t(
                                                'ssc-options.' +
                                                    e[0]?.schoolSupportCd,
                                                { ns: 'form' }
                                            )}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Row>
            </Fragment>
        )
        setShowSchoolSupportModal(true)
    }
    const handleSubmit = () => {
        setAwaitingResponse(true)
        setShowConfirmModal(false)
        let residentsToBeAdded = residents.filter(e => e.new)
        let residentsToBeUpdated = residents.filter(e => {
            let original = originalResidents.find(o => o.i === e.i)
            return original
                ? JSON.stringify(original) !== JSON.stringify(e) ||
                      propertySSUpdated
                : null
        })
        residentsToBeAdded.forEach(e => {
            e.roll_num = rollNum
        })
        residentsToBeUpdated.forEach(e => {
            e.roll_num = rollNum
        })
        let residentsToBeDeleted = deletedResidents
        let residentsDelta = []
        for (let i in residentsToBeAdded) {
            if (!residentsDelta.includes(residentsToBeAdded[i])) {
                residentsDelta.push(residentsToBeAdded[i])
            }
        }
        for (let i in residentsToBeUpdated) {
            if (!residentsDelta.includes(residentsToBeUpdated[i])) {
                residentsDelta.push(residentsToBeUpdated[i])
            }
        }
        addResidentsToProperty(residentsToBeAdded, address)
            .then(response => {
                // console.log(response)
                return updatePropertyResidents(residentsToBeUpdated, address)
            })
            .then(response => {
                // console.log(response)
                return deleteResidents(residentsToBeDeleted)
            })
            .then(response => {
                // console.log(response)
                return updateSchoolSupport(
                    [],
                    schoolSupportCd,
                    address.ips_property_id,
                    'O'
                )
            })
            .then(response => {
                // console.log(response)
                return setPropertyInfo({
                    email_address: emailAddress,
                    area_occupied: areaOccupied,
                    roll_num: rollNum
                })
            })
            .then(response => {
                let tempCd = ''
                if (Array.isArray(schoolSupportCd)) {
                    schoolSupportCd.map((e, i) => {
                        let val = t('ssc-options.' + e, { ns: 'form' })
                        if (i === 0) {
                            tempCd = val
                        } else {
                            tempCd = tempCd + ', ' + val
                        }
                    })
                } else {
                    tempCd = t('ssc-options.' + schoolSupportCd, { ns: 'form' })
                }
                return sendResidentsEmail(
                    emailAddress,
                    tempCd,
                    address.mun_address_30,
                    hasBeenUpdated,
                    lang
                )
            })
            .then(response => {
                return setTimeout(
                    updateSSTransactionsDim({
                        residents: residentsDelta,
                        address: address,
                        areaOccupied: areaOccupied,
                        propertySchoolSupportCd: schoolSupportCd,
                        emailAddress: emailAddress,
                        ipsOwnerId: ipsOwnerId,
                        ipsSubordinateId: ipsSubordinateId
                    }),
                    0
                )
            })
            .then(response => {
                // console.log(response)
                dispatch(updateAuthentication({}))
                dispatch(updateToken(''))
                dispatch(updateRollNum(''))
                dispatch(
                    updateViewProperty({ emailAddress: '', areaOccupied: '' })
                )
                navigate('/confirmation')
            })
    }
    return (
        <Container className='inner-component' role='main'>
            {loading ? (
                <Spinner animation='border' role='status' className='mt-3' />
            ) : (
                <>
                    <h1
                        className='blue'
                        role='heading'
                        aria-label={t('title', { ns: 'profile' })}
                    >
                        {t('title', { ns: 'profile' })}
                    </h1>
                    <div
                        role='region'
                        aria-label={
                            t('desc-one', { ns: 'profile' }) +
                            t('desc-link', { ns: 'profile' }) +
                            t('desc-two', { ns: 'profile' })
                        }
                    >
                        {t('desc-one', { ns: 'profile' })}{' '}
                        <a
                            className='blue'
                            href={ssUrl}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {t('desc-link', { ns: 'profile' })}
                        </a>{' '}
                        {t('desc-two', { ns: 'profile' })}
                        <br />
                        <br />
                        {t('desc-three', { ns: 'profile' })}
                    </div>
                    <h2
                        className='mt-3 subtitle'
                        role='heading'
                        aria-label={t('sub-title', { ns: 'profile' })}
                    >
                        {t('sub-title', { ns: 'profile' })}
                    </h2>
                    <ViewProperty
                        address={address}
                        schoolSupportCd={schoolSupportCd}
                        areaOccupied={areaOccupied}
                        handleAreaOccupied={handleAreaOccupied}
                        emailAddress={emailAddress}
                        handleEmailAddress={handleEmailAddress}
                        showErrorAlert={showErrorAlert}
                        checkTenants={checkTenants}
                        ssTooltip={ssTooltip}
                        lang={lang}
                    />
                    {residents.length > 0 && (
                        <OccupantsTable
                            residents={residents}
                            updateResidents={updateResidents}
                            handleDelete={handleDelete}
                            editable={editable}
                            handleEditable={handleEditable}
                            currentRow={currentRow}
                            handleCurrentRow={handleCurrentRow}
                            address={address}
                            lang={lang}
                            ssOptions={ssOptions}
                        />
                    )}
                    {showErrorAlert && (
                        <>
                            {!areaOccupied && (
                                <div
                                    className='alert alert-danger'
                                    role='alert'
                                >
                                    {t('ao-error', { ns: 'profile' })}
                                </div>
                            )}
                            {!checkValidEmail(emailAddress) && (
                                <div
                                    className='mt-3 alert alert-danger'
                                    role='alert'
                                >
                                    {t('ea-error', { ns: 'profile' })}
                                </div>
                            )}
                        </>
                    )}
                    {showForm && (
                        <Add
                            showForm={showForm}
                            setShowForm={setShowForm}
                            address={address}
                            residents={residents}
                            updateResidents={updateResidents}
                            areaOccupied={areaOccupied}
                            lang={lang}
                            ssOptions={ssOptions}
                        />
                    )}
                    {!showForm && !editable && (
                        <div
                            className='button-container mt-3'
                            role='region'
                            aria-label={
                                t('buttons.add-new', { ns: 'common' }) +
                                '/' +
                                t('buttons.cancel', { ns: 'common' }) +
                                '/' +
                                t('buttons.save', { ns: 'common' })
                            }
                        >
                            {!showForm && (
                                <Button onClick={() => setShowForm(true)}>
                                    {t('buttons.add-new', { ns: 'common' })}
                                </Button>
                            )}
                            <Button
                                className='button-margin'
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                {t('buttons.cancel', { ns: 'common' })}
                            </Button>
                            <Button
                                className='button-margin'
                                disabled={
                                    editable || awaitingResponse || isAmppa
                                }
                                onClick={e => {
                                    if (mismatchingSchoolSupport) {
                                        handleSchoolSupport()
                                    } else {
                                        handleConfirm()
                                    }
                                }}
                            >
                                {t('buttons.save', { ns: 'common' })}
                            </Button>
                            <br />{' '}
                            {awaitingResponse && (
                                <Spinner
                                    animation='border'
                                    role='status'
                                    className='mt-3'
                                />
                            )}
                        </div>
                    )}
                    {showDeleteModal && (
                        <CustomModal
                            show={showDeleteModal}
                            close={() => setShowDeleteModal(false)}
                            dialog='modal-wide'
                            title={t('delete', { ns: 'profile' })}
                            body={deleteBody}
                            bodyText={t('confirm-delete', { ns: 'profile' })}
                            footer={deleteFooter}
                            footerText={t('buttons.confirm', { ns: 'common' })}
                        />
                    )}
                    {showConfirmModal && (
                        <CustomModal
                            show={showConfirmModal}
                            close={() => setShowConfirmModal(false)}
                            dialog='modal-wide'
                            title={confirmTitle}
                            body={confirmBody}
                            bodyText={confirmBody}
                            footer={
                                <Fragment>
                                    <Button
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    >
                                        {t('buttons.confirm', { ns: 'common' })}
                                    </Button>
                                </Fragment>
                            }
                            footerText={t('buttons.confirm', { ns: 'common' })}
                        />
                    )}
                    {showSchoolSupportModal && (
                        <CustomModal
                            show={showSchoolSupportModal}
                            close={() => setShowSchoolSupportModal(false)}
                            dialog='modal-wide'
                            title='School Support'
                            body={schoolSupportBody}
                            bodyText={t('multi-ss', { ns: 'common' })}
                            footer={
                                <Fragment>
                                    <Button
                                        disabled={!save}
                                        onClick={() => {
                                            if (tempCd !== schoolSupportCd) {
                                                setPropertySSUpdated(true)
                                            }
                                            setSchoolSupportCd(tempCd)
                                            setShowSchoolSupportModal(false)
                                            setMismatchingSchoolSupport(false)
                                        }}
                                    >
                                        {t('buttons.save', { ns: 'common' })}
                                    </Button>
                                </Fragment>
                            }
                            footerText={t('buttons.save', { ns: 'common' })}
                        />
                    )}
                </>
            )}
        </Container>
    )
}

export default Profile
