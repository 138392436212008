import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Container,
    Button,
    Form,
    Table,
    Row,
    Col,
    Spinner
} from 'react-bootstrap'
import { updateInProgress, updateCompleted } from '../APIService/APIService'
import { updateUpdates } from '../../Reducers/Updates'
import VwUpdates from '../../Common/VwUpdates'
import CustomModal from '../Custom/CustomModal'
import { formatName } from '../../Common/Functions'
import { fields } from '../../Common/AdminValues'
import AdminContainer from '../../Common/AdminContainer'
import ResponsivePagination from 'react-responsive-pagination'

import './UpdatesTable.css'

function UpdatesTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('complete')
    const [searchField, setSearchField] = useState('roll_num')
    const [searchValue, setSearchValue] = useState('')
    const [modalBody, setModalBody] = useState(
        'Are you sure you would like to load the latest updates?'
    )
    const admin = useSelector(state => state.adminReducer.value)
    const updates = useSelector(state => state.updatesReducer.value)
    const [orderedUpdates, setOrderedUpdates] = useState(updates?.updates)
    const [filteredUpdates, setFilteredUpdates] = useState(orderedUpdates)
    const [currentUpdates, setCurrentUpdates] = useState(filteredUpdates)
    const [confirmedInProgress, setConfirmedInProgress] = useState([])
    const [confirmedCompleted, setConfirmedCompleted] = useState([])
    const [enabled, setEnabled] = useState(null)
    const [updated, setUpdated] = useState(false)
    const keys = [
        'transaction_id',
        'in_progress_date',
        'in_progress_by',
        'completed',
        'last_completed_by'
    ]
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState([])
    const [numPages, setNumPages] = useState(0)
    useEffect(() => {
        if (orderedUpdates?.length) {
            let tempDisabled = []
            let tempEnabled = []
            let recordsToShow = []
            orderedUpdates.forEach(e => {
                if (e.in_progress_date) {
                    if (e.in_progress_by !== admin.user_id) {
                        tempDisabled = [...tempDisabled, e.transaction_id]
                    } else {
                        tempEnabled = [...tempEnabled, e.transaction_id]
                    }
                }
                if (!tempDisabled.includes(e.transaction_id)) {
                    recordsToShow = [
                        ...recordsToShow,
                        { ...e, completed: null }
                    ]
                }
            })
            setEnabled(tempEnabled)
            setOrderedUpdates(recordsToShow)
            setFilteredUpdates(recordsToShow)
            setConfirmedInProgress(tempEnabled)
            setLoading(false)
        }
    }, [updated])
    useEffect(() => {
        handleSearchChange({ target: { value: searchValue } }, orderedUpdates)
    }, [orderedUpdates])
    useEffect(() => {
        let temp = filteredUpdates
        let tempNum = Math.ceil(temp.length / 5)
        setNumPages(tempNum)
        // setCurrentUpdates(
        //     temp.length < 5
        //         ? temp.slice((page - 1) * 5, (page - 1) * 5 + temp.length)
        //         : temp.slice((page - 1) * 5, page * 5)
        // )
        let tempPages = []
        for (let i = 0; i < tempNum; i++) {
            tempPages.push(i + 1)
        }
        setPages(tempPages)
        if (page > tempNum && page > 0) {
            setPage(tempNum)
        }
    }, [filteredUpdates])
    useEffect(() => {
        let temp = filteredUpdates
        let tempArray = temp.slice((page - 1) * 5, page * 5)
        setCurrentUpdates(tempArray)
    }, [page, filteredUpdates])
    const Modal = () => {
        return (
            <CustomModal
                show={showModal}
                close={() => setShowModal(false)}
                dialog='modal-wide'
                title='Confirm'
                body={modalBody}
                footer={
                    <Button
                        onClick={() => {
                            if (modalType === 'completed') {
                                const completed = orderedUpdates.filter(
                                    e =>
                                        e.completed &&
                                        !confirmedCompleted.includes(
                                            e.transaction_id
                                        )
                                )
                                const notCompleted = orderedUpdates.filter(
                                    e => !completed.includes(e)
                                )
                                if (completed.length) {
                                    completed.forEach(e => {
                                        e.last_completed_by = admin.user_id
                                    })
                                    updateCompleted(completed).then(
                                        response => {
                                            setModalType('after-completed')
                                            setModalBody(
                                                response +
                                                    ' record(s) have been successfully completed.'
                                            )
                                            dispatch(
                                                updateUpdates({
                                                    ...updates,
                                                    updates: notCompleted
                                                })
                                            )
                                            let tempConfirmed = [
                                                ...confirmedCompleted
                                            ]
                                            completed.forEach(e => {
                                                tempConfirmed = [
                                                    ...tempConfirmed,
                                                    e.transaction_id
                                                ]
                                            })
                                            setConfirmedCompleted(tempConfirmed)
                                            setOrderedUpdates(notCompleted)
                                            setFilteredUpdates([])
                                            let temp = [...notCompleted]
                                            if (searchValue) {
                                                temp = [...notCompleted].filter(
                                                    el => {
                                                        return el[
                                                            searchField
                                                        ]?.includes(
                                                            searchValue?.toUpperCase()
                                                        )
                                                    }
                                                )
                                            }
                                            setFilteredUpdates(temp)
                                        }
                                    )
                                } else {
                                    setModalType('completed')
                                    setShowModal(false)
                                }
                            } else if (modalType === 'in-progress') {
                                let toBeUpdated = []
                                orderedUpdates.forEach(e => {
                                    if (
                                        e.in_progress_date &&
                                        e.in_progress_by === admin.user_id &&
                                        !enabled?.includes(e.transaction_id)
                                    ) {
                                        toBeUpdated = [
                                            ...toBeUpdated,
                                            e.transaction_id
                                        ]
                                    }
                                })
                                updateInProgress(
                                    admin.user_id,
                                    toBeUpdated,
                                    []
                                ).then(response => {
                                    setUpdated(!updated)
                                    dispatch(
                                        updateUpdates({
                                            ...updates,
                                            updates: orderedUpdates
                                        })
                                    )
                                    setModalType('after-in-progress')
                                    setModalBody(
                                        toBeUpdated.length +
                                            ' record(s) have been successfully set in-progress.'
                                    )
                                    let tempInProgress = confirmedInProgress
                                    toBeUpdated.forEach(e => {
                                        tempInProgress = [...tempInProgress, e]
                                    })
                                    setConfirmedInProgress(tempInProgress)
                                })
                            } else {
                                setShowModal(false)
                            }
                        }}
                    >
                        OK
                    </Button>
                }
            />
        )
    }
    const handleChange = e => {
        setSearchField(e.target.value)
    }
    const handleSearchChange = (e, values) => {
        setSearchValue(e.target.value)
        let temp = values || orderedUpdates
        if (e.target.value) {
            temp = (values || orderedUpdates).filter(el => {
                return el[searchField]?.includes(e.target.value?.toUpperCase())
            })
        }
        setFilteredUpdates(temp)
    }
    const childComponent = (
        <div>
            <VwUpdates />
        </div>
    )
    return (
        <Container className='inner-component' role='main'>
            <h1
                className='blue'
                role='heading'
                aria-label='School Support Admin'
            >
                School Support Admin
            </h1>
            <div role='region' aria-label='Latest Updates'>
                <AdminContainer childComponent={childComponent} />
                <Form id='updates-table' className='mt-3'>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Select
                                    id='search_field'
                                    name='search_field'
                                    value={searchField}
                                    onChange={e => handleChange(e)}
                                    required
                                >
                                    {fields.map((e, i) => {
                                        return (
                                            e.value !== 'in_progress' &&
                                            e.value !== 'complete' && (
                                                <option key={i} value={e.value}>
                                                    {e.text}
                                                </option>
                                            )
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    type='text'
                                    name='search'
                                    value={searchValue}
                                    onChange={e => {
                                        handleSearchChange(e)
                                    }}
                                    placeholder='Search'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                {loading || !orderedUpdates.length ? (
                    <Spinner
                        animation='border'
                        role='status'
                        className='mt-3'
                    />
                ) : (
                    <Table striped>
                        <thead>
                            <tr>
                                {fields.map((e, i) => {
                                    if (e.value === 'birth_dte') {
                                        return (
                                            <th>
                                                <div>{formatName(e.text)}</div>
                                            </th>
                                        )
                                    } else {
                                        return <th>{formatName(e.text)}</th>
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {currentUpdates &&
                                currentUpdates.map((e, i) => {
                                    return (
                                        <tr>
                                            {Object.entries(e).map(
                                                ([key, value]) => {
                                                    if (!keys.includes(key)) {
                                                        return (
                                                            <td>
                                                                {formatName(
                                                                    value
                                                                )}
                                                            </td>
                                                        )
                                                    }
                                                }
                                            )}
                                            <td>
                                                <Form>
                                                    <Form.Check
                                                        disabled={
                                                            enabled?.includes(
                                                                e.transaction_id
                                                            ) &&
                                                            e.in_progress_by ===
                                                                admin.user_id
                                                        }
                                                        checked={
                                                            e.in_progress_date
                                                        }
                                                        type='checkbox'
                                                        onChange={() => {
                                                            if (
                                                                e.in_progress_date
                                                            ) {
                                                                setOrderedUpdates(
                                                                    orderedUpdates.map(
                                                                        el =>
                                                                            el.transaction_id ===
                                                                            e.transaction_id
                                                                                ? {
                                                                                      ...el,
                                                                                      in_progress_date:
                                                                                          null,
                                                                                      in_progress_by:
                                                                                          null
                                                                                  }
                                                                                : el
                                                                    )
                                                                )
                                                            } else {
                                                                setOrderedUpdates(
                                                                    orderedUpdates.map(
                                                                        el =>
                                                                            el.transaction_id ===
                                                                            e.transaction_id
                                                                                ? {
                                                                                      ...el,
                                                                                      in_progress_date: true,
                                                                                      in_progress_by:
                                                                                          admin.user_id
                                                                                  }
                                                                                : el
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </Form>
                                            </td>
                                            <td>
                                                <Form>
                                                    <Form.Check
                                                        type='checkbox'
                                                        disabled={
                                                            !confirmedInProgress.includes(
                                                                e.transaction_id
                                                            ) ||
                                                            confirmedCompleted.includes(
                                                                e.transaction_id
                                                            )
                                                        }
                                                        checked={e.completed}
                                                        onChange={() => {
                                                            if (e.completed) {
                                                                setOrderedUpdates(
                                                                    orderedUpdates.map(
                                                                        el =>
                                                                            el.transaction_id ===
                                                                            e.transaction_id
                                                                                ? {
                                                                                      ...el,
                                                                                      completed:
                                                                                          null
                                                                                  }
                                                                                : el
                                                                    )
                                                                )
                                                            } else {
                                                                setOrderedUpdates(
                                                                    orderedUpdates.map(
                                                                        el =>
                                                                            el.transaction_id ===
                                                                            e.transaction_id
                                                                                ? {
                                                                                      ...el,
                                                                                      completed: true
                                                                                  }
                                                                                : el
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </Form>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                )}
                {pages.length > 1 && (
                    <ResponsivePagination
                        current={page}
                        total={numPages - 1}
                        onPageChange={e => {
                            setPage(e)
                        }}
                    />
                )}
                <div className='button-container'>
                    <Button
                        onClick={() => {
                            let toBeUpdated = orderedUpdates.filter(
                                e =>
                                    e.in_progress_date &&
                                    e.in_progress_by === admin.user_id &&
                                    !enabled?.includes(e.transaction_id)
                            )
                            if (toBeUpdated.length) {
                                setModalBody(
                                    'Are you sure you would like to update the in-progress status of the selected records?'
                                )
                                setModalType('in-progress')
                            } else {
                                setModalBody(
                                    'No records have been selected. Please ensure that you have selected records to complete and try again.'
                                )
                            }
                            setShowModal(true)
                        }}
                    >
                        Set In-Progress
                    </Button>
                    <Button
                        className='button-margin'
                        onClick={() => {
                            const completed = orderedUpdates.filter(
                                e =>
                                    e.completed &&
                                    !confirmedCompleted.includes(
                                        e.transaction_id
                                    )
                            )
                            setModalType('completed')
                            setModalBody(
                                completed.length
                                    ? 'Are you sure you would like to complete the selected records? Completed records will be removed from School Support Admin once they have been updated.'
                                    : 'No completed records have been selected. Please ensure that you have selected records to complete and try again.'
                            )
                            setShowModal(true)
                        }}
                    >
                        Complete Updates
                    </Button>
                    <Button
                        className='button-margin'
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        Back
                    </Button>
                </div>
                {showModal && <Modal />}
            </div>
        </Container>
    )
}

export default UpdatesTable
