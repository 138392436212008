import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { getMunicipality } from '../APIService/APIService'
import { updateUserData } from '../../Reducers/UserData'
import ReCaptcha from '../../Common/ReCaptcha'
import './Confirm.css'

function Confirm({ lang }) {
    const { t } = useTranslation(['confirmation', 'form', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const privacyUrl =
        lang === 'fr'
            ? 'https://www.mpac.ca/fr/AboutUs/Policies/PrivacyStatement'
            : 'https://www.mpac.ca/en/AboutUs/Policies/PrivacyStatement'
    const [authentication, setAuthentication] = useState(
        useSelector(state => state.authenticationReducer.value)
    )
    const [userData, setUserData] = useState(
        useSelector(state => state.userDataReducer.value)
    )
    const [address, setAddress] = useState(
        useSelector(state => state.addressReducer.value)
    )
    const [formInput, setFormInput] = useState(
        useSelector(state => state.formInputReducer.value)
    )
    const [checked, setChecked] = useState(false)
    const [recaptcha, setRecaptcha] = useState(null)
    const [showNext, setShowNext] = useState(false)
    const [municipality, setMunicipality] = useState(null)
    const recaptchaRef = useRef()
    useEffect(() => {
        let user = { ...userData }
        let input = { ...formInput }
        if (!authentication || !authentication.authenticated) {
            navigate('/search')
        } else {
            user = {
                ...user,
                citizenship_cd: input.citizenship_cd,
                religion_cd: input.religion_cd,
                french_language_rights_flg: input.french_language_rights_flg,
                school_support_cd: input.school_support_cd
            }
            setUserData(user)
            if (user.roll_num) {
                getMunicipality({
                    cty_mun: user.roll_num.substring(0, 4)
                }).then(response => {
                    if (response.length) {
                        setMunicipality(response[0])
                    } else {
                        setMunicipality('')
                    }
                })
            } else {
                setMunicipality('')
            }
        }
    }, [])
    useEffect(() => {
        setShowNext(checked && recaptcha)
    }, [checked, recaptcha])
    const handleCaptchaChange = e => {
        setRecaptcha(e)
    }
    const updateUser = () => {
        dispatch(updateUserData(userData))
    }
    return (
        <Container className='inner-component' role='main'>
            {userData && municipality ? (
                <>
                    <h1
                        role='heading'
                        aria-label={t('title', { ns: 'confirmation' })}
                    >
                        {t('title', { ns: 'confirmation' })}
                    </h1>
                    <div
                        role='region'
                        aria-label={t('desc', { ns: 'confirmation' })}
                    >
                        {t('desc', { ns: 'confirmation' })}
                        <Row>
                            <Col className='mt-3 confirm-heading'>
                                {t('first-name', { ns: 'form' })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('last-name', { ns: 'form' })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('birth-dte', { ns: 'form' })}
                            </Col>
                        </Row>
                        <Row>
                            <Col>{userData.first_name?.toUpperCase()}</Col>
                            <Col>{userData.last_name?.toUpperCase()}</Col>
                            <Col>{userData.birth_dte}</Col>
                        </Row>
                        <Row>
                            <Col className='mt-3 confirm-heading'>
                                {t('municipality', { ns: 'form' })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('address', { ns: 'form' })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('area-occupied', { ns: 'form' })}
                            </Col>
                        </Row>
                        <Row>
                            <Col>{municipality}</Col>
                            <Col>{userData.mun_address_30}</Col>
                            <Col>
                                {t('ao-options.' + address?.area_occupied, {
                                    ns: 'form'
                                })?.toUpperCase()}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3 confirm-heading'>
                                {t('occupancy-cd', { ns: 'form' })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('citizenship-alt', { ns: 'form' })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('religion-cd', { ns: 'form' })}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {userData.occupancy_cd
                                    ? t(
                                          'oc-options.' +
                                              (userData.occupancy_cd === 'S'
                                                  ? 'ST'
                                                  : userData.occupancy_cd),
                                          {
                                              ns: 'form'
                                          }
                                      ).toUpperCase()
                                    : ''}
                            </Col>
                            <Col>
                                {t(
                                    'options.' +
                                        (userData.citizenship_cd === 'C'
                                            ? 'yes'
                                            : 'no'),
                                    {
                                        ns: 'common'
                                    }
                                )?.toUpperCase()}
                            </Col>
                            <Col>
                                {t(
                                    'options.' +
                                        (userData.religion_cd === 'R'
                                            ? 'yes'
                                            : 'no'),
                                    {
                                        ns: 'common'
                                    }
                                )?.toUpperCase()}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-3 confirm-heading'>
                                {t('french-language-rights-flg', {
                                    ns: 'form'
                                })}
                            </Col>
                            <Col className='mt-3 confirm-heading'>
                                {t('school-support-cd', { ns: 'form' })}
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                {' '}
                                <Col>
                                    {t(
                                        'options.' +
                                            (userData.french_language_rights_flg ===
                                            'Y'
                                                ? 'yes'
                                                : 'no'),
                                        {
                                            ns: 'common'
                                        }
                                    )?.toUpperCase()}
                                </Col>
                            </Col>
                            <Col>
                                {userData.school_support_cd
                                    ? t(
                                          'ssc-options.' +
                                              userData?.school_support_cd,
                                          { ns: 'form' }
                                      ).toUpperCase()
                                    : ''}
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>
                    <div
                        className='mt-3 school-support-info'
                        role='region'
                        aria-label='ReCaptcha'
                    >
                        {t('agree-one', { ns: 'common' })}
                        <a
                            href={privacyUrl}
                            target='_blank'
                            rel='noreferrer'
                            className='blue'
                        >
                            {t('agree-link', { ns: 'common' })}
                        </a>{' '}
                        {t('agree-two', { ns: 'common' })}
                        <ReCaptcha
                            onChange={e =>
                                setChecked(e.target.checked ? 'Y' : 'N')
                            }
                            handleCaptchaChange={handleCaptchaChange}
                            checked={checked}
                            setChecked={setChecked}
                            recaptchaRef={recaptchaRef}
                            lang={lang}
                        />
                    </div>
                    <div
                        className='mt-3 button-container'
                        role='region'
                        aria-label={
                            t('buttons.cancel', { ns: 'common' }) +
                            '/' +
                            t('buttons.next', { ns: 'common' })
                        }
                    >
                        <Button
                            className='button-margin'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            {t('buttons.cancel', { ns: 'common' })}
                        </Button>
                        <Button
                            className='button-margin'
                            onClick={() => {
                                updateUser()
                                navigate('/complete')
                            }}
                            disabled={!showNext}
                        >
                            {t('buttons.next', { ns: 'common' })}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    {' '}
                    <Spinner
                        animation='border'
                        role='status'
                        className='mt-3'
                    />
                </>
            )}
        </Container>
    )
}

export default Confirm
