import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import {
    decodeToken,
    getResidents,
    getPropertyInfo
} from '../APIService/APIService'
import { updateAuthentication } from '../../Reducers/Authentication'
import { updateViewProperty } from '../../Reducers/ViewProperty'
import { updateRollNum } from '../../Reducers/RollNum'
import { updateSchoolLeaseFlag } from '../../Reducers/SchoolLeaseFlag'
import { updateToken } from '../../Reducers/Token'
import AttestationText from '../../Common/AttestationText'
import ReCaptchaContainer from '../../Common/ReCaptchaContainer'
import './Verify.css'

function Verify({ lang }) {
    const { t } = useTranslation(['attestation', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token } = useParams()
    const [checked, setChecked] = useState(false)
    const [recaptcha, setRecaptcha] = useState(null)
    const [showNext, setShowNext] = useState(false)
    const [loading, setLoading] = useState(true)
    const [viewProperty, setViewProperty] = useState(
        useSelector(state => state.viewPropertyReducer.value)
    )
    const recaptchaRef = useRef()
    useEffect(() => {
        if (
            document.referrer?.split('/')[2] === 'amppa-ecs.aws.mpac.ca' ||
            document.referrer?.split('/')[2] === 'amppa-dev.aws.mpac.ca'
        ) {
            localStorage.setItem('amppa', 'T')
        } else if (localStorage.getItem('amppa')) {
            localStorage.removeItem('amppa')
        }
        setShowNext(checked && recaptcha)
    }, [checked, recaptcha])
    const handleCaptchaChange = e => {
        setRecaptcha(e)
    }
    useEffect(() => {
        if (token) {
            dispatch(updateToken(token))
            // console.log(token)
            decodeToken(token).then(data => {
                // console.log(data)
                if (data.msg) {
                    navigate('/')
                } else {
                    dispatch(
                        updateSchoolLeaseFlag(
                            data.decoded_token.school_lease_flag
                        )
                    )
                    dispatch(updateRollNum(data.decoded_token.roll_num))
                    let formInput = {
                        rollNum: data.decoded_token.roll_num,
                        emailAddress: data.decoded_token.email_address
                    }
                    getResidents(formInput)
                        .then(response => {
                            if (response.msg) {
                                navigate('/property-not-found')
                            } else {
                                setLoading(false)
                                dispatch(
                                    updateAuthentication({
                                        authenticated: true,
                                        type: 'O'
                                    })
                                )
                            }
                            return getPropertyInfo(formInput)
                        })
                        .then(response => {
                            if (response && response.length) {
                                dispatch(
                                    updateViewProperty({
                                        ...viewProperty,
                                        emailAddress:
                                            response[0] ||
                                            data.decoded_token.email_address,
                                        areaOccupied: response[1]
                                    })
                                )
                            } else {
                                dispatch(
                                    updateViewProperty({
                                        ...viewProperty,
                                        emailAddress:
                                            data.decoded_token.email_address,
                                        areaOccupied: null
                                    })
                                )
                            }
                        })
                }
            })
        } else {
            navigate('/')
        }
    }, [])
    return (
        <Container className='inner-component' role='main'>
            {loading ? (
                <Spinner animation='border' role='status' className='mt-3' />
            ) : (
                <>
                    <h1
                        className='blue'
                        role='heading'
                        aria-label={t('title', { ns: 'attestation' })}
                    >
                        {' '}
                        {t('title', { ns: 'attestation' })}
                    </h1>
                    <AttestationText
                        title={t('title', { ns: 'attestation' })}
                    />
                    <ReCaptchaContainer
                        type='attestation'
                        onChange={e => setChecked(e.target.checked ? 'Y' : 'N')}
                        handleCaptchaChange={handleCaptchaChange}
                        checked={checked}
                        setChecked={setChecked}
                        recaptchaRef={recaptchaRef}
                        lang={lang}
                    />
                    <div
                        className='mt-3 button-container'
                        role='region'
                        aria-label={t('buttons.continue', { ns: 'common' })}
                    >
                        <Button
                            onClick={() => navigate('/terms')}
                            disabled={!showNext}
                        >
                            {t('buttons.continue', { ns: 'common' })}
                        </Button>
                    </div>
                </>
            )}
        </Container>
    )
}

export default Verify
