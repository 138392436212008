import { createSlice } from '@reduxjs/toolkit'
import { userObject } from '../Components/Templates/UserObject'

export const tempHolderSlice = createSlice({
  name: 'tempHolder',
  initialState: {
    value: userObject
  },
  reducers: {
    updateTempHolder: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateTempHolder } = tempHolderSlice.actions

export default tempHolderSlice.reducer
