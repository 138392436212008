import { createSlice } from '@reduxjs/toolkit'

export const updatesSlice = createSlice({
    name: 'updates',
    initialState: {
        updates: [],
        lastUpdatedDate: '',
        lastUpdatedBy: '',
        lastCompletedDate: '',
        lastCompletedBy: ''
    },
    reducers: {
        updateUpdates: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updateUpdates } = updatesSlice.actions

export default updatesSlice.reducer
