import { createSlice } from '@reduxjs/toolkit'

export const schoolLeaseFlagSlice = createSlice({
    name: 'schoolLeaseFlag',
    initialState: {
        value: ''
    },
    reducers: {
        updateSchoolLeaseFlag: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updateSchoolLeaseFlag } = schoolLeaseFlagSlice.actions

export default schoolLeaseFlagSlice.reducer
