import { createSlice } from '@reduxjs/toolkit'

export const downloadSlice = createSlice({
    name: 'download',
    initialState: {
        value: {
            type: '',
            values: []
        }
    },
    reducers: {
        updateDownload: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { updateDownload } = downloadSlice.actions

export default downloadSlice.reducer
