import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Alert,
    Spinner
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import RequiredField from '../Custom/RequiredField'
import { userObject } from '../Templates/UserObject'
import { getResident, getSchoolBoards } from '../APIService/APIService'
import { updateUserData } from '../../Reducers/UserData'
import { updateFormInput } from '../../Reducers/FormInput'
import { sortSchoolSupportArray } from '../../Common/Functions'
import './UpdateSchoolSupport.css'

function UpdateSchoolSupport() {
    const { t } = useTranslation(['update', 'form', 'common'])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let temp = userObject
    const [authentication, setAuthentication] = useState(
        useSelector(state => state.authenticationReducer.value)
    )
    const [address, setAddress] = useState(
        useSelector(state => state.addressReducer.value)
    )
    const [userData, setUserData] = useState(
        useSelector(state => state.userDataReducer.value)
    )
    const [loading, setLoading] = useState(false)
    const [addNew, setAddNew] = useState(false)
    const [showNotFound, setShowNotFound] = useState(false)
    const [ssOptions, setSsOptions] = useState([])
    useEffect(() => {
        if (
            !authentication ||
            !authentication.authenticated ||
            authentication.type !== 'T'
        ) {
            navigate('/search')
            return
        }
        temp = {
            ...temp,
            citizenship_cd: 'C',
            occupancy_cd: 'T',
            residency_cd: 'U',
            school_support_cd: 'P',
            french_language_rights_flg: 'N',
            religion_cd: 'N',
            roll_num: address?.roll_num,
            ips_property_id: address?.ips_property_id,
            mun_address_30: address?.mun_address_30,
            municipality: address?.municipality,
            area_occupied: address?.area_occupied,
            ips_subordinate_id: address?.ips_subordinate_id
        }
        setUserData(temp)
        const rollNum = address?.roll_num
        if (rollNum) {
            const ctyMun = rollNum.substring(0, 4)
            if (ctyMun) {
                getSchoolBoards({ cty_mun: ctyMun }).then(response => {
                    let arr = []
                    for (let i in response) {
                        //   if (response[i]?.length) {
                        if (!arr.includes(response[i][0])) {
                            if (response[i][0] == 'FP') {
                                arr.push('A')
                            } else if (response[i][0] == 'FS') {
                                arr.push('C')
                            } else if (response[i][0] == 'PS') {
                                arr.push('D')
                            } else {
                                arr.push(response[i][0])
                            }
                            //   }
                        }
                    }
                    setSsOptions(sortSchoolSupportArray(arr))
                })
            }
        }
    }, [])
    const handleChange = e => {
        setUserData(userData => {
            return { ...userData, [e.target.name]: e.target.value }
        })
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (addNew) {
            let temp = { ...userData }
            temp.new = true
            temp.area_occupied = address?.area_occupied
            let input = {
                citizenship_cd: temp.citizenship_cd,
                religion_cd: temp.religion_cd,
                french_language_rights_flg: temp.french_language_rights_flg,
                school_support_cd: temp.school_support_cd
            }
            dispatch(updateUserData(temp))
            dispatch(updateFormInput(input))
            navigate('/confirm')
        } else {
            setShowNotFound(false)
            setLoading(true)
            getResident(userData).then(response => {
                setLoading(false)
                if (response.length && response[0].length) {
                    let temp = response[0][0]
                    temp = JSON.parse(temp)
                    temp.area_occupied = address?.area_occupied
                    // temp.occupancy_cd = userData.occupancy_cd
                    let input = {
                        citizenship_cd: temp.citizenship_cd,
                        religion_cd: temp.citizenship_cd,
                        french_language_rights_flg:
                            temp.french_language_rights_flg,
                        school_support_cd: temp.school_support_cd,
                        occupancy_cd: temp.occupancy_cd
                    }
                    dispatch(updateUserData(temp))
                    dispatch(updateFormInput(input))
                    navigate('/school-support')
                } else {
                    setShowNotFound(true)
                }
            })
        }
    }
    return (
        <Container className='inner-component' role='main'>
            <h1 role='heading' aria-label={t('title', { ns: 'update' })}>
                {t('title', { ns: 'update' })}
            </h1>
            <Form onSubmit={e => handleSubmit(e)} className='mt-3 mb-3'>
                <fieldset>
                    <legend> {t('desc', { ns: 'update' })}</legend>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='first_name'>
                                    {t('first-name', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Control
                                    id='first_name'
                                    type='text'
                                    name='first_name'
                                    value={userData?.first_name}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='last_name'>
                                    {t('last-name', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Control
                                    id='last_name'
                                    type='text'
                                    name='last_name'
                                    value={userData?.last_name}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='birth_dte'>
                                    {t('birth-dte', { ns: 'form' })}{' '}
                                    <RequiredField />
                                </Form.Label>
                                <Form.Control
                                    id='birth_dte'
                                    type='date'
                                    name='birth_dte'
                                    value={userData?.birth_dte}
                                    onChange={e => handleChange(e)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='municipality'>
                                    {t('municipality', { ns: 'form' })}
                                </Form.Label>
                                <Form.Control
                                    id='municipality'
                                    type='text'
                                    name='municipality'
                                    value={
                                        userData?.municipality
                                            ?.municipality_desc
                                    }
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='street_address'>
                                    {t('street-address', { ns: 'form' })}
                                </Form.Label>
                                <Form.Control
                                    id='street_address'
                                    type='text'
                                    name='address'
                                    value={userData?.mun_address_30}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label htmlFor='area_occupied'>
                                    {t('area-occupied', { ns: 'form' })}
                                </Form.Label>
                                <Form.Control
                                    id='area_occupied'
                                    type='text'
                                    name='area_occupied'
                                    value={t(
                                        'ao-options.' + address?.area_occupied,
                                        { ns: 'form' }
                                    )}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {addNew && (
                        <>
                            <Row>
                                <Col className='mt-3'>
                                    <Form.Group>
                                        <Form.Label htmlFor='occupancy_cd'>
                                            {t('occupancy-cd', { ns: 'form' })}{' '}
                                            <RequiredField />
                                        </Form.Label>
                                        <Form.Select
                                            id='occupancy_cd'
                                            name='occupancy_cd'
                                            value={userData?.occupancy_cd}
                                            onChange={e => handleChange(e)}
                                            required
                                        >
                                            <option value='T'>
                                                {t('oc-options.T', {
                                                    ns: 'form'
                                                })}
                                            </option>
                                            <option value='S'>
                                                {t('oc-options.ST', {
                                                    ns: 'form'
                                                })}
                                            </option>
                                            <option value='B'>
                                                {t('oc-options.B', {
                                                    ns: 'form'
                                                })}
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='mt-3'>
                                    <Form.Group>
                                        <Form.Label htmlFor='residency_cd'>
                                            {t('residency-cd', { ns: 'form' })}{' '}
                                            <RequiredField />
                                        </Form.Label>
                                        <Form.Select
                                            id='residency_cd'
                                            name='residency_cd'
                                            value={userData?.residency_cd}
                                            onChange={e => handleChange(e)}
                                            required
                                        >
                                            <option value='U'>
                                                {t('rc-options.U', {
                                                    ns: 'form'
                                                })}
                                            </option>
                                            {userData.occupancy_cd !== 'B' && (
                                                <>
                                                    <option value='M'>
                                                        {t('rc-options.M', {
                                                            ns: 'form'
                                                        })}
                                                    </option>
                                                    <option value='A'>
                                                        {t('rc-options.A', {
                                                            ns: 'form'
                                                        })}
                                                    </option>
                                                    <option value='N'>
                                                        {t('rc-options.N', {
                                                            ns: 'form'
                                                        })}
                                                    </option>
                                                </>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mt-3'>
                                    <Form.Group>
                                        <Form.Label htmlFor='citizenship_cd'>
                                            {t('citizenship-cd', {
                                                ns: 'form'
                                            })}{' '}
                                            <RequiredField />
                                        </Form.Label>
                                        <Form.Select
                                            id='citizenship_cd'
                                            name='citizenship_cd'
                                            value={userData?.citizenship_cd}
                                            onChange={e => handleChange(e)}
                                            required
                                        >
                                            <option value='C'>
                                                {t('cc-options.C', {
                                                    ns: 'form'
                                                })}
                                            </option>
                                            <option value='A'>
                                                {t('cc-options.A', {
                                                    ns: 'form'
                                                })}
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='mt-3'>
                                    <Form.Group>
                                        <Form.Label htmlFor='religion_cd'>
                                            {t('religion-cd', { ns: 'form' })}
                                        </Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    id='religion_cd'
                                                    type='radio'
                                                    value='R'
                                                    name='religion_cd'
                                                    checked={
                                                        userData?.religion_cd ===
                                                        'R'
                                                    }
                                                    label={t('options.yes', {
                                                        ns: 'common'
                                                    })}
                                                    onChange={e =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Check
                                                    id='religion_cd_n'
                                                    type='radio'
                                                    value='N'
                                                    name='religion_cd'
                                                    checked={
                                                        userData?.religion_cd ===
                                                        'N'
                                                    }
                                                    label={t('options.no', {
                                                        ns: 'common'
                                                    })}
                                                    onChange={e =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </Col>
                                            <Col className='mt-3'></Col>
                                            <Col className='mt-3'></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='mt-3'>
                                    <Form.Group>
                                        <Form.Label htmlFor='french_language_rights_flg'>
                                            {t('french-language-rights-flg', {
                                                ns: 'form'
                                            })}
                                        </Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    id='french_language_rights_flg'
                                                    type='radio'
                                                    value='Y'
                                                    name='french_language_rights_flg'
                                                    checked={
                                                        userData?.french_language_rights_flg ===
                                                        'Y'
                                                    }
                                                    label={t('options.yes', {
                                                        ns: 'common'
                                                    })}
                                                    onChange={e =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Check
                                                    id='french_language_rights_flg_n'
                                                    type='radio'
                                                    value='N'
                                                    name='french_language_rights_flg'
                                                    checked={
                                                        userData?.french_language_rights_flg ===
                                                        'N'
                                                    }
                                                    label={t('options.no', {
                                                        ns: 'common'
                                                    })}
                                                    onChange={e =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </Col>
                                            <Col></Col>
                                            <Col></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label htmlFor='school_support_cd'>
                                            {t('school-support-cd', {
                                                ns: 'form'
                                            })}
                                        </Form.Label>
                                        <Form.Select
                                            id='school_support_cd'
                                            name='school_support_cd'
                                            value={userData.school_support_cd}
                                            onChange={e => handleChange(e)}
                                        >
                                            {ssOptions.map((o, idx) => {
                                                const value = o?.toString()
                                                if (
                                                    value === 'S' &&
                                                    userData.religion_cd !== 'R'
                                                ) {
                                                    return
                                                } else if (
                                                    value === 'A' &&
                                                    (userData.citizenship_cd !==
                                                        'C' ||
                                                        userData.french_language_rights_flg !==
                                                            'Y')
                                                ) {
                                                    return
                                                } else if (
                                                    value === 'C' &&
                                                    (userData.citizenship_cd !==
                                                        'C' ||
                                                        userData.french_language_rights_flg !==
                                                            'Y' ||
                                                        userData.religion_cd !==
                                                            'R')
                                                ) {
                                                    return
                                                } else if (
                                                    value === 'D' &&
                                                    (userData.religion_cd ===
                                                        'R' ||
                                                        address?.region_cd !==
                                                            '16' ||
                                                        address?.roll_num.substring(
                                                            0,
                                                            4
                                                        ) !== '4372')
                                                ) {
                                                    return
                                                } else {
                                                    return (
                                                        <option value={value}>
                                                            {t(
                                                                'ssc-options.' +
                                                                    value,
                                                                {
                                                                    ns: 'form'
                                                                }
                                                            )}
                                                        </option>
                                                    )
                                                }
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    <div className='mt-3 button-container'>
                        <Button
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            {t('buttons.cancel', { ns: 'common' })}
                        </Button>
                        <Button className='button-margin' type='submit'>
                            {addNew
                                ? t('buttons.add', { ns: 'common' })
                                : t('buttons.search', { ns: 'common' }) +
                                  '/' +
                                  t('buttons.add', { ns: 'common' })}
                        </Button>
                    </div>
                    {loading && (
                        <Spinner
                            animation='border'
                            role='status'
                            className='mt-3'
                        />
                    )}
                    {showNotFound && (
                        <Alert variant='danger' className='mt-3'>
                            {t('not-found', { ns: 'update' })}
                            <span
                                className='cursor-pointer blue'
                                onClick={() => {
                                    setShowNotFound(false)
                                    setAddNew(true)
                                }}
                            >
                                {t('not-found-link', { ns: 'update' })}
                            </span>
                            .
                        </Alert>
                    )}
                </fieldset>
            </Form>
        </Container>
    )
}

export default UpdateSchoolSupport
